import React, { useState, useEffect } from 'react';
import { fetchConfigApi, postConfig, insertErrorApi } from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../loader/Loader';
// import DeletePopup from './deletePopup';
// PS_CP_69: Initialize various state variables needed for storing and managing site details and scores when the Admin changes the site in a dropdown button.
const Scoring = () => {
    const scoringDataDefault = {
        'sam.gov': [
            { score_fields: 'Department/Ind. Agency', values: [{ name: 'Department of Defence', points: 0 }, { name: 'All other Departments/Ind Agencies', points: 0 }] },

            { score_fields: 'Sub-Tier', values: [{ name: 'Department of the Army', points: 0 }, { name: 'Department of the Navy', points: 0 }, { name: 'All other Sub-tiers', points: 0 }] },

            { score_fields: 'Contract Opportunity Type', values: [{ name: 'Sources Sought', points: 0 }, { name: 'Presolication', points: 0 }, { name: 'Solication', points: 0 }] },

            { score_fields: 'Updated Date Offers Due', values: [{ name: ' 0-15 days', points: 0 }, { name: '16-30 days', points: 0 }, { name: '31-60 days', points: 0 }, { name: '61+ days', points: 0 }] },

            { score_fields: 'Original Set-Aside', values: [{ name: '8(a)', points: 0 }, { name: 'HUBZone', points: 0 }, { name: 'Woman-Owned Small Business', points: 0 }, { name: 'Total Small Business', points: 0 }, { name: 'All other Set-Asides', points: 0 }] },

            { score_fields: 'Product Service Code', values: [{ name: ' DA01', points: 0 }, { name: 'All other Product Service Codes', points: 0 }] },

            { score_fields: 'NAICS Code', values: [{ name: '541511', points: 0 }, { name: '541512', points: 0 }, { name: '541513', points: 0 }, { name: '541519', points: 0 }, { name: '611420', points: 0 }, { name: 'All other NAICS Codes', points: 0 }] },

            { score_fields: 'Place of Performance', values: [{ name: 'USA', points: 0 }, { name: 'Any other Place of Performance', points: 0 }] },

        ],

        'govtribe': [
            { score_fields: 'Type', values: [{ name: 'Pre-Solicitation', points: 0 }, { name: 'Solicitation Agencies', points: 0 }, { name: 'All other outputs Agencies', points: 0 }] },
            { score_fields: 'Federal Agency', values: [{ name: 'Department of the Army', points: 0 }, { name: 'Department of the Navy', points: 0 }, { name: 'All other outputs', points: 0 }] },
            { score_fields: 'NAICS Category', values: [{ name: '541511', points: 0 }, { name: '541512', points: 0 }, { name: '541513', points: 0 }, { name: '541519', points: 0 }, { name: '611420', points: 0 }, { name: 'All other NAICS Codes', points: 0 }] },
            { score_fields: 'PSC Category', values: [{ name: 'DA01', points: 0 }, { name: 'All other outputs', points: 0 }] },
            { score_fields: 'Predicted Award Type', values: [{ name: 'Single', points: 0 }, { name: 'Multiple', points: 0 }] },
            { score_fields: 'Set-Aside Type', values: [{ name: '8(a)', points: 0 }, { name: 'HUBZone', points: 0 }, { name: 'Woman-Owned Small Business', points: 0 }, { name: 'Total Small Business', points: 0 }, { name: 'All other Set-Asides', points: 0 }] },
            { score_fields: 'Place of Performance', values: [{ name: 'USA', points: 0 }, { name: 'All other Place of Performance outputs', points: 0 }] }
        ],

        'govwin': [
            { score_fields: 'Buying Organization', values: [{ name: 'Army', points: 0 }, { name: 'Navy', points: 0 }, { name: 'All other outputs', points: 0 }] },
            { score_fields: 'Status', values: [{ name: 'Forecast Pre-RFP', points: 0 }, { name: 'Pre-RFP', points: 0 }, { name: 'Post-RFP', points: 0 }, { name: 'Any Active', points: 0 }, { name: 'All other Status outputs', points: 0 }] },
            { score_fields: 'Solicitation Date', values: [{ name: '0-15 days', points: 0 }, { name: '16-30 days', points: 0 }, { name: '31-60 days', points: 0 }, { name: '61+ days', points: 0 }] },
            { score_fields: 'Response Date', values: [{ name: '0-15 days', points: 0 }, { name: '16-30 days', points: 0 }, { name: '31-60 days', points: 0 }, { name: '61+ days', points: 0 }] },
            { score_fields: 'Value', values: [{ name: '> 1,000', points: 0 }, { name: '1,000', points: 0 }, { name: '10,000', points: 0 }, { name: '50,000', points: 0 }, { name: '100,000+', points: 0 }] },
            { score_fields: 'Competition Type', values: [{ name: '8(a)', points: 0 }, { name: 'HUBZone', points: 0 }, { name: 'Woman-Owned Small Business', points: 0 }, { name: 'Total Small Business', points: 0 }, { name: 'All other Set-Asides', points: 0 }] },
            { score_fields: 'Type of Award', values: [{ name: 'Task / Delivery Order', points: 0 }, { name: 'IDIQ', points: 0 }, { name: 'BPA', points: 0 }, { name: 'All other Type of Award outputs', points: 0 }] },
            { score_fields: 'NAICS Code(s)', values: [{ name: '541511', points: 0 }, { name: '541512', points: 0 }, { name: '541513', points: 0 }, { name: '541519', points: 0 }, { name: '611420', points: 0 }, { name: 'All other NAICS Codes', points: 0 }] },
            { score_fields: 'Place of Performance', values: [{ name: 'United States', points: 0 }, { name: 'All other Place of Performance outputs', points: 0 }] },
        ]
    };
    const navigate = useNavigate();
    const location = useLocation();
    const user_id = location.state.user_id
    const jwtToken = location.state.jwtToken

    const [loader, setLoader] = useState(false);

    const [selectedWebsite, setSelectedWebsite] = useState('');
    const [scoringData, setScoringData] = useState(scoringDataDefault);
    const [initialData, setInitialData] = useState(scoringDataDefault); // To store initial state
    const [accordionOriginalData, setAccordionOriginalData] = useState({}); // To track original data per accordion
    const [newFieldName, setNewFieldName] = useState('');
    const [editingFieldIndex, setEditingFieldIndex] = useState(null);
    const [editingValueIndex, setEditingValueIndex] = useState(null);
    const [newValueName, setNewValueName] = useState('');
    const [newSiteName, setNewSiteName] = useState('');
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastImage, setToastImage] = useState("");
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDelteModal, setShowDelteModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    let validSites = {
        'sam.gov': '391F0D6C-2F19-4BD1-AB2D-0E8B3D64A1CF',
        'govtribe': 'A1374E18-C657-4DEA-909B-2E88D3465A9C',
        'govwin': '5213E526-5A49-45CF-840E-23A28FEC7285'
};

    //This Function call the Intial data on the load of the page
    const fetchConfigData = async () => {
        try {
            const payload = {
                user_id: user_id,
                click_type: 'scoring',
            };
            setLoader(() => true)
            const response = await fetchConfigApi(payload, jwtToken);
            setLoader(() => false)
            const dbData = response.data.data;
            console.log(dbData, "dbbbbb");
            // Collect website names and IDs from the backend
            const websitesFromBackend = dbData.map(item => item.website_name);
            const websiteIdMap = dbData.reduce((acc, item) => {
                acc[item.website_name] = item.website_id;
                console.log(acc, "acccc");
                return acc;
            }, {});

            // Select the first website as the default selectedWebsite
            const defaultWebsiteName = websitesFromBackend[0];
            const defaultWebsiteId = websiteIdMap[defaultWebsiteName];

            // Create a deep copy of default data and mark as 'static'
            const filteredStaticData = {};
            Object.keys(scoringDataDefault).forEach(website => {
                if (websitesFromBackend.includes(website)) {
                    filteredStaticData[website] = JSON.parse(JSON.stringify(scoringDataDefault[website]));
                    filteredStaticData[website].forEach(field => {
                        field.source = 'static';
                        field.values.forEach(value => {
                            value.source = 'static';
                        });
                    });
                }
            });

            // Merge database data with filtered static data
            dbData.forEach(item => {
                const websiteName = item.website_name;
                const websiteId = item.website_id;
                const scoreFieldsFromDB = item.score_fields;
                const scoreValues = JSON.parse(item.score_values);
                console.log(websiteName);
                console.log(websiteId);
                console.log(scoreFieldsFromDB);
                console.log(scoreValues);


                if (!filteredStaticData[websiteName]) {
                    filteredStaticData[websiteName] = [];
                }

                let fieldIndex = filteredStaticData[websiteName].findIndex(field =>
                    field.score_fields === scoreFieldsFromDB
                );

                if (fieldIndex === -1) {
                    filteredStaticData[websiteName].push({
                        score_fields: scoreFieldsFromDB,
                        values: [],
                        source: 'db'
                    });
                    fieldIndex = filteredStaticData[websiteName].length - 1;
                }

                const fieldData = filteredStaticData[websiteName][fieldIndex];

                // Process each value from the DB
                Object.entries(scoreValues).forEach(([name, points]) => {
                    const existingValueIndex = fieldData.values.findIndex(value =>
                        value.name === name
                    );

                    if (existingValueIndex !== -1) {
                        fieldData.values[existingValueIndex].points = points;
                        fieldData.values[existingValueIndex].source = 'static';
                    } else {
                        fieldData.values.push({
                            name,
                            points,
                            source: 'db'
                        });
                    }
                });
            });

            // Attach website ids
            Object.keys(filteredStaticData).forEach(website => {
                filteredStaticData[website].id = websiteIdMap[website];
            });

            // Set the default selected website state
            setSelectedWebsite(defaultWebsiteName);

            // Set the state with updated data
            setScoringData(filteredStaticData);
            setInitialData(JSON.parse(JSON.stringify(filteredStaticData)));
            setAccordionOriginalData(JSON.parse(JSON.stringify(filteredStaticData)));

        } catch (error) {
            setLoader(() => false)
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchConfigData();
    }, []);

    console.log(scoringData, "scoreeee");

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    };
    // Function to handle adding a new site
    const handleAddSite = async () => {
        const trimmedSiteName = newSiteName.trim().toLowerCase();
        const siteId = validSites[trimmedSiteName];

        if (siteId) {
            if (!scoringData[trimmedSiteName]) {
                try {
                    // Call API to insert new site
                    const payload = {
                        user_id: user_id,
                        value: 'scoring',
                        detail: [{
                            website_id: siteId,
                            field: 'null',
                            action: 'insert'
                        }]
                    };
                    // setLoader(true)
                    setLoader(() => true)
                    const response = await postConfig(payload, jwtToken);
                    setLoader(() => false)

                    console.log(response);
                    if (response.success === true) {
                        console.log(response);
                        fetchConfigData()
                    } else if (response.status === 403) {
                        openToast("Token Expired", "images/cross - red.svg");
                        navigate('/Login');
                    } else if (response.status === 401 || 500) {
                        openToast("Something went wrong", "images/cross - red.svg");
                    }

                    setNewSiteName('');

                } catch (error) {
                    setLoader(() => false)
                    const payload = {
                        service: "frontend",
                        error_message: error.toString(),
                        module_name: "Scoring.jsx",
                        function_name: "handleAddSite",
                        user_id: user_id,
                    };
                    insertErrorApi(payload);
                } finally {
                    // Always set loader to false after operation completes
                    // setLoader(false);
                }
            } else {
                openToast("Site already exists in scoringData", "images/cross - red.svg");
            }
        } else {
            openToast('Invalid site. Only sam.gov, govtribe, and govwin are allowed.', 'images/cross - red.svg');
        }
    };

    // Function to handle deleting a site
    const handleDeleteSite = async (website) => {
        try {
            // Call API to delete site
            const payload = {
                user_id: user_id,
                value: 'scoring',
                detail: [{
                    website_id: scoringData[website].id,
                    field: 'null',
                    action: 'delete'
                }]
            };
            // setLoader(true)
            setLoader(() => true)
            const response = await postConfig(payload, jwtToken);
            setLoader(() => false)

            console.log(response, "resss");
            if (response.success === true) {
                console.log(response.success, "true");
                fetchConfigData()
            } else if (response.status === 403) {
                openToast("Token Expired", "images/cross - red.svg");
                navigate('/Login');
            } else if (response.status === 401 || 500) {
                openToast("Something went wrong", "images/cross - red.svg");
            }


            // Update selectedWebsite if it's the one being deleted
            if (selectedWebsite === website) {
                const remainingWebsites = Object.keys(scoringData).filter(site => site !== website);
                const newSelectedWebsite = remainingWebsites.length > 0 ? remainingWebsites[0] : '';
                setSelectedWebsite(newSelectedWebsite);
            }

            // Disable delete modal if only one website remains
            if (Object.keys(scoringData).length <= 1) {
            }
        } catch (error) {
            setLoader(() => false)
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Scoring.jsx",
                function_name: "handleDeleteSite",
                user_id: user_id,
            };
            insertErrorApi(payload);
        } finally {
            // setLoader(false);
        }
    };

    const handleWebsiteChange = (website) => {
        setSelectedWebsite(website);
    };

    //Function is used to Edit the Site
    const handleEditSite = (website) => {
        setSelectedWebsite(website); // Select the site for editing
        setNewSiteName(website); // Set initial value in input field to current site name
        setShowEditModal(true); // Show the edit modal
    };

    const saveEditedSiteName = () => {
        if (newSiteName.trim() !== '') {
            setScoringData(prevData => {
                const updatedData = { ...prevData };
                updatedData[newSiteName] = updatedData[selectedWebsite];
                delete updatedData[selectedWebsite];
                return updatedData;
            });
            setSelectedWebsite(newSiteName); // Update selected site to edited name
            setNewSiteName(''); // Clear input field
        }
    };
    const handleDeletetheSite = (website) => {
        console.log
            (website, "webssss");
        setSelectedWebsite(website); // Select the site for editing
        setNewSiteName(website); // Set initial value in input field to current site name
        setShowDeleteModal(true); // Show the edit modal

    };

    //Function Handle the Point Change
    const handlePointsChange = (website, fieldIndex, valueIndex, points) => {
        // Filter the input to allow only numeric values
        const numericPoints = points.replace(/[^0-9]/g, ''); // Removes non-numeric characters
        setIsSaveDisabled(numericPoints === '');

        // Update state with numeric values only
        setScoringData(prevData => {
            const newData = { ...prevData };
            newData[website][fieldIndex].values[valueIndex].points = numericPoints;
            return newData;
        });
    };

    //Function handle the Addition of the Vlaue field
    const handleAddValue = () => {
        if (newFieldName.trim() !== '') {
            setScoringData(prevData => {
                const updatedData = { ...prevData };
                updatedData[selectedWebsite][editingFieldIndex].values.push({
                    name: newFieldName,
                    points: 0,
                    source: 'db'
                });
                return updatedData;
            });
            setNewFieldName('');
        }
    };
    //Function is used to edit the Added value field
    const handleEditValue = (website, fieldIndex, valueIndex) => {
        setEditingFieldIndex(fieldIndex);
        setEditingValueIndex(valueIndex);
        setNewValueName(scoringData[website][fieldIndex].values[valueIndex].name);
    };
    //This function work in the Each Save of the Accordian
    const saveEditedValueName = () => {
        if (newValueName.trim() !== '') {
            setScoringData(prevData => {
                const updatedData = { ...prevData };
                updatedData[selectedWebsite][editingFieldIndex].values[editingValueIndex].name = newValueName;
                return updatedData;
            });
            setNewValueName('');
        }
    };

    //PS_CP_92: This function is User to Delete the added value Admin initiates field deletion; adjust site details and scores in state to remove the specified field, and reflect changes in the UI.
    const handleDeleteValue = (website, fieldIndex, valueIndex) => {
        console.log(fieldIndex, valueIndex, "delete");
        setScoringData(prevData => {
            const updatedData = { ...prevData };
            updatedData[selectedWebsite][fieldIndex].values.splice(valueIndex, 1);
            return updatedData;
        });
    };

    //This function is used to Send the desired format of data to backend
    const deepCloneAndTransform = (scoringData) => {
        if (Array.isArray(scoringData)) {
            return scoringData.map(entry => ({
                [entry.score_fields]: entry.values.reduce((acc, item) => {
                    // Convert points to number if possible
                    const pointsValue = Number(item.points);
                    acc[item.name] = isNaN(pointsValue) ? item.points : pointsValue;
                    return acc;
                }, {})
            }));
        } else if (scoringData !== null && typeof scoringData === 'object') {
            return Object.keys(scoringData).reduce((acc, key) => {
                if (key !== 'source') {
                    acc[key] = deepCloneAndTransform(scoringData[key]);
                }
                return acc;
            }, {});
        } else {
            return scoringData;
        }
    };


    //PS_CP_96: This Function trigger when the user click the submit button the site  delete, add and the field update will occur here Admin finalizes all scoring changes; process and save all scoring updates, handle API response, and present the final outcome through UI updates or popups as needed.
    const saveChanges = async () => {
        const cleanedData = deepCloneAndTransform(scoringData);
        console.log('Saving changes:', cleanedData[selectedWebsite]);
        console.log(cleanedData, "clean");
        // Implement API call to save changes
        console.log(selectedWebsite);
        console.log(scoringData, "score")
        console.log(scoringData[selectedWebsite].id);

        try {
            const payload = {
                "value": "scoring",
                "user_id": user_id,
                "detail": [{
                    "website_id": scoringData[selectedWebsite].id,
                    "field": cleanedData[selectedWebsite]
                }]
            }
            setLoader(() => true)
            const response = await postConfig(payload, jwtToken);
            setLoader(() => false)
            console.log(response);

            if (response.success == true) {
                console.log(response);
                console.log(document.getElementById('openmypopup'));
                document.getElementById('openmypopup').click()
                setIsSaveSuccessful(true); // Set save success flag
            } else if (response.status === 403) {
                openToast("Token Expired", "images/cross - red.svg");
                navigate('/Login');
            } else if (response.status === 401 || 500) {
                openToast("Something went wrong", "images/cross - red.svg");
            }
        } catch (error) {
            setLoader(() => false)
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Scoring.jsx",
                function_name: "saveChanges",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    //Function trigger the Overall Cancel
    const handleCancel = () => {
        setScoringData(JSON.parse(JSON.stringify(initialData)));
    };

    //PS_CP_94: Admin confirms the changes within an accordion; save the updated details and scores in state variables and update the scoring tab UI accordingly.
    const handleAccordionSave = (website, fieldIndex) => {
        setAccordionOriginalData(prevData => {
            const updatedData = { ...prevData };
            updatedData[website][fieldIndex] = JSON.parse(JSON.stringify(scoringData[website][fieldIndex]));
            return updatedData;
        });
    };
    //PS_CP_93: Admin cancels changes within an accordion; reset associated scoring state details to original states and ensure UI is updated.
    const handleAccordionCancel = (website, fieldIndex) => {
        if (!isSaveSuccessful) { // Check if save was not successful
            setScoringData(prevData => {
                const updatedData = { ...prevData };
                updatedData[website][fieldIndex] = JSON.parse(JSON.stringify(accordionOriginalData[website][fieldIndex]));
                return updatedData;
            });
        }
    };
    // This function is used to Show the Toast mesage
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    function reset() {
        setNewSiteName('')
    }


    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="tab-pane fade-show active" id="Scoring" role="tabpanel">
                        <div className="row">
                            {isToastOpen && (<div className="container-fluid">
                                <div className="d-flex justify-content-right align-items-end flex-column">
                                    <div className="px-2 mx-3 my-2 toast-container p-2">
                                        <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                            <span>
                                                <img
                                                    src={toastImage}
                                                    alt="tick-green-icon"
                                                    className="me-2 toast-img cursor-pointer"
                                                />
                                            </span>{" "}
                                            {toastMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>)}
                            <div className="col-md-3">
                                <label className="font-20 font-semibold mb-2">Site</label>
                                <div className="input-group">
                                    <div className="dropdown w-75">
                                        <button
                                            className="btn input-btn bg-white color-black font-14 w-100 text-start gap-3 custom-border d-flex justify-content-between align-items-center"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="mb-1">{selectedWebsite}</span>
                                            <img src="./images/dropdown-arrow.svg" alt="dropdown" />
                                        </button>
                                        <ul className="dropdown-menu custom-dropdown-width py-1 rounded-3 dropdown-box-shadow border-0">
                                            {Object.keys(scoringData).map((website, index) => (
                                                <li className="dropdown-item bg-white cursor-pointer px-0" key={index}>
                                                    <span
                                                        className="d-flex justify-content-between align-items-center mx-2 rounded-2 dropdown-list"
                                                        onClick={() => handleWebsiteChange(website)}
                                                    >
                                                        <span className="ms-2 font-13 mb-1">{website}</span>

                                                        <span className="me-2 mb-1 edit-delete">
                                                            <span className="cursor-pointer">
                                                                <img
                                                                    className="custom-right-border pe-1"
                                                                    src="./images/edit-icon.svg"
                                                                    alt="edit"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#editSiteModal"
                                                                    onClick={() => handleEditSite(website)}
                                                                />
                                                            </span>

                                                            <span className="cursor-pointer" id="delete">
                                                                <img
                                                                    className="custom-right-border pe-1"
                                                                    src="./images/delete-icon.svg"
                                                                    alt="delete"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#deleteModal"
                                                                    onClick={() => handleDeletetheSite(website)}
                                                                />

                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                    <button className="input-group-text bg-white cursor-pointer custom-border border-start-0 add-button" 
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop15"
                                    disabled={Object.keys(scoringData).length < 3 ? false : true}>
                                        <img src="./images/green-plus.svg" alt="add" width={10} onClick={reset}
                                            
                                        />
                                    </button>


                                    <div className="modal fade" id="editSiteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="editSiteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content border-size-5">
                                                <div className="modal-header border-0 px-4 pb-0">
                                                    <h1 className="modal-title font-semibold color-black font-20" id="editSiteModalLabel">Edit Site Name</h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body px-4">
                                                    <div className="mt-3">
                                                        <label className="form-label font-14 font-semibold primary-color" htmlFor="EditSiteName">New Site Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control custom-form font-13 font-regular py-2"
                                                            id="EditSiteName"
                                                            placeholder="Enter New Site Name"
                                                            value={newSiteName}
                                                            onChange={(e) => setNewSiteName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="modal-footer gap-2 text-end pt-4 px-10 border-0 pt-0 pb-25">
                                                    <button type="button" className="btn cancel-btn font-13 primary-color font-medium" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal" onClick={saveEditedSiteName}>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="modal fade" id="staticBackdrop15" data-bs-backdrop="static" data-bs-keyboard="false"
                                        tabIndex="-1" aria-labelledby="staticBackdropLabel5" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content border-size-5">
                                                <div class="modal-header border-0 px-4 pb-0">
                                                    <h1 class="modal-title font-semibold color-black font-20" id="exampleModalLabel">Add
                                                        Site</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                        aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body px-4">
                                                    <div class="mt-3">
                                                        <label class="form-label font-14 font-semibold primary-color" htmlFor="SiteName">Site
                                                            Name</label>

                                                        <input type="text" class="form-control custom-form font-13 font-regular py-2"
                                                            id="SiteName" placeholder="Enter Site Name"
                                                            value={newSiteName}
                                                            onChange={(e) => setNewSiteName(e.target.value)}
                                                        />

                                                        <div class="modal-footer gap-2 text-end pt-3 px-0 border-0 pt-5 pb-0">
                                                            <button type="button"
                                                                class="btn cancel-btn font-13 primary-color font-medium"
                                                                data-bs-dismiss="modal">Cancel</button>
                                                            <button type="button"
                                                                class="btn submit-btn font-13 color-white font-medium"
                                                                data-bs-dismiss="modal"
                                                                onClick={handleAddSite}>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <h3 className="font-semibold font-20 mt-5">Scoring</h3>

                            <div className="accordion" id="accordionExample">
                                {scoringData[selectedWebsite]?.map((item, index) => (
                                    <div className="accordion-item rounded-3 custom-border mb-2" key={index}>
                                        <h2 className="accordion-header d-flex align-items-center justify-content-between">
                                            <button
                                                className="cust-acc-btn d-flex align-items-center gap-2 btn border-0 collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${index}`}
                                                aria-expanded="true"
                                                aria-controls={`collapse${index}`}
                                            >
                                                <span className="font-medium primary-text-color font-16 font-semibold">
                                                    {item.score_fields}
                                                </span>
                                            </button>
                                        </h2>
                                        <div
                                            id={`collapse${index}`}
                                            className="accordion-collapse collapse"
                                            aria-labelledby={`heading${index}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="custom-border-top" />
                                            <div className="accordion-body row mt-2">
                                                {item.values.map((value, valueIndex) => (
                                                    <div className="col-md-4 mb-3" key={valueIndex}>
                                                        <div className="d-flex align-items-center">
                                                            <span className="font-14 font-semibold me-2">
                                                                {value.name}
                                                            </span>
                                                            <span className="mb-2">
                                                                {
                                                                    value.source === 'db' && (
                                                                        <>
                                                                            <span className="cursor-pointer">
                                                                                <img
                                                                                    className="custom-right-border pe-1"
                                                                                    src="./images/edit-icon.svg"
                                                                                    alt="edit"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#editValueNameModal"
                                                                                    onClick={() => handleEditValue(selectedWebsite, index, valueIndex)}
                                                                                />
                                                                            </span>
                                                                            <span className="cursor-pointer">
                                                                                <img
                                                                                    className="custom-right-border px-1"
                                                                                    src="./images/delete-icon.svg"
                                                                                    alt="delete"
                                                                                    onClick={() => handleDeleteValue(selectedWebsite, index, valueIndex)}
                                                                                />
                                                                            </span>
                                                                        </>
                                                                    )}
                                                            </span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            value={value.points}
                                                            onChange={(e) =>
                                                                handlePointsChange(selectedWebsite, index, valueIndex, e.target.value)
                                                            }
                                                            placeholder="Enter Points"
                                                            className="custom-input custom-border p-2 rounded-3 mt-2 px-3"
                                                        />

                                                    </div>
                                                ))}

                                                <div class="col-md-4 mb-3">
                                                    <button
                                                        className="btn custom-line-height d-flex align-items-center justify-content-center  border-0 bottom-0 primary-btn rounded-3  p-2 rounded-4 mt-4 px-2 w-50 p-2"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addValueModal"
                                                        onClick={() => setEditingFieldIndex(index)}
                                                    >
                                                        <img src="./images/add-plus-icon.svg" alt="plus-icon" />
                                                        <span className="font-14 font-medium text-white ms-2 mt-1">
                                                            Add Another Input
                                                        </span>
                                                    </button>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-end align-items-center py-3">
                                                        <button className="btn cancel-btn me-3 px-4 font-regular primary-color"
                                                            onClick={() => handleAccordionCancel(selectedWebsite, index)}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button className="btn submit-btn color-white px-4font-regular" 
                                                        onClick={(e) =>
                                                            handleAccordionSave(selectedWebsite, index)
                                                        }
                                                        disabled={isSaveDisabled}>
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="col-md-12">
                                <div className="d-flex justify-content-end align-items-center py-3">
                                    <button className="btn cancel-btn me-3 px-4 font-regular primary-color"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button className="btn submit-btn color-white px-4font-regular" onClick={(e) =>
                                        saveChanges(selectedWebsite)}

                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                            <>
                            </>
                        </div>


                        {/* Delete Modal */}
                        <div
                            className="modal fade"
                            id="deleteModal"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="deleteModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content border-size-5">
                                    <div className="modal-header border-0 pt-4 pb-1">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body px-4">
                                        <div className="d-flex justify-content-center mb-4">
                                            <img src="images/error-red.svg" alt="error-red" />
                                        </div>
                                        <p className="text-center font-regular font-13 m-0 color-black">
                                            Are you sure you want to delete this {selectedWebsite} website?
                                        </p>
                                    </div>
                                    <div className="modal-footer justify-content-center border-0 gap-2 pb-4">
                                        <button
                                            className="btn cancel-btn font-13 primary-color font-medium"
                                            data-bs-dismiss="modal"
                                        >
                                            No
                                        </button>
                                        <button
                                            type="button"
                                            className="btn submit-btn font-13 color-white font-medium"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                handleDeleteSite(selectedWebsite);
                                            }}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Modal for adding/editing value */}
                        <div
                            className="modal fade"
                            id="addValueModal"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="addValueModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content border-size-5">
                                    <div className="modal-header border-0 px-4 pb-0">
                                        <h1 className="modal-title font-semibold color-black font-20" id="addValueModalLabel">
                                            Add Another Input
                                        </h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                            onClick={() => setNewFieldName("")}
                                        />
                                    </div>
                                    <div className="modal-body px-4">
                                        <div className="mt-3">
                                            <label
                                                className="form-label font-14 font-semibold primary-color"
                                                htmlFor="NewValueName"
                                            >
                                                Field Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form font-13 font-regular py-2"
                                                id="NewValueName"
                                                placeholder="Enter Field Name"
                                                value={newFieldName}
                                                onChange={(e) => setNewFieldName(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                    <div className="modal-footer gap-2 text-end pt-4 px-10 border-0 pt-0 pb-25">
                                        <button type="button"
                                            className="btn cancel-btn font-13 primary-color font-medium"
                                            data-bs-dismiss="modal"
                                            onClick={() => setNewFieldName("")}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn submit-btn font-13 color-white font-medium"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                                if (editingFieldIndex !== null) {
                                                    handleAddValue();
                                                }
                                            }}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Modal for editing value name */}
                        <div
                            className="modal fade"
                            id="editValueNameModal"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex={-1}
                            aria-labelledby="editValueNameModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content border-size-5">
                                    <div className="modal-header border-0 px-4 pb-0">
                                        <h1 className="modal-title font-semibold color-black font-20" id="editValueNameModalLabel">
                                            Edit Field Name
                                        </h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body px-4">
                                        <div className="mt-3">
                                            <label
                                                className="form-label font-14 font-semibold primary-color"
                                                htmlFor="EditValueName"
                                            >
                                                Field Name
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control custom-form font-13 font-regular py-2"
                                                id="EditValueName"
                                                placeholder="Enter Value Name"
                                                value={newValueName}
                                                onChange={(e) => setNewValueName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer gap-2 text-end pt-4 px-10 border-0 pt-0 pb-25">
                                        <button
                                            type="button"
                                            className="btn cancel-btn font-13 primary-color font-medium"
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className="btn submit-btn font-13 color-white font-medium"
                                            data-bs-dismiss="modal"
                                            onClick={() => saveEditedValueName()}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {/* Configuration Modification popup starts here */}
            <button id="openmypopup" data-bs-toggle="modal" data-bs-target="#staticBackdrop8" style={{ display: 'none' }}>test</button>
            <div className="col-md-12 my-2">
                {/* Modal */}
                <div
                    className="modal fade"
                    id="staticBackdrop8"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel8"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border-size-5">
                            <div className="modal-header border-0 justify-content-center pt-4 pb-1">
                                <img src="images/tick-green.svg" alt="tick-green" />
                            </div>
                            <div className="modal-body">
                                <p className="text-center font-regular font-16 m-0 color-black-v2">
                                    Your modifications have been successfully applied. The newly added
                                    configuration will be effective for the next data scraping
                                    process.
                                </p>
                            </div>
                            <div className="modal-footer justify-content-center border-0 gap-2 pb-3">
                                <button
                                    type="button"
                                    className="btn submit-btn font-13 color-white font-medium"
                                    data-bs-dismiss="modal"
                                >
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Scoring;