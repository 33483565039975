import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "./header/Header";
import { decode } from 'base-64';


import { insertErrorApi, fetchCompliance, fetchDetailsApi, postDetailsApi } from '../services/api';
/* PS_CG_13    to  PS_CG_84  * Compliance being present in the grid format with edit icon, on clicking it the field turns into text box ,
RFP SECTION
- This section represents the sections present in the RFP document where user can find the sections has been took place.
  - In Some cases the RFP section also can't defined properly.
 Requirement Details:
  - In this section the Requirement details of that RFP should be displayed which is analysed by AI from the uploaded documents.
  -Proposal Section:
  - The Proposal section should contain a input text field where user can enter the details manually.
  Responsible Party:
  - The Responsible Party or person name should be added manually.
  Compliance Status:
  - The Compliance status sections should be added manually
Action:
  - An Action section section should be provided where the user can edit a specific section in the compliance matrix.
 */
const Consideration = () => {
  const [staticConsideration, setStaticConsideration] = useState([]);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [editValues, setEditValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [expired, setExpired] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [wrong, setWrong] = useState(false);
  const [consideration, setConsideration] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [loader, setLoader] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();
  console.log('considerations location', location)
  const user_id = location.state?.user_id
  const opportunity_id = location.state?.opportunity_id
  const jwtToken = location.state?.jwtToken
  const opportunityName = location.state?.opportunityName
  const responseType = location.state?.responseType
  const resType = location.state?.resType
  const userID = location.state?.userID
  const type = location.state?.type


  const getDecodedItem = (key) => {
    const encodedValue = localStorage.getItem(key);
    if (!encodedValue) return null;
    return decode(encodedValue.substring(15, encodedValue.length - 10));
  };

  const user_role = getDecodedItem('5mMb2dpbg23Ly')
  const rfp = getDecodedItem('2bTs2dpbg23Ly')
  const rfi = getDecodedItem('m3Da2dpbg23Ly')
  const mail_Id = getDecodedItem('2tMb2dpbg23Ly')
  console.log("THE VALUES GOT AT STATE CONSIDERATION", user_id, opportunity_id, opportunityName, responseType, resType, userID, user_role, mail_Id)

  let ResponseType;
  if (resType?.includes('rfp_document')) {
      ResponseType = 'RFP Response';
  }
  if (resType?.includes('rfi_document')) {
      ResponseType = 'RFI Response';
  }

  let responsiveType;
  if (resType?.toLowerCase() == 'rfp_document') {
    responsiveType = 'RFP'
  } else if (resType?.toLowerCase() == 'rfi_document') {
    responsiveType = 'RFI'
  }
  /*getConsideration This function is to bind the compliance values to the grid  */
  useEffect(() => {
    if (location.state == null) {
      navigate('/', { state: null });
  }
    setLoader(true)
    const timer = setTimeout(() => {
      getConsideration(location.state);
    }, 5000); // 5000 milliseconds = 5 seconds
    // Cleanup the timer if the component unmounts before the timeout completes
    return () => clearTimeout(timer);
  }, []);
 

  const getConsideration = async (object) => {
    setLoader(true)
    try {
      const payload = {
        user_id: user_id,
        oppurtunity_id: object.opportunity_id,
        value: "compliancegrid",
        tab: "oppurtunity",
        type: responsiveType
      };
      /*Makes the call to the aAPI with the required pay load */
      const response = await fetchDetailsApi(payload, jwtToken);
      const responseData = response.data;
      if (response.success == true) {
        if (responseData.complianceGrid.length > 0) {

          setConsideration(responseData.complianceGrid);
          setStaticConsideration(responseData.complianceGrid)
          setEditValues(responseData.complianceGrid);
          setLoader(false)
        } else {
          getCompliance();
        }

      } else if (responseData.message == "Mail id not found") {
        openToast("Email Not Found", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }

    } catch (error) {
      await insertErrorApi({
        error_message: String(error),
        module_name: '__name__',
        function: 'getCompliance()',
        service: 'Frontend',
        user_id: 'user_id',
      });

    }
  };

  /*Makes a api call to the Compliance generation api if no compliance exist */
  const getCompliance = async () => {
    setLoader(true)
    try {
      const payload = {
        user_id: user_id,
        opportunity_id: opportunity_id,
        response: responsiveType,
        response_type: responsiveType
      };
      const response = await fetchCompliance(payload, jwtToken);
      const responseData = JSON.parse(response.data);
      setLoader(false)
      console.log(responseData, 'compliance responseeee');
      if (response.success == true) {
        getConsideration(responseData)
      } else if (response.success == false) {
        openToast("Something Went Wrong");
        navigate('/UploadDocument', { state: { "opportunity_id": opportunity_id, "opportunity_name": opportunityName, "user_id": user_id, "responseType": resType, "jwtToken": jwtToken, "userID": userID, "type": "create" } });
      } else if (response.message == "Mail id not found") {
        openToast("Email Not Found", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }

    } catch (error) {
      await insertErrorApi({
        error_message: String(error),
        module_name: '__name__',
        function: 'getCompliance()',
        service: 'Frontend',
        user_id: 'user_id',
      });

    }
  };
  /* Makes the toast message available */
  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }
  {
    toastMessage && (
      <div className="toast">
        {toastImage && <img src={toastImage} alt="toast" />}
        <p>{toastMessage}</p>
      </div>
    )
  }


  /*Handles the compliance binding */
  const bindConsideration = (consideration) => {
    console.log(consideration, "oopopopopop")
    console.log(editValues, "----------------------------")
    return (
      <>
        {consideration?.map((item, index) => (
          <tr key={index}>
            {editRowIndex == index ? (
              <>
                <td>
                  <input
                    type="text"
                    className="form-control custom-form"
                    name="RFP_section"
                    value={editValues.RFP_section}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control custom-form"
                    value={editValues.requirement_details}
                    name="requirement_details"
                    onChange={(e) => handleChange(e)}
                  />

                </td>
                <td>
                  <input
                    type="text"
                    className="form-control custom-form"
                    name="proposal_section"
                    value={editValues.proposal_section}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control custom-form"
                    placeholder="Enter"
                    name="responsible_party"
                    value={editValues.responsible_party}
                    onChange={(e) => handleChange(e)}
                  />
                </td>
                <td>
                  <select
                    className="form-select custom-select-arrow custom-form"
                    aria-label="Default select example"
                    id="compliance_status"
                    name="compliance_status"

                    value={editValues.compliance_status}
                    onChange={(e) => { handleChange(e) }}
                  >
                    <option selected hidden>Select</option>
                    <option value="Compliance">Compliance</option>
                    <option value="No Compliance">No Compliance</option>
                  </select>
                </td>
                <td className="text-center">
                  <span className="d-flex">
                    <button
                      className="btn px-2 btn-common"
                      onClick={handleSave}
                      disabled={isDisabled}
                      onChange={handleChange}
                    >
                      <img src="images/tick-icon-green.svg" alt="tick-icon" />
                    </button>
                    <button className="btn px-2 btn-common" onClick={handleClose}>
                      <img src="images/close-icon-red.svg" alt="close-icon" />
                    </button>
                  </span>
                </td>
              </>
            ) : (
              <>
                <td>{item.RFP_section}</td>
                <td>{item.requirement_details}</td>
                <td>{item.proposal_section}</td>
                <td>{item.responsible_party}</td>
                <td>{item.compliance_status}</td>
                {/* <td></td> */}
                <td>
                  <button
                    type="button"
                    className="btn btn-common"
                    onClick={() => handleEditClick(index)}
                  >
                    <img src="images/edit-icon-large.svg" alt="edit-icon" />
                  </button>
                </td>
              </>
            )}
          </tr>
        ))}
      </>
    );
  }
  /*The Excel is being exported*/
  const exportToExcel = () => {
    try {
      const XLSX = require('xlsx');
      const worksheet = XLSX.utils.json_to_sheet(consideration.map((data) => { return { RFP_section: data.RFP_section, requirement_details: data.requirement_details, proposal_section: data.proposal_section, responsible_party: data.responsible_party, compliance_status: data.compliance_status } }));
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'consideration.xlsx');
    } catch (error) {
      insertErrorApi({
        error_message: error.toString(),
        module_name: '__name__',
        function: 'exportToExcel',
        service: 'Frontend',
        user_id: 'user_id',
      });

    }
  };
  /*Handles the value in edit click*/
  const handleEditClick = (index) => {
    setEditRowIndex(index);
    setEditValues(consideration[index]);
  };
  /* On change of the value this set states the edited values to the grid */
  const handleChange = (e) => {
    let { name, value } = e.target;
    console.log(value, "value44444444444444444444444")


    const newEditValues = JSON.parse(JSON.stringify(editValues));
    newEditValues[name] = value;
    console.log(newEditValues, "trttttttttttttttttttttttttttttt")
    setEditValues(newEditValues);
    const isRFPSectionEmpty = newEditValues.RFP_section.length == 0;
    console.log(isRFPSectionEmpty, "sedhjk");
    const isRequirementsDetailsEmpty = newEditValues.requirement_details.length == 0;

    setIsDisabled(isRFPSectionEmpty || isRequirementsDetailsEmpty);

  };
  /* The handle save saves the changes made and affects the grid */
  const handleSave = () => {
    console.log("Enteed save", consideration)
    const newData = [...consideration];
    console.log(editValues, "edittttttttttt", newData)
    newData[editRowIndex] = editValues;
    console.log(newData, "FINALLLLL")
    setConsideration(() => newData);
    setEditRowIndex(null);
    setEditValues({});
  };
  console.log(consideration, "Afeter set ......................considerations")
  /*Sets the value to original again */
  const handleClose = () => {
    setEditRowIndex(null);
    setEditValues({});
  };
  /*Navigates to opportunity page */
  const navigateToOpportunity = async () => {
    if (responseType == 'create') {
      let body = {
        tab: "consideration",
        type: "delete",
        opportunity_id: opportunity_id,
        user_id: user_id,
        response_type: responsiveType
      }
      setLoader(true)
      console.log(body)
      const response = await postDetailsApi(body, jwtToken);
      console.log(response)
      setLoader(false)
      if (response.success == true) {
        console.log("NEW CALL")
        navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunityName, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });

      } else if (response.message == "Mail id not found") {
        openToast("Email Not Found", "images/cross - red.svg");
      }

      else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
    }
    else {
      navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunityName, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });
    }
  };
  /* Navigates to upload document */
  const navigateToUploadDocument = async () => {
    
    if (responseType == 'create') {
      let body = {
        tab: "consideration",
        type: "deleteConsideration",
        opportunity_id: opportunity_id,
        user_id: user_id,
        response_type: responsiveType
      }
      setLoader(() => true)
      console.log(body)
      const check = await postDetailsApi(body, jwtToken);
      let response = check
      console.log(response)
      setLoader(() => false)
      if (response.success == true) {
        console.log("NEW CALL")
        navigate('/UploadDocument', { state: { "opportunity_id": opportunity_id, "opportunity_name": opportunityName, "user_id": user_id, "responseType": resType, "jwtToken": jwtToken, "userID": userID, "type": "create" } });

      } else if (response.message == "Mail id not found") {
        openToast("Email Not Found", "images/cross - red.svg");
      }

      else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
    }
    else {
      navigate('/UploadDocument', { state: { "opportunity_id": opportunity_id, "opportunity_name": opportunityName, "user_id": user_id, "responseType": resType, "jwtToken": jwtToken, "userID": userID, "type": "edit" } });
    }
  }
    /* Navigates to RFP proposal document */
    const navigateToRFPproposal = async () => {
        
        console.log("weeeeee")
        let body = {
          tab: "consideration",
          type: "update",
          complianceGrid: consideration,
          user_id: user_id,
        }
        setLoader(() => true)
        
        let response = await postDetailsApi(body, jwtToken)
        
        setLoader(() => false)
        if (response.success == true) {
          console.log("NEW CALL")

        } else if (response.message == "Mail id not found") {
          openToast("Email Not Found", "images/cross - red.svg");
        } else if (response.message == 'Token Expired') {
          openToast("Token Expired", "images/cross - red.svg");
        } else if (response.message == 'User_Id mismatch between JWT token and request body') {
          openToast("Unauthorized Access", "images/cross - red.svg");
        } else if (response.message == 'Token Invalid') {
          openToast("Invalid token", "images/cross - red.svg");
        } else if (response.status == 500) {
          openToast("Internal server error", "images/cross - red.svg");
        }

        navigate('/ResponsePreview', { state: { "opportunity_id": opportunity_id, "user_id": user_id, "resType": resType, "responseType": responseType, "jwtToken": jwtToken, "opportunityName": opportunityName } })
      };

    return (
      <>
        <div>

          {<Header user_id={user_id} jwtToken={jwtToken} moduleName={1} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
        </div>
        {loader ? (
          <div className="container-fluid p-0">
            <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
              <div className="wrapper text-center">
                <div className="circle" />
                <div className="circle" />
                <div className="circle" />
                <div className="shadow" />
                <div className="shadow" />
                <div className="shadow" />
              </div>
              <p className="font-24 font-regular color-white mt-3">Loading</p>
            </div>
          </div>) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 p-5">
                {/* breadcrumb starts here */}
                <ul className="breadcrumb-item mb-4 ps-0">
                  <li>
                    <a className="pe-0 font-13 primary-color font-medium">
                      Opportunities
                    </a>
                  </li>
                  <li className="pe-2 font-13 font-medium">
                    {opportunityName}
                  </li>
                </ul>
                {/* breadcrumb ends here */}
                <div className="p-4 bg-white border-size-5">
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="d-flex gap-2 mb-5 align-items-center">
                        <a className="cursor-pointer lh-1 pb-1" onClick={() => navigateToOpportunity()}>
                          <img src="images/back-icon.svg" alt="Back Icon" onClick={() => navigateToOpportunity()} />
                        </a>
                        <h5 className="font-semibold font-22 m-0">{ResponseType}</h5>
                      </div>
                    </div>
                    {/* stepper starts here */}
                    <div className="col-md-6 mb-5">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center flex-column gap-3">
                          <div className="step-num completed d-flex justify-content-center align-items-center">
                            <img
                              src="images/tick-white.svg"
                              alt="completed-icon"
                              className="tick-img"
                            />
                          </div>
                          <span className="font-16 font-semibold step-text active">Step 1</span>
                        </div>
                        <div className="d-flex align-items-center flex-column gap-3 position-relative">
                          <div className="step-num active d-flex justify-content-center align-items-center font-medium font-18 step-line">
                            <p className="font-medium font-18 m-0">2</p>
                            <img
                              src="images/tick-white.svg"
                              alt="completed-icon"
                              className="tick-img"
                            />
                          </div>
                          <span className="font-16 font-semibold step-text">Step 2</span>
                        </div>
                        <div className="d-flex align-items-center flex-column gap-3 position-relative">
                          <div className="step-num d-flex justify-content-center align-items-center font-medium font-18 step-line">
                            <p className="font-medium font-18 m-0">3</p>
                            <img
                              src="images/tick-white.svg"
                              alt="completed-icon"
                              className="tick-img"
                            />
                          </div>
                          <span className="font-16 font-semibold step-text">Step 3</span>
                        </div>
                      </div>
                    </div>
                    {/* stepper ends here */}
                    {/* table starts here */}
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between mb-3 align-items-center">
                        <h5 className="font-22 font-medium primary-color m-0">Consideration</h5>
                        <button
                          type="button"
                          className="btn primary-btn border-size-5 font-13 font-medium color-white"
                          onClick={() => exportToExcel()}
                        >
                          <img
                            src="images/download-white.svg"
                            alt="download-white"
                            className="me-2"
                          />
                          Download
                        </button>
                      </div>
                      <div className="table-responsive theme-table bg-white">
                        <table className="table table-borderless mb-0">
                          <thead>
                            <tr>
                              <th className="text-nowrap">RFP Section</th>
                              <th className="text-nowrap">Requirement Details</th>
                              <th className="text-nowrap">Proposal Section</th>
                              <th className="text-nowrap">Responsible Party</th>
                              <th className="text-nowrap">Compliance Status</th>
                              <th className="text-nowrap">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {bindConsideration(consideration)}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* table ends here */}







                    <div className="d-flex justify-content-between mt-4">
                      <button
                        type="button"
                        className="btn secondary-outline-btn font-13 font-medium"
                        onClick={() => navigateToUploadDocument()}
                      >
                        Previous
                      </button>
                      <div className="text-end">
                        <button
                          type="button"
                          className="btn cancel-btn font-13 font-medium primary-color me-2"
                          onClick={() => navigateToOpportunity()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn submit-btn font-13 font-medium color-white"
                          onClick={() => { navigateToRFPproposal() }}
                        >
                          <img
                            src="images/ai-white.svg"
                            alt="ai-white"
                            className="me-2"


                          />
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* table ends here */}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  export default Consideration