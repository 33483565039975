//PS_MU_02: Import useState, useEffect, and useContext from react, and other necessary functions from api.js and react-router-dom.
import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "./header/Header";

// Assuming CommonContext and fetchDetailsApi are provided and exported from their respective modules

import { fetchDetailsApi, insertErrorApi, postDetailsApi } from '../services/api';

const ManageUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location', location)
  const user_id = location.state?.user_id
  const jwtToken = location.state?.jwtToken
  const mail_Id = location.state?.mail_Id
  const user_role = location.state?.user_role
  const rfp = location.state?.rfp_access
  const rfi = location.state?.rfi_access
  const [activityChanged, setActivityChanged] = useState(true)

  //PS_MU_05: Initialize state variables
  const [users, setUsers] = useState([]);

  let newUser = { tab: "manageUser", type: "add", business_unit: [], email: "", is_active: "0", rfi: 0, rfp: 0, username: "", role: "", status: "invited", user_id: user_id }
  const [addUser, setAddUser] = useState(newUser)

  let editUser = { tab: "manageUser", type: "edit", business_unit: [], email: "", rfi: 0, rfp: 0, username: "", role: "", user: "", user_id: user_id }
  const [updateUser, setUpdateUser] = useState(editUser)

  let removeUser = { tab: "manageUser", type: "delete", user: "", user_id: user_id, is_active: "" }
  const [deleteUser, setDeleteUser] = useState(removeUser)

  const [dropDownValues, setDropDownValues] = useState([])
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [wrong, setWrong] = useState('')
  const [invalid, setInvalid] = useState('')
  const [expired, setExpired] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState([]);
  const [search, setSearch] = useState('')
  const [role, setRole] = useState('')
  const [businessUnit, setBusinessUnit] = useState('')
  const [isActive, setIsActive] = useState('')
  const [filteredItems, setFilteredItems] = useState([]);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [rfpChecked, setRfpChecked] = useState(false);
  const [rfiChecked, setRfiChecked] = useState(false);
  const [email, setEmail] = useState('')
  const [createErrorMessage, setCreateErrorMessage] = useState('')
  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [filterRole, setFilterRole] = useState('')
  const [toggleMenu, setToggleMenu] = useState(false)


  console.log('roleeee', role)
  useEffect(() => {
    if (location.state == null) {
      navigate('/', { state: null });
  }
    fetchUsers();
  }, []);


  //PS_MU_95: The handleSort function is responsible for sorting a list of user objects based on their user_name property in either ascending or descending order. The function toggles the sort order each time it is called and updates the component state to reflect the sorted list.
  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const sorted = [...filteredItems].sort((a, b) => {
      return newSortOrder === 'asc'
        ? b.user_name.localeCompare(a.user_name)
        : a.user_name.localeCompare(b.user_name);
    });
    setFilteredItems(sorted);
    setSortOrder(newSortOrder);
  };

  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }
  //PS_MU_101: The handleApplyFilter function is designed to filter a list of users based on selected criteria such as role, active status, and business unit. It performs filtering in a straightforward manner and updates the state with the filtered list.
  const handleApplyFilter = () => {
    console.log('0000000000000')
    setToggleMenu(false)
    const filtered = users.filter(user => {
      console.log('==========')
      console.log('users', users)

      console.log('role', filterRole)
      console.log('is active', isActive)
      console.log('businessUnit', businessUnit)


      let roleValue = filterRole
      let isActiveValue = isActive.toLowerCase() == true ? 1 : 0;

      let role_match = filterRole != '' ? user.user_role.toLowerCase() == roleValue.toLowerCase() : true;
      let bU_match = businessUnit != '' ? JSON.parse(user.business_unit).includes(businessUnit) : true;
      let isActive_match = isActive ? user.is_active == isActiveValue : true;

      return role_match && bU_match && isActive_match;
    });
    console.log('---------------------==========', filtered)
    setFilteredItems(filtered);
  };

  console.log('filtered data', filteredItems)

  const handleDropdownChange = (e, setterFunction) => {
    setterFunction(e.target.value);
    setIsApplyDisabled(!role && !businessUnit && !isActive && e.target.value === '');
  };

  const handleResetFilter = () => {
    setFilterRole('');
    setIsActive('');
    setBusinessUnit('');
    setFilteredItems(users); // Reset the filtered items to the full user list
    setActivityChanged(true);
    setSortOrder('asc')
  };

  // Async function to fetch users
  const fetchUsers = async () => {
    const payload = {
      "tab": "manageusers",
      "value": "usergrid",
      "user_id": user_id,
    };
    try {
      setLoading(true)
      const response = await fetchDetailsApi(payload, jwtToken);
      setRole('')
      setRfpChecked(false)
      setRfiChecked(false)
      setLoading(false)
      console.log(response)
      handleApiResponse(response);
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "fetchUsers"
      };
      insertErrorApi(payload);
      console.log(error);
    }
  };

  // Handle API response
  const handleApiResponse = (response) => {

    if (response.success === true) {
      setUsers(response.data.userGrid);
      setFilteredItems(response.data.userGrid)
      console.log('--------', response.data.businessUnit)
      const uniqueBusinessUnits = [...new Set(response.data.businessUnit.map(item => item.business_unit))];
      console.log('resssssssssssssss', uniqueBusinessUnits)

      setDropDownValues([...new Set(response.data.businessUnit.map(item => item.business_unit))])
      setTotalRecords(response.data.userGrid.length);

    } else if (response.message == 'Token Expired') {
      openToast("Token Expired", "images/cross - red.svg");
    } else if (response.message == 'User_Id mismatch between JWT token and request body') {
      openToast("Unauthorized Access", "images/cross - red.svg");
    } else if (response.message == 'Token Invalid') {
      openToast("Invalid token", "images/cross - red.svg");
    } else if (response.status == 500) {
      openToast("Internal server error", "images/cross - red.svg");
    }
  };

  console.log('rfp checked', rfpChecked)
  console.log('rfi cchecked', rfiChecked)
  const handleRoleChange = (e) => {
    const selectedRole = e.target.value;

    console.log('selected role', selectedRole)
    setRole(selectedRole);
    let obj = addUser

    if (selectedRole == '1') { // 1 corresponds to Admin
      setRfpChecked(true);
      setRfiChecked(true);
      obj.rfi = 1
      obj.rfp = 1
      obj.role = 'Admin'
    } else if (selectedRole == '0') {
      setRfpChecked(false);
      setRfiChecked(false);
      obj.rfi = 0
      obj.rfp = 0
      obj.role = 'User'
    } else {
      setRfpChecked(false);
      setRfiChecked(false);
      obj.rfi = 0
      obj.rfp = 0
      obj.role = ''
    }
    setAddUser(obj)
  };
  console.log(role, 'roles');
  console.log('add users', addUser)

  //handleEmailChange is an event handler function designed to update the state with the email address input by the user in a form for adding a new user.
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setAddUser({ ...addUser, email: email });
    setEmail(email)
  }

  //handleUpdateEmailChange is an event handler function to update the state with the email address input by the user in a form for updating an existing user's details.
  const handleUpdateEmailChange = (e) => {
    const email = e.target.value;
    setUpdateUser({ ...updateUser, email: email });
    setEmail(email)
  }

  //handleAdd is an asynchronous function to handle the addition of a new user. It validates the email, interacts with the API to add the user, and updates the UI state based on the API response.
  const handleAdd = async () => {
    try {
      console.log('came to adding')
      if (!emailRegex.test(email) && email === "") {
        openToast("Invalid email format", "images/cross - red.svg")
        return;
      }
      if ((addUser.username).trim() == "") {
        openToast("Enter the Username", "images/cross - red.svg")
        return;
      }
      if (addUser.business_unit.length == 0) {
        openToast("Enter the Business Unit", "images/cross - red.svg");
        return;
      }
      if (addUser.rfp == 0 && addUser.rfi == 0 && addUser.role == 'User') {
        openToast("Select any one option", "images/cross - red.svg");
        return;
      }
      if ((addUser.rfp == 0 || addUser.rfi == 0) && addUser.role == 'Admin') {
        openToast("Select both option", "images/cross - red.svg");
        return;
      }
      if (addUser.role == '') {
        openToast("Select the Role", "images/cross - red.svg");
        return;
      }
      console.log('saiiiiiiiii')

      console.log('adddddddd userrrrrrr', addUser)
      setLoading(true)
      const response = await postDetailsApi(addUser, jwtToken);
      console.log("000000000000000000", response)
      if (response.success === true) {
        fetchUsers();
        setAddUser(newUser)
        setRole('')
        setRfpChecked(false)
        setRfiChecked(false)
      } else if (response.Message === "mail id already exists") {
        openToast("Mail id already exists, Enter new mail address to add", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
      setLoading(false)
      console.log(response)
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleAdd"
      };
      insertErrorApi(payload);
      console.log(error);
    }
  }


  //handleEditing is an asynchronous function to handle the updating of an existing user's details. It validates the email, interacts with the API to update the user, and updates the UI state based on the API response.
  const handleEditing = async () => {
    try {
      console.log(role, "wasd");
      setRole('')
      console.log('came to editing field')
      if (!emailRegex.test(updateUser.email)) {
        openToast("Invalid email format", "images/cross - red.svg")
        return;
      }
      if (!(updateUser.username).trim()) {
        openToast("Enter the Username", "images/cross - red.svg")
        return;
      }
      if (updateUser.business_unit.length == 0) {
        openToast("Enter the Business Unit", "images/cross - red.svg");
        return;
      }
      if (updateUser.rfp == 0 && updateUser.rfi == 0 && updateUser.role == 'User') {
        openToast("Select any one option", "images/cross - red.svg");
        return;
      }
      if ((updateUser.rfp == 0 || updateUser.rfi == 0) && updateUser.role == 'Admin') {
        openToast("Select both option", "images/cross - red.svg");
        return;
      }
      if (updateUser.role == '') {
        openToast("Select the Role", "images/cross - red.svg");
        return;
      }
      console.log('saiiiiiiiii')
      console.log('adddddddd userrrrrrr', updateUser)
      setLoading(true)
      const response = await postDetailsApi(updateUser, jwtToken);
      if (response.success === true) {
        fetchUsers();
      } else if (response.status == 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
      setLoading(false)
      setIsEditing(null);
      console.log(response)
    }
    catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleEditing"
      };
      insertErrorApi(payload);
      console.log(error);
    }
    fetchUsers();
  }
  console.log('deleteeeeee', deleteUser)

  //PS_MU_67: When admin deletes a user, toggle the Inactive status from yes to no.
  const handleIsActive = async (index) => {
    let active = filteredItems[index].is_active ? 0 : 1
    console.log('activeeeeee', active)
    const updatedItems = [...filteredItems];
    console.log('updated itrems', updatedItems)
    // Update the is_active property for the specific index
    updatedItems[index] = {
      ...updatedItems[index],
      is_active: active
    };

    // Get the user ID of the item at the specified index
    let userId = filteredItems[index].user_id;

    // Create the deleteUser object with the updated is_active value
    let deleteUserObj = {
      tab: "manageUser",
      type: "delete",
      user: userId,
      user_id: user_id,
      is_active: active
    };

    // Update the deleteUser state
    setDeleteUser(deleteUserObj);

    // Call deletingUser function to perform the delete operation
    await deletingUser(deleteUserObj);
  }

  console.log('-----filtered items-----', filteredItems, 'is activeeee', isActive)
  //PS_MU_69: Create an async function handleDelete to process user deletion by toggling the isActive status.
  const deletingUser = async (deleteUserObj) => {
    try {
      console.log('delete user', deleteUserObj)
      setLoading(true)
      const response = await postDetailsApi(deleteUserObj, jwtToken);
      console.log('response', response)
      if (response.status == 200) {
        const updatedItems = filteredItems.map(item => {
          console.log('itemmmm', item, 'deleteusetttobjj', deleteUserObj)
          if (item.user_id == deleteUserObj.user) {
            // Assuming 'active' is a boolean field
            console.log('isActive', isActive)
            return { ...item, is_active: !item.is_active }; // Update 'active' field to false
          }
          return item; // Return other items unchanged
        });
        console.log('updatedItems', updatedItems)
        setFilteredItems(updatedItems);
        setUsers(updatedItems)

        setLoading(false)
      } else if (response.status == 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
    }
    catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "deletingUser"
      };
      insertErrorApi(payload);
      console.log(error);
    }
  }

  console.log('deleteeeeeiiiiiiiiiiii', deleteUser)


  // Define more functions or JSX as needed for ManageUser component
  console.log("---------------------------------------", users)
  console.log('drop down.......', dropDownValues)
  console.log('========', totalRecords)

  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }

  const handleClose = () => {
    setIsEditing(false); // Assuming setIsEditing is a state setter function to control the visibility of the pop-up
  };

  //PS_MU_48: Trigger handleEdit function to prefill the form with the user's current data and Prepare the edited user data for submission, set isEditing to true.
  const handleEdit = (index) => {
    setIsEditing(index)
    let obj = filteredItems[index]
    setUpdateUser({ tab: "manageUser", type: "edit", business_unit: JSON.parse(obj.business_unit), email: obj.email_id, rfi: obj.rfi_access ? 1 : 0, rfp: obj.rfp_access ? 1 : 0, username: obj.user_name, role: obj.user_role.toLowerCase() == 'admin' ? "Admin" : 'User', user: obj.user_id, user_id: user_id })
  };

  console.log('update users', updateUser)

  function bindingTableData() {
    try {
      console.log(users, "ooooooooooooooooooooo")
      console.log('filteredItems.......', filteredItems)
      console.log(updateUser, "hiiiii");
      return filteredItems.map((user, index) => {
        console.log(updateUser, "heloooooooooooooo")
        return (
          <tr key={index}>
            {isEditing == index ? (<>

              <td className="ps-4 pe-5">
                <input
                  type="text"
                  className="form-control custom-form"
                  placeholder="Enter Username"
                  value={updateUser.username}
                  onChange={(e) => setUpdateUser({ ...updateUser, username: e.target.value })}
                />
              </td>
              <td className="pe-5">
                <input
                  type="text"
                  className="form-control custom-form"
                  placeholder="Enter Email Address"
                  value={updateUser.email}
                  onChange={(e) => handleUpdateEmailChange(e)}
                />
              </td>
              <td>
                <div className="input-group">
                  <div className="dropdown w-75">
                    <button
                      className="btn bg-white color-black font-14 dropdown-width text-start gap-3 custom-border d-flex justify-content-between align-items-center dropdown-show custom-form"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"

                    >
                      {updateUser.business_unit && updateUser.business_unit.length > 0
                        ? (
                          <span className="dropdown-text font-13 font-regular" >
                            {updateUser.business_unit.join(', ')}
                          </span>
                        )
                        : (
                          <span className="dropdown-text font-13 font-regular">Select</span>
                        )
                      }
                      <img
                        src="./images/select-arrow.svg"
                        alt="dropdown-arrow"
                      />
                    </button>
                    <ul className="dropdown-menu dropdown-width py-1 rounded-start dropdown-box-shadow border-0" aria-labelledby="dropdownMenuButton" >
                      {bindDropDownValuesEdit(index)}
                    </ul>
                  </div>
                </div>
              </td>
              <td className="pe-5">
                <select
                  className="form-select custom-select-arrow custom-form"
                  aria-label="Default select example"
                  id="ComplianceStatus"
                  value={updateUser.role}

                  onChange={(e) => {
                    console.log('update user role', updateUser.role)
                    setUpdateUser({ ...updateUser, role: e.target.value })
                  }}
                >
                  <option value=''>Select</option>
                  <option value={'Admin'}>Admin</option>
                  <option value={'User'}>User</option>
                </select>
              </td>
              <td className="pe-4">
                <input
                  type="checkbox"
                  className="form-check-input cust-form-check cursor-pointer"
                  id="checkbox1"
                  checked={updateUser.rfp == 1 ? true : false}
                  onChange={(e) => {
                    setUpdateUser(prevUser => ({
                      ...prevUser,
                      rfp: e.target.checked ? 1 : 0 // Set to 1 if checked, otherwise 0
                    }));
                  }}
                />
              </td>
              <td className="pe-4">
                <input
                  type="checkbox"
                  className="form-check-input cust-form-check cursor-pointer"
                  id="checkbox2"
                  checked={updateUser.rfi == 1 ? true : false}
                  onChange={(e) => {
                    setUpdateUser(prevUser => ({
                      ...prevUser,
                      rfi: e.target.checked ? 1 : 0 // Set to 1 if checked, otherwise 0
                    }));
                  }}
                />
              </td>
              <td />
              <td />
              <td className="text-center">
                <span className="d-flex">
                  <button
                    className="btn px-2 btn-common"
                    onClick={() => handleEditing()}

                  >
                    <img src="images/tick-icon-green.svg" alt="tick-icon" />
                  </button>
                  <button className="btn px-2 btn-common"
                    onClick={() => { handleClose(); setIsEditing(null) }}
                  >
                    <img src="images/close-icon-red.svg" alt="close-icon" />
                  </button>
                </span>
              </td>
              {/* Display the error message */}
              {editErrorMessage && <div className="font-14 font-regular warning">{editErrorMessage}</div>}

            </>) : (
              <>
                <td className="ps-4">{user.user_name}</td>
                <td>{user.email_id}</td>
                <td>{JSON.parse(user.business_unit).join(', ')}</td>
                <td>{user.user_role}</td>
                {user.rfp_access ? <td>Yes</td> : <td>No</td>}
                {user.rfi_access ? <td>Yes</td> : <td>No</td>}
                <td>
                  <span className="form-check form-switch d-flex justify-content-center">
                    {user.is_active ? <><input
                      className="form-check-input custom-checkbox me-2 cursor-pointer"
                      type="checkbox"
                      id={`isActive${index}`}
                      checked={true}
                      onChange={() => { handleIsActive(index) }}
                    />
                      <label className="form-check-label" htmlFor={`isActive${index}`}>
                        Yes
                      </label>
                    </> : <><input
                      className="form-check-input custom-checkbox me-2 cursor-pointer"
                      type="checkbox"
                      checked={false}
                      id={`isActive${index}`}
                      onChange={() => { handleIsActive(index) }}
                    />
                      <label className="form-check-label" htmlFor={`isActive${index}`}>
                        No
                      </label>
                    </>}
                  </span>
                </td>
                <td>{user.user_status}</td>
                <td className="text-center">
                  <span className="d-inline-block">
                    <button className="btn d-flex align-items-center justify-content-center btn-common" onClick={() => handleEdit(index)}
                    >
                      <img
                        src="images/edit-outline.svg"
                        alt="outline-edit-icon"
                      />
                    </button>
                  </span>
                </td>
              </>)}
          </tr>
        )

      })

    }
    catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "ForgotPassword.jsx",
        function_name: "handleSendEmail",
        user_id: user_id,
      };
      console.log(error)
    }

  }

  console.log('updateeeeeee', updateUser)

  const handleSearch = (term) => {
    console.log(term, "term>>>>>>>")
    if (term.length === 0) {
      setFilteredItems(users);

    } else {
      console.log('------')
      const searchData = users.filter((item) =>
        String(item.user_name).toLowerCase().includes(term.toLowerCase())
      );
      console.log('search data', searchData)
      setFilteredItems(searchData);
    }
    setTotalRecords(users.length)
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    console.log('term', term)
    setSearch(term);
    handleSearch(term);
  };

  console.log('search terms', search)
  console.log('searcs', users)


  const handleCheckboxClick = (value) => {
    console.log(value, "hello")
    let obj = addUser.business_unit
    if (obj.includes(value)) {
      obj = obj.filter((prev) => prev !== value);
    } else {
      obj = [...obj, value];
    }
    setAddUser({ ...addUser, business_unit: obj })
  };


  function bindDropDownValues() {
    console.log("ertyuio")
    console.log('sssss', dropDownValues)
    return dropDownValues.map((value, index) => (
      <li className="cursor-pointer dropdown-item bg-white cursor-pointer px-0" key={index}>
        <span className="cursor-pointer d-flex justify-content-between align-items-center mx-1 rounded-2 dropdown-list">
          <span className="cursor-pointer ms-2 mb-1 d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              className="cursor-pointer form-check-input cust-form-check m-0"
              value={value}
              checked={addUser.business_unit.includes(value)}
              onChange={() => handleCheckboxClick(value)}
            />
            <label
              htmlFor={`unitcheck${index}`}
              className="font-13 font-regular color-black mt-1 text-wrap ms-1">
              {value}
            </label>
          </span>
        </span>
      </li>
    ));
  };


  const handleCheckboxClickEdit = (value) => {
    console.log(value, "hello")
    let obj = updateUser.business_unit
    if (obj.includes(value)) {
      obj = obj.filter((prev) => prev !== value);
    } else {
      obj = [...obj, value];
    }
    setUpdateUser({ ...updateUser, business_unit: obj })
  };



  console.log(updateUser.business_unit, "selected")
  function bindDropDownValuesEdit() {
    console.log("ertyuio")
    console.log('sssss', dropDownValues)
    return dropDownValues.map((value, index) => (
      <li className="cursor-pointer dropdown-item bg-white cursor-pointer px-0" key={index}>
        <span className="cursor-pointer d-flex justify-content-between align-items-center mx-1 rounded-2 dropdown-list">
          <span className="cursor-pointer ms-2 mb-1 d-flex justify-content-center align-items-center">
            <input
              type="checkbox"
              className="cursor-pointer form-check-input cust-form-check m-0"
              value={value}
              checked={updateUser?.business_unit.includes(value)}
              onChange={() => handleCheckboxClickEdit(value)}
            />
            <label
              htmlFor={`unitcheck${index}`}
              className="cursor-pointer font-13 font-regular color-black mt-1 text-wrap">
              {value}
            </label>
          </span>
        </span>
      </li>
    ));
  };

  function toggleApplyButton() {
    setActivityChanged(false);
  }
  return (

    <>
      <div>

        {<Header user_id={user_id} jwtToken={jwtToken} moduleName={3} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
      </div>
      {loading ? (
        <div className="container-fluid p-0">
          <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
            <div className="wrapper text-center">
              <div className="circle" />
              <div className="circle" />
              <div className="circle" />
              <div className="shadow" />
              <div className="shadow" />
              <div className="shadow" />
            </div>
            <p className="font-24 font-regular color-white mt-3">Loading</p>
          </div>
        </div>
      ) : (

        <>

          <div className="container-fluid">
            <div className="row">
              {isToastOpen && (<div className="container-fluid">
                <div className="d-flex justify-content-right align-items-end flex-column position-fixed end-0" style={{zIndex:"999", top:"100px"}}>
                  <div className="px-2 mx-3 my-2 toast-container p-2">
                    <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                      <span>
                        <img
                          src={toastImage}
                          alt="tick-green-icon"
                          className="me-2 toast-img cursor-pointer"
                        />
                      </span>{" "}
                      {toastMessage}
                    </p>
                  </div>
                </div>
              </div>)}
              <div className="col-md-12 p-5">
                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                  <h5 className="font-22 font-semibold">Manage Users</h5>
                  <div className="d-flex gap-3 w-md-100 flex-wrap">
                    <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100 position-relative">
                      <div className="d-flex gap-3 flex-md-row w-md-100 align-items-center">
                        <div className="input-group input-search">
                          <input
                            type="search"
                            className="form-control border-end-0 font-14 font-regular search-input"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => handleInputChange(e)}
                          />
                          <button
                            className="btn border-start-0 search-input"
                            type="button"
                          >
                            <img src="images/search.svg" alt="search" className="mb-1" />
                          </button>
                        </div>

                          <button
                            className="btn filter-btn btn-common lh-0"
                            id="dropdownMenuLink"
                            aria-expanded="false"
                            style={{ display: "flex" }}
                            onClick={() => setToggleMenu(true)}
                          >
                            <img
                              src="images/filter-active.svg"
                              alt="filter-icon"
                              className="filter-icon"
                            />
                          </button>
                          {/*filter*/}
                          <div
                            className={`dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0`} style={toggleMenu ? { position: "absolute", inset: "0px auto auto 0px", margin: "0px", top:"50px",left:"-100px", display: "block" } : {}
                          }
                          >
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="font-semibold font-20 mb-0">
                              Advanced Filter
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              onClick={() => { handleResetFilter(); setToggleMenu(false) }}
                              aria-label="Close"
                            />
                          </div>
                          <div className="row" style={{ display: "flex" }}>
                            <div className="col-md-12 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color "
                                htmlFor="activestate"
                              >
                                Is Active
                              </label>
                              <select
                                className="cursor-pointer form-select custom-select-arrow custom-form"
                                aria-label="Default select example"
                                id="activestate"
                                value={isActive}
                                onChange={(e) => { toggleApplyButton(); handleDropdownChange(e, setIsActive) }}
                              >
                                <option selected="" hidden>Select</option>
                                <option value={1}>Yes</option>
                                <option value={0}>No</option>
                              </select>
                            </div>
                            <div className="col-md-12 mb-4">
                              <label
                                className="form-label font-14 font-semibold"
                                htmlFor="Role"
                              >
                                Role
                              </label>
                              <select
                                className="cursor-pointer form-select custom-select-arrow custom-form"
                                aria-label="Default select example"
                                id="Role"
                                value={filterRole}
                                onChange={(e) => { toggleApplyButton(); handleDropdownChange(e, setFilterRole) }}
                              >
                                <option selected="" hidden>Select</option>
                                <option value={"Admin"}>Admin</option>
                                <option value={"User"}>User</option>
                              </select>
                            </div>
                            <div className="col-md-12 mb-4">
                              <label
                                className="form-label font-14 font-semibold"
                                htmlFor="BusinessUnitType"
                              >
                                Business Unit
                              </label>
                              <select
                                className="cursor-pointer form-select custom-select-arrow custom-form"
                                aria-label="Default select example"
                                id="BusinessUnitType"
                                value={businessUnit}
                                onChange={(e) => { toggleApplyButton(); handleDropdownChange(e, setBusinessUnit) }}
                              >
                                <option selected="" hidden>Select</option>
                                {dropDownValues.map((value, index) => (
                                  <option key={index} value={value}>
                                    {value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-12">
                              <div className="d-flex justify-content-end align-items-center py-3">
                                <button className="btn cancel-btn me-3 font-semibold font-13 primary-color"
                                  onClick={() => { handleResetFilter(); setToggleMenu(false) }}
                                >
                                  Cancel
                                </button>
                                <button className="btn submit-btn color-white font-regular font-13"
                                  onClick={handleApplyFilter} disabled={(activityChanged) ? true : false}>
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive theme-table bg-white ">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th className="text-nowrap ps-4" onClick={handleSort}>
                          Username
                          <span className="ms-1 cursor-pointer">
                            {sortOrder === 'asc' ? (
                              <img src="images/sort-arrow-down.svg" alt="sort-arrow-down.svg" />
                            ) : (
                              <img src="images/sort-up-arrow.svg" alt="images/sort-up-arrow.svg" />
                            )}
                          </span>
                        </th>
                        <th className="text-nowrap">Email Address</th>
                        <th className="text-nowrap">Business Unit</th>
                        <th className="text-nowrap">Role</th>
                        <th className="text-nowrap">RFP</th>
                        <th className="text-nowrap">RFI</th>
                        <th className="text-nowrap text-center">Is Active</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="ps-4 pe-5">
                          <input
                            type="text"
                            className="form-control custom-form"
                            placeholder="Enter Username"
                            value={addUser.username}
                            onChange={(e) => setAddUser({ ...addUser, username: e.target.value })}
                          />
                        </td>
                        <td className="pe-5">
                          <input
                            type="text"
                            className="form-control custom-form"
                            placeholder="Enter Email Address"
                            value={addUser.email}
                            onChange={(e) => handleEmailChange(e)}
                          />
                        </td>
                        <td>
                          <div className="input-group">
                            <div className="dropdown w-75">
                              <button
                                className="btn bg-white color-black font-14 dropdown-width text-start gap-3 custom-border d-flex justify-content-between align-items-center dropdown-show custom-form"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {addUser.business_unit && addUser.business_unit.length > 0
                                  ? (
                                    <span className="dropdown-text font-13 font-regular">
                                      {addUser.business_unit.join(', ')}
                                    </span>
                                  )
                                  : (
                                    <span className="dropdown-text font-13 font-regular">Select</span>
                                  )
                                }
                                <img
                                  src="./images/select-arrow.svg"
                                  alt="dropdown-arrow"
                                />
                              </button>
                              <ul className="dropdown-menu dropdown-width py-1 rounded-start dropdown-box-shadow border-0 cursor-pointer " aria-labelledby="dropdownMenuButton">
                                {bindDropDownValues()}
                              </ul>
                            </div>
                          </div>
                        </td>
                        <td className="pe-5">
                          <select
                            className="form-select custom-select-arrow custom-form cursor-pointer "
                            aria-label="Default select example"
                            id="ComplianceStatus"
                            value={role}
                            onChange={(e) => handleRoleChange(e)}
                          >
                            <option value="">Select</option>
                            <option value={1}>Admin</option>
                            <option value={0}>User</option>

                          </select>
                        </td>
                        <td className="pe-4">
                          <input
                            type="checkbox"
                            className="form-check-input cust-form-check cursor-pointer"
                            id="checkbox1"
                            checked={rfpChecked}
                            onChange={(e) => {
                              setRfpChecked(e.target.checked);
                              setAddUser(prevUser => ({
                                ...prevUser,
                                rfp: e.target.checked ? 1 : 0
                              }));
                            }}
                          />
                        </td>
                        <td className="pe-4">
                          <input
                            type="checkbox"
                            className="form-check-input cust-form-check cursor-pointer"
                            id="checkbox2"
                            checked={rfiChecked}
                            onChange={(e) => {
                              setRfiChecked(e.target.checked);
                              setAddUser(prevUser => ({
                                ...prevUser,
                                rfi: e.target.checked ? 1 : 0
                              }));
                            }}
                          />
                        </td>
                        <td />
                        <td />
                        <td className="text-center">
                          <span className="d-inline-block">
                            <button className="btn d-flex align-items-center justify-content-center btn-common"
                              onClick={() => handleAdd()}
                            >
                              <img
                                src="images/green-plus-icon.svg"
                                alt="green-plus-icon"
                              />
                            </button>
                          </span>
                        </td>
                      </tr>
                      {bindingTableData()}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-between mt-4">
                  <p className="font-14 font-regular color-grey-v2">
                    {filteredItems.length > 0 ? (
                      <>
                        # of Records :{" "}
                        <span className="font-semibold font-16 color-black">{filteredItems.length}</span> out of{" "}
                        <span className="font-semibold font-16 color-black">{totalRecords}</span>
                      </>
                    ) : (
                      <>
                        # of Records :{" "}
                        <span className="font-semibold font-16 color-black">{totalRecords}</span> out of{" "}
                        <span className="font-semibold font-16 color-black">{totalRecords}</span>
                      </>
                    )}
                  </p>
                </div>

              </div>
            </div>
          </div>
        </>

      )}
    </>
  )
}

export default ManageUser;