//  import necessary packages...PS_01 to 06

import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { insertErrorApi, postDetailsApi, fetchDetailsApi } from '../services/api'; // Assuming these functions are defined in your API service
import { BlobServiceClient } from '@azure/storage-blob';
import { decode } from 'base-64';
import Header from "./header/Header";
import Loader from './loader/Loader'


const UploadDocument = () => {
  //  import all the state variables and functions PS_10 to 21
  const [loader, setLoader] = useState(false)
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [filesExisting, setFilesExisting] = useState([])
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [errorMessage, setErrorMessage]=useState('')
  const [type,setType]=useState('')
  const maxFiles = 3;
  const [fileBinding, setFileBinding] = useState([])
  const maxSize = 5 * 1024 * 1024;
  const navigate = useNavigate();
  const location = useLocation();
  console.log('upload location', location)
  const opportunity_id = location.state?.opportunity_id
  const user_id = location.state?.user_id
  const responseType = location.state?.responseType
  const userID = location.state?.userID
  const jwtToken = location.state?.jwtToken
  const types = location.state?.type

  const opportunity_name = location.state?.opportunity_name
  console.log('opppopppp',opportunity_name)
  console.log('user_iddddd', user_id, 'responseType',responseType)
  console.log('typeeeee',type)
  console.log('typeesss',types)


  const [fileCount, setFileCount] = useState(0)
  console.log('user id', user_id, 'userID', userID)

  // Specify the responsive type as RFP Response if response type is rfp_document, same for rfi document, responsive type is RFI response..PS_22
  let responsiveType;
  if (responseType?.toLowerCase() == 'rfp_document') {
    responsiveType = 'RFP Response'
  } else if (responseType?.toLowerCase() == 'rfi_document') {
    responsiveType = 'RFI Response'
  }

  let response_type;
  if (responseType?.toLowerCase() == 'rfp_document') {
    response_type = 'RFP'
  } else if (responseType?.toLowerCase() == 'rfi_document') {
    response_type = 'RFI'
  }
  console.log(responseType,"dsxaaaaaaaa")


  // call fetchBlobDetails in useEffect which is to perform on load..PS_23
  useEffect(() => {
    if (location.state == null) {
      navigate('/', { state: null });
  }
    fetchBlobDetails();
  }, []);


  // getDecodedItem arrow function is created to decode stored local storage keys.Variables mail_Id, user_role, rfp, rfi are created and decoded keys are stored.PS_24,25
  const getDecodedItem = (key) => {
    const encodedValue = localStorage.getItem(key);
    if (!encodedValue) return null;
    return decode(encodedValue.substring(15, encodedValue.length - 10));
  };

  const mail_Id = getDecodedItem('2tMb2dpbg23Ly')
  const user_role = getDecodedItem('5mMb2dpbg23Ly')
  const rfp = getDecodedItem('2bTs2dpbg23Ly')
  const rfi = getDecodedItem('m3Da2dpbg23Ly')
  console.log('mail iffffffddd', mail_Id)
  console.log("THE VALUES GOT AT STATE uploadDocument", user_id, opportunity_id, responseType, userID, user_role, mail_Id)

  const fileInputRef = useRef(null);// Reference to the file input

  //  The fetchBlobDetails function is an asynchronous function designed to fetch and process document details related to an opportunity. And need to add the fileNames to the setFileBinding and fileNames to the existingFiles
  //  PS_26 to 41
  const fetchBlobDetails = async () => {
    setLoader(true);
    try {
      const response = await fetchDetailsApi({
        "oppurtunity_id": opportunity_id,
        "value": "responsedocument",
        "tab": "oppurtunity",
        "user_id": user_id,
        "type": response_type
      }, jwtToken);
      
      console.log('responseee', response);
  
      const ress = response.data;
      console.log('ress', ress);
  
      // Check if the response type is 'rfi_document' and rename the file names if needed
      const responseDocuments = responseType === 'rfi_document'
        ? ress.responseDocument.map(item => {
            let newFileName = item.file_name;
            if (item.file_name === 'RFI_document.pdf') {
              newFileName = 'RFI_document.pdf';
            }
            return { ...item, file_name: newFileName };
          })
        : ress.responseDocument;
  
      setFilesExisting(responseDocuments.map(item => item.document_id));
      const fileNames = responseDocuments.map(item => item.file_name);
      console.log('file name', fileNames);
  
      setFileBinding(responseDocuments.map(item => ({ file_name: item.file_name, type: 'old', document_id: item.document_id })));
      setExistingFiles(fileNames);
      console.log(fileNames.length,"existstts");
      setType(fileNames.length === 0 ? 'create' : (types === 'create' || types === 'edit') ? types : 'edit');
      console.log(type,"weds");
      setSubmitEnabled(fileNames.length > 0);
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "fetchBlobDetails",
        user_id: user_id
      };
      insertErrorApi(payload);
      console.log(error);
    }
    setLoader(false);
  };

  
  console.log('existing files', existingFiles)
  console.log('fileBindngggggggggg', fileBinding)
  console.log('changeeeeeeee', filesExisting)




  // PS_42 to 54
  // The handleFileChange function manages file selection for upload, ensuring only valid PDF files are selected, no more than one "RFP document" is included, and the total number and size of files do not exceed predefined limits. If any conditions are not met, an error message is displayed.
  const handleFileChange = (e) => {
    try {
      console.log(e.target.value, "okokokok");
      setFileCount(fileCount + 1)
      console.log("filllllllllllllllllllleeeeeeeeeeeeeeeeeeeee", fileCount)
      var newFiles = [...fileBinding];
      console.log('new files', newFiles)

      // fileBinding.length != 0 && newFiles.push(fileBinding) // Combine existing files with newly selected files and Combines the existing files stored in fileBinding with the newly selected files.

      console.log('files binding', fileBinding)
      console.log('proint', newFiles)
      // Converts the selected files from a FileList to an array of file objects, each containing the file's name and size.

      var selectedFilesData = Array.from(e.target.files)
      let selectedFiles = []
      selectedFilesData.forEach((file) => {
        selectedFiles.push({ file_name: file.name, type: 'new', file: file });
      });
      newFiles = [...newFiles, ...selectedFiles]

      console.log('selected files', selectedFiles);

      // Checks if any of the selected files are not PDF files and filters them out.Displays an error message if there are invalid files.
      const invalidFiles = selectedFiles.filter(file => !file.file_name.toLowerCase().endsWith('.pdf'));
      console.log(invalidFiles, "ppppppppppppp");
      if (invalidFiles.length > 0) {
        showError('Only PDF files are allowed.', "images/cross - red.svg");
        return;
      }

      if(responseType == 'rfp_document' ){
      const invalidFilesName = newFiles.filter(file => file.file_name.toLowerCase().includes('rfp_document'));
      console.log(invalidFilesName, "ppppppppppppp");
      if (invalidFilesName.length > 1) {
        showError('The files you upload must include only one RFP document.', "images/cross - red.svg");
        e.target.value = null
        return;
      }
    }
    else{
      const invalidFilesName = newFiles.filter(file => file.file_name.toLowerCase().includes('rfi_document'));
      console.log(invalidFilesName, "ppppppppppppp");
      if (invalidFilesName.length > 1) {
        showError('The files you upload must include only one RFI document.', "images/cross - red.svg");
        return;
      }
    }

      // Ensures the total number of files does not exceed a predefined limit (e.g., 3 files).Displays an error message if the file count exceeds the limit.
      if (newFiles.length > maxFiles) {
        showError('You can upload a maximum of 3 files', "images/cross - red.svg");
        return;
      }

      // Calculates the total size of all files and ensures it does not exceed a predefined limit (e.g., 5MB).Displays an error message if the total file size exceeds the limit.
      if (selectedFiles[0]?.file?.size > maxSize) {
        showError('File size should not exceed 5MB.', "images/cross - red.svg");
        return;
      }
      console.log('seleccted files', selectedFiles)

      setSubmitEnabled(true);
      setFileBinding([...newFiles])
      setFiles(newFiles);
      console.log(e.target.value, "okokokok1");
      e.target.value=null
      console.log(e.target.value, "okokokok2");

    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleFileChange"
      };
      insertErrorApi(payload);
      console.log(error)
    }
  };
  console.log('saiiiii', fileBinding)
  console.log('filesssssss', files)
  console.log('files', files)
  console.log('exisying files', existingFiles)

  // Handle "browse" click
  const handleBrowseClick = (event) => { // Prevent default hyperlink behavior
    fileInputRef.current.click(); // Programmatically trigger file input click
  };

  // check if responseType is 'rfp_document' or 'rfi_response' and files uploaded should have 'RFP_document.pdf' else returns error ...PS_55,59
  const validateFiles = () => {
    try {
      console.log('filllessss', files)
      console.log('fillle bindinggg', fileBinding)
      if (responseType === 'rfp_document' && !fileBinding.some(fileArray => fileArray.file_name.includes('RFP_document.pdf'))) {
        showError('*The RFP document file name is incorrect. Please ensure the file name is in the format: RFP_document.pdf');
        return false;
      }
      if (responseType === 'rfi_document' && !files.some(fileArray => fileArray.file_name.includes('RFI_document.pdf'))) {
        showError('*The RFI document file name is incorrect. Please ensure the file name is in the format: RFI_document.pdf');
        return false;
      }
      return true;
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "validateFiles"
      };
      insertErrorApi(payload);
    }
  };

  // removeFileBinding function manages the removal of a file from the fileBinding array while updating the appropriate states based on whether the file is from existingFiles or newly selected files..PS_60 to 66
  const removeFileBinding = (index) => {
    setType('create')
    const updatedFileBinding = [...fileBinding];
    const removedFile = updatedFileBinding.splice(index, 1)[0];

    // Assuming each file object has a `user_id` property
    // Check if the removed file is from existingFiles
    let isExistingFile = false;

    if (typeof removedFile === 'string') {
      // Check if the removed file is in the existingFiles list and get the user_id
      for (const file of existingFiles) {
        console.log('fileeee', existingFiles)
        if (file === removedFile) {
          isExistingFile = true;
          break;
        }
      }
      console.log('user id', user_id, 'userID', userID)
      // If the documents are uploaded by the existing user and if new user comes and changes anything for the existign documents then they dont have permision for removing the documents
      if (userID) {
        if (isExistingFile && user_id !== userID) {
          showError('You do not have permission to remove this file.', "images/cross - red.svg");
          return;
        }
      }

      // If the file is from existingFiles, filter it out and update existingFiles state.If the file is from files, filter it out and update files state.Update fileBinding and submitEnabled states accordingly.
      // Update existingFiles state
      const updatedExistingFiles = existingFiles.filter(file => file !== removedFile);
      setExistingFiles(updatedExistingFiles);
    } else {
      // Update files state for newly selected files
      const updatedFiles = files.filter(file => file !== removedFile);
      setFiles(updatedFiles);
    }


    // Update fileBinding and submitEnabled states
    setFileBinding(updatedFileBinding);
    setSubmitEnabled(updatedFileBinding.length > 0);
  };

  const clearConsideration = async () =>{
    let body = {
        tab: "responsePreview",
        type: "clearConsideration",
        opportunity_id: opportunity_id,
        user_id: user_id,
        response_type: response_type
      }
      setLoader(() => true)
      console.log(body)
      const check = await postDetailsApi(body, jwtToken);
      let response = check
      console.log(response)
      setLoader(() => false)
      if (response.success == true) {
        console.log("NEW CALL")
      } else if (response.message == "Mail id not found") {
        openToast("Email Not Found", "images/cross - red.svg");
      }
      else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      }
    }

  // If the existing files length is greater than zero then are two scenarios handling. One is for insert flow and second is for delete flow.
  // If the user removes any existing documents then there executes a delete query in the backend
  //  if the user adds the new documents then there executes a insery query in backend...PS_67 to 75
  const handleNextClick = async () => {
    try {
      if (existingFiles.length > 0) {
        // Flatten the arrays
        console.log(existingFiles.flat(),"tttttttttttttttttttttttttttttt",existingFiles)
        const flattenedExistingFiles = existingFiles.map((file) => ({
          file_name: file
        }));
        
        console.log(flattenedExistingFiles);
        console.log('flattend existign files',flattenedExistingFiles)
        const flattenedFiles = fileBinding.map(file => ({ ...file }));
        console.log('flattened files',flattenedFiles)

        const filesAreEqual = flattenedExistingFiles.length === flattenedFiles.length &&
          flattenedExistingFiles.every(file => 
            flattenedFiles.some(flattenedFile => flattenedFile.file_name === file.file_name)
          );

          // clear the compliance if new file added to it 
          if (type == 'create') {
            clearConsideration();
            
            setTimeout(() => {
              // Your code here that needs to execute after 5 seconds
              console.log("5 seconds have passed");
              // You can call another function or execute any other code here
            }, 5000); // 5000 milliseconds = 5 seconds
          }

        console.log('files   are equalll------------', filesAreEqual)
        if (filesAreEqual) {
          console.log('No changes in files, navigating to considerations');
          navigate('/Considerations', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "rfp_access": rfp, "rfi_access": rfi, "mail_Id": mail_Id, "opportunityName": opportunity_name, "responseType": type, "resType": responseType, "user_role": user_role, "userID": userID, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role, "type": type } }); return;
        }

        // Find deleted files
        
        console.log('existing files', existingFiles)
        console.log('file binding', fileBinding)
        let findOldFiles = fileBinding.filter(item => item.type == "old").map(item => item.document_id)
        console.log('-------find old files', findOldFiles)
        var deletedFiles = filesExisting.filter(existingFile =>
          !findOldFiles.includes(existingFile)
        );

        console.log('deleted filesss', deletedFiles)

        let findNewFIles = fileBinding.filter(item => item.type == "new")
        // Find newly inserted files
        console.log('new filessss', findNewFIles)

        // Delete files if any
        if (deletedFiles.length > 0) {
          console.log('files to delete', deletedFiles);
          await removeFiles(deletedFiles); // API call to delete files
        }

        // Insert new files if any
        if (findNewFIles.length > 0) {
          console.log('files to insert', findNewFIles);


          // Check if new files are not duplicates of existing files
          const uniqueNewFiles = findNewFIles.filter(file =>
            !flattenedExistingFiles.includes(file.file_name)
          );

          console.log('uniqueNewFilessssss', uniqueNewFiles)

          console.log('unique new files', uniqueNewFiles);
          await insertNewFiles(uniqueNewFiles); // API call to insert new files
        }

      } else {
        console.log('Proceeding to handleSubmit');
        await handleSubmit(); // API call to handle submit
      }
    } catch (error) {
      insertErrorApi({
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleNextClick",
        user_id: user_id
      });
    }
  };

  console.log('type',type)
  //  This function is used to remove the deleted files from the upload screen..PS_98 to 103
  const removeFiles = async (filesToDelete) => {
    // Trigger backend call to remove files and associated data
    try {
      if (!validateFiles()) {
        return;
      }
      setType('create')
      setLoader(() => true)
      console.log('files to dleete', filesToDelete)
      
      // Example call to remove files (replace with your actual API call)
      const response = await postDetailsApi({ "file_name": filesToDelete, "opportunity_id": opportunity_id, "user_id": user_id, "response_type": response_type,"tab": "uploadDocuments", "type": "delete" }, jwtToken);
      console.log('response', response)
      setLoader(() => false)
      if (response.success === true) {
        navigate('/Considerations', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunityName": opportunity_name, "responseType": type, "resType": responseType,"mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role, "type": type } })
      } else if (response.status === 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
          openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
          openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
          openToast("Internal server error", "images/cross - red.svg");
      }

    } catch (error) {
      insertErrorApi({
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "removeFiles",
        user_id: user_id
      });
    }
  };


  // THis function is used to add the newly uplaoded documents when it is edit flow..hits the backend and adds the documents in blob url in the database..PS_104 to 112
  const insertNewFiles = async (insertFiles) => {
    // Trigger backend call to remove files and associated data
    try {
      if (!validateFiles()) {
        return;
      }
      setType('create')
      setLoader(() => true)
      console.log('insert files', insertFiles)
    
      const uploadUrls = await Promise.all(
        insertFiles.map(async (file) => {
          // Determine the new file name based on the response type and original file name
          let newFileName = file.file_name;
      
          if (responseType === 'rfp_document' && file.file_name.includes('RFP_document')) {
            newFileName = 'RFP_document.pdf';
          } else if (responseType === 'rfi_document' && file.file_name.includes('RFI_document')) {
            newFileName = 'RFI_document.pdf';
          }
      
          // Upload the file to the blob and get the blob URL
          const blobUrl = await uploadFileToBlob(file, opportunity_id, responseType);
      
          console.log('blobUrl', blobUrl);
          return { "blobUrl": blobUrl, "fileName": newFileName };
        })
      );
      
      console.log('upload url', uploadUrls)
      // Example call to remove files (replace with your actual API call)
      const response = await postDetailsApi({ "tab": "uploadDocuments", "type": "createDelete", "opportunity_id": opportunity_id, "blob_data": uploadUrls, "response_type": response_type,"user_id": user_id }, jwtToken);
      setLoader(() => false)
      console.log('response', response)
      if (response.success === true) {
        navigate('/Considerations', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunityName": opportunity_name, "responseType": type, "resType": responseType,"mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role, "type": type } })
      } else if (response.status === 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
          openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
          openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
          openToast("Internal server error", "images/cross - red.svg");
      }

    } catch (error) {
      insertErrorApi({
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "insertNewFiles",
        user_id: user_id
      });
    }
  };

  // Replace with your actual SAS URL
  const AZURE_STORAGE_SAS_URL = "https://cineusbadsamisgdev.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2025-06-05T22:12:28Z&st=2024-06-05T14:12:28Z&spr=https,http&sig=SrCckL%2B9YOvqqBFvwcDcqfRObC0UN7cCxdTk%2F5W1vBM%3D";


  //  The function initializes the BlobServiceClient using the AZURE_STORAGE_SAS_URL.It gets a reference to the container client using the containerNa. The blob name is constructed using the opportunityId, responseType, and the file name. The category is determined based on the responseType.It gets a block blob client for the constructed blob name.The file is uploaded using the uploadBrowserData method.The function returns the URL of the uploaded blob without the SAS token.If an error occurs, it logs the error using the insertErrorApi function...PS_76 to 83
  const uploadFileToBlob = async (file, opportunityId, responseType) => {
    try {
      console.log('file', file);
      // Ensure the SAS URL is correctly formatted and complete
      const blobServiceClient = new BlobServiceClient(AZURE_STORAGE_SAS_URL);

      // Extract container name from SAS URL or use a hardcoded name if not included in SAS URL
      const containerName = "badsami";

      // Get a reference to the container client
      const containerClient = blobServiceClient.getContainerClient(containerName);

      let type;
      if(responseType=='rfp_document'){
        type='RFP_document.pdf'
      }else if(responseType=='rfi_document'){
        type='RFI_document.pdf'
      }
      
      // Construct the blob name using opportunityId, responseType, and file name
      const category = responseType === 'rfp_document' ? 'RFP_Document' : 'RFI_Document';
      const fileName = file.file_name.includes('RFP_document') ? 'RFP_document.pdf' : 'RFI_document.pdf';
      const blobName = `Documents/${opportunityId}/${category}/${fileName}`;
      console.log('blob name', blobName);

      // Get a block blob client for the constructed blob name
      const blobClient = containerClient.getBlockBlobClient(blobName);
      console.log('blob client', blobClient.url);

      // Set the content type to application/pdf and remove content disposition
      const options = {
        blobHTTPHeaders: {
          blobContentType: "application/pdf",
          blobContentDisposition: "inline"
        }
      };

      // Upload the file to the blob with the content type options
      const uploadBlobResponse = await blobClient.uploadData(file.file, options);
      console.log('uploadBlob response', uploadBlobResponse);

      // Return the URL of the uploaded blob with the SAS token for preview
      const previewUrl = blobClient.url; // This includes the SAS token
      console.log('previewUrl', previewUrl);
      const urlWithoutToken=previewUrl.split('?')[0];
      console.log('urlWithoutToken',urlWithoutToken)
      return urlWithoutToken;
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "uploadFileToBlob",
        user_id: user_id
      };
      insertErrorApi(payload);
    }
  };

  // The function starts by validating the files with validateFiles(). If the validation fails, it exits the function early.It filters out files that already exist in existingFile.For each new file, it uploads the file to Azure Blob Storage using uploadFileToBlob and collects the URLs of the uploaded blobs.It calls documentsUpload with the necessary parameters including the URLs of the uploaded files.Based on the API response, it navigates to a different route or shows a toast notification with the corresponding message.If any error occurs during the process, it logs the error to an API and prints the error to the console...PS_88 to 96

  const handleSubmit = async () => {
    try {
      if (!validateFiles()) {
        return;
      }

      setType('create')
      setLoader(() => true)
      const newFiles = files.filter(file => !existingFiles.includes(file.name));
      console.log('New files to upload:', newFiles);

      const uploadUrls = await Promise.all(
        newFiles.map(async (file) => {
          // Determine the new file name based on the response type and original file name
          let newFileName = file.file_name;
      
          if (responseType === 'rfp_document' && file.file_name.includes('RFP_document')) {
            newFileName = 'RFP_document.pdf';
          } else if (responseType === 'rfi_document' && file.file_name.includes('RFI_document')) {
            newFileName = 'RFI_document.pdf';
          }
      
          // Upload the file to the blob and get the blob URL
          const blobUrl = await uploadFileToBlob(file, opportunity_id, responseType);
      
          console.log('blobUrl', blobUrl);
          return { "blobUrl": blobUrl, "fileName": newFileName };
        })
      );
      
      console.log('upload url', uploadUrls)
      const response = await postDetailsApi({ "tab": "uploadDocuments", "type": "create", "opportunity_id": opportunity_id, "response_type": response_type,"blob_data": uploadUrls, "user_id": user_id }, jwtToken);

      setLoader(() => false)
      if (response.success === true) {
        navigate('/Considerations', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunityName": opportunity_name, "responseType": type, "resType": responseType, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role, "type": type} })
      } else if (response.status === 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
          openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
          openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
          openToast("Internal server error", "images/cross - red.svg");
      }
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleSubmit",
        user_id: user_id
      };
      insertErrorApi(payload);
      console.log(error);
    }
  };

  console.log('type of the responsee',type)
  console.log('types of the responsee',types)

  // The handleBackClick function navigates the user back to the opportunity grid.PS_124 to 126
  const handleBackClick = () => {
    console.log('entered back click icon')
    if(type === 'edit'){
      navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunity_name, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });
    }else if ((type === 'create' || type === undefined) && files.length === 0) {
      navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunity_name, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });
    }else if(type === 'create' && files.length !== 0){
      handleCancel()
    }else{
      navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunity_name, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });
    }
  };

  // This function is used to delete the documents uploaded when clicking on cancel button or back icon
  const handleCancel=async()=>{
    try{
      console.log('came to cancel and navigate to opportunities')
      setLoader(() => true)
      const response = await postDetailsApi({ "tab": "uploadDocuments", "type": "cancel", "response_type": response_type,"opportunity_id": opportunity_id, "user_id": user_id }, jwtToken);
      setLoader(() => false)
      if (response.success === true) {
        navigate('/opportunities', { state: { "jwtToken": jwtToken, "user_id": user_id, "opportunity_id": opportunity_id, "opportunity_name": opportunity_name, "mail_Id": mail_Id, "rfp_access": rfp, "rfi_access": rfi, "user_role": user_role } });
      } else if (response.status === 400) {
        openToast("something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
          openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
          openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
          openToast("Internal server error", "images/cross - red.svg");
      }
    }catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "UploadDocument.jsx",
        function_name: "handleCancel",
        user_id: user_id
      };
      insertErrorApi(payload);
      console.log(error);
    }
  }


  console.log("exist", existingFiles.length);

  // The openToast function shows a toast notification with a message and image for 5 seconds.PS_127 to 129
  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }

  // Function to show error message
  function showError(message) {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  }


  console.log('opppppp issssssssdddd', opportunity_id)

  return (
    <>
      <div>
        {<Header user_id={user_id} jwtToken={jwtToken} moduleName={1} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
      </div>
      {loader ? (
                <Loader />

            ) : (
        <div className="container-fluid">
          <div className="row">

          {isToastOpen && (<div className="container-fluid">
              <div className="d-flex justify-content-right align-items-end flex-column">
                <div className="px-2 mx-3 my-2 toast-container p-2">
                  <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                    <span>
                      <img
                        src={toastImage}
                        alt="tick-green-icon"
                        className="me-2 toast-img cursor-pointer"
                      />
                    </span>{" "}
                    {toastMessage}
                  </p>
                </div>
              </div>
            </div>)}

            <div className="col-md-12 p-5">
              {/* breadcrumb starts here */}
              <ul className="breadcrumb-item mb-4 ps-0">
                <li>
                  <a className="pe-0 font-13 primary-color font-medium">
                    Opportunities
                  </a>
                </li>
                <li className="pe-2 font-13 font-medium">
                  {opportunity_name}
                </li>
              </ul>
              {/* breadcrumb ends here */}
              <div className="p-4 bg-white border-size-5">
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="d-flex gap-2 mb-5 align-items-center">
                      <a className="cursor-pointer lh-0 pb-1" onClick={() => handleBackClick()}>
                        <img src="images/back-icon.svg" alt="Back Icon" onClick={() => handleBackClick()} />
                      </a>
                      <h5 className="font-semibold font-22 m-0">{responsiveType}</h5>
                    </div>
                  </div>
                  {/* stepper starts here */}
                  <div className="col-md-6 mb-5">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <div className="d-flex align-items-center flex-column gap-3">
                        <div className="step-num d-flex justify-content-center align-items-center active">
                          <p className="font-medium font-18 m-0">1</p>
                          <img
                            src="images/tick-white.svg"
                            alt="completed icon"
                            className="tick-img"
                          />
                        </div>
                        <span className="font-16 font-semibold step-text active">
                          {" "}
                          Step 1{" "}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column gap-3 position-relative">
                        <div className="step-num d-flex justify-content-center align-items-center font-medium font-18 step-line">
                          <p className="font-medium font-18 m-0">2</p>
                          <img
                            src="images/tick-white.svg"
                            alt="completed icon"
                            className="tick-img"
                          />
                        </div>
                        <span className="font-16 font-semibold step-text">
                          {" "}
                          Step 2{" "}
                        </span>
                      </div>
                      <div className="d-flex align-items-center flex-column gap-3 position-relative">
                        <div className="step-num d-flex justify-content-center align-items-center font-medium font-18 step-line">
                          <p className="font-medium font-18 m-0">3</p>
                          <img
                            src="images/tick-white.svg"
                            alt="completed icon"
                            className="tick-img"
                          />
                        </div>
                        <span className="font-16 font-semibold step-text">
                          {" "}
                          Step 3{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* stepper ends here */}
                  {/* upload box starts here */}
                  <div className="col-md-12">
                    <h5 className="font-22 font-medium primary-color">Upload</h5>
                    <div className="info-box p-2">
                      <p className="m-0 font-14 font-regular primary-color">
                        <img
                          src="images/tooltip-icon.svg"
                          alt="tooltip-icon"
                          className="mx-2"
                        />
                          Please upload up to three files (max 5MB each). Please ensure
                          that the document is named "{responseType == 'rfp_document'  ? 'RFP_document' : 'RFI_document'}". The files you upload
                          must include only one {responseType == 'rfp_document' ? 'RFP document' : 'RFI document'}.
                      </p>
                    </div>
                    <div className="mt-3">
                      <span className="upload-btn-wrapper d-block">
                        <button
                          type="button"
                          className="upload-btn cursor-pointer py-5 font-14 border-size-5"
                        >
                          <img
                            src="images/upload-large.svg"
                            alt="Upload"
                            className="mb-2"
                          />
                          <p className="font-regular font-14 m-0">
                            Drag and drop PDF files here or{" "}
                            <a
                              className={`text-decoration-none ${fileCount >= 3 ? 'disabled' : ''}`}
                              onClick={handleBrowseClick} >
                              browse
                            </a>
                          </p>
                        </button>
                        <input
                          type="file"
                          name="myfile"
                          className="cursor-pointer custom-file"
                          id="Upload"
                          accept=".pdf" // Accept PDF files only
                          ref={fileInputRef} // Assign reference to the input
                          onChange={handleFileChange}
                          multiple
                          disabled={fileBinding.length < 3 ? false : true}
                        />
                      </span>
                      {fileBinding.length > 0 ?
                        <div className="d-flex gap-2">
                          {fileBinding.map((file, index) => (
                            <div key={index} className="uploaded-content my-3 px-2 py-1 border-size-5">
                              <span className="font-medium font-14">
                                {file.file_name}
                                <img
                                  src="images/close-icon.svg"
                                  alt="close-icon"
                                  className="cursor-pointer ms-3"
                                  onClick={() => removeFileBinding(index)}
                                />
                              </span>
                            </div>
                          ))}
                        </div>
                        : <></>}
                        {errorMessage && (
                          <p className="font-14 font-regular warning">
                            {errorMessage}
                          </p>
                        )}
                      <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                        <button
                          type="button"
                          className="btn cancel-btn font-13 color-black font-medium"
                          data-bs-dismiss="modal"
                          onClick={() => handleBackClick()}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn submit-btn font-13 color-white font-medium"
                          data-bs-dismiss="modal"
                          onClick={handleNextClick}
                          disabled={!submitEnabled}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* upload box ends here */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UploadDocument;