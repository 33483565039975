//  import all the necessary packages..PS_01 to 06
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertErrorApi, resetPasswordApi } from '../services/api'; // Assuming these functions are defined in your API service
import { decode } from 'base-64';

// import all the state variables..PS_07 to 24
const ResetPassword = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetPasswordEnabled, setResetPasswordEnabled] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/; // Adjust the regex as needed

  const getDecodedItem = (key) => {
    const encodedValue = localStorage.getItem(key);
    if (!encodedValue) return null;
    return decode(encodedValue.substring(15, encodedValue.length - 10));
  };
  const [user_status, setUserStatus] = useState('')

  const user_id = getDecodedItem('lY2tMb2dpbg23')
  const jwtToken = getDecodedItem('Y2hlY2tMb2dpb')
  const mail_id = getDecodedItem('2tMb2dpbg23Ly')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search) // id=123
    let id = params.get('status')
    console.log(id);
    if (id == null) {
      navigate('/')
      return;
    }
    setUserStatus(decode(id))
    initializeTooltips()
  }, [])

  useEffect(() => {
    if (errorMessage) {
      initializeTooltips();
    }
  }, [errorMessage]);

  console.log('user_id', user_id, 'jwttoken', jwtToken, 'mail id', mail_id, 'user_status', user_status)

  const initializeTooltips = () => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    return tooltipTriggerList.map((tooltipTriggerEl) => {
      return new (window).bootstrap.Tooltip(tooltipTriggerEl);
    });
  };
  // Extracts the new password from the event object (e.target.value.trim()) and trims any leading or trailing whitespace.Checks if both the new password and the confirm password are not empty. If they are not empty, it sets the resetPasswordEnabled state to true, enabling the reset password button. Otherwise, it sets the state to false....PS_28,29
  const handleNewPasswordChange = (e) => {
    try {
      const newPassword = e.target.value.trim();
      if (newPassword !== "" && confirmPassword !== "") {
        setResetPasswordEnabled(true); // Enable login if both new password is not empty 
      } else {
        setResetPasswordEnabled(false);
      }
      setNewPassword(newPassword);
      setErrorMessage('')
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "ResetPassword.jsx",
        function_name: "handleNewPasswordChange"
      };
      insertErrorApi(payload);
    }
  };

  // Extracts the confirm password from the event object (e.target.value.trim()) and trims any leading or trailing whitespace.Checks if both the confirm password and the new password are not empty. If they are not empty, it sets the resetPasswordEnabled state to true, enabling the reset password button. Otherwise, it sets the state to false....PS_37,38
  const handleConfirmPasswordChange = (e) => {
    try {
      const confirmPassword = e.target.value.trim();
      if (confirmPassword !== "" && newPassword !== "") {
        setResetPasswordEnabled(true); // Enable login if both new password is not empty 
      } else {
        setResetPasswordEnabled(false);
      }
      setConfirmPassword(confirmPassword);
      setErrorMessage('')
    } catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "ResetPassword.jsx",
        function_name: "handleConfirmPasswordChange",
        user_id: user_id
      };
      insertErrorApi(payload);
    }
  };

  // PS_46 to 64
  const resetPassword = async () => {
    // Checks if the new password matches the confirm password. If they do not match, it sets an error message stating that both passwords should be the same.
    if (newPassword !== confirmPassword) {
      showError("The passwords you entered do not match. Please make sure both passwords are the same.", "font-14 font-regular warning text-center mt-2")
      return;
    }

    //  Ensures that both the new password and confirm password meet the password strength requirements specified by passwordRegex. If they do not meet the requirements, it sets an error message indicating the requirements.
    if (!passwordRegex.test(newPassword)) {
      showError("Your password must contain a mix of uppercase and lowercase letters, numbers, and special characters. Please try again with a stronger password", "font-14 font-regular warning text-center mt-2")
      return;
    }

    if (!passwordRegex.test(confirmPassword)) {
      showError("Your password must contain a mix of uppercase and lowercase letters, numbers, and special characters. Please try again with a stronger password", "font-14 font-regular warning text-center mt-2")
      return;
    }

    try {
      // If the passwords match and meet the strength requirements, it makes an API call to reset the password. The API call includes the user ID, new password, confirm password, mail ID, and type. and checks if the new password matches the current password
      let response;
      setLoader(() => true)
      if (user_status && user_status.toLowerCase() == 'invited') {
        response = await resetPasswordApi({ "user_id": user_id, "new_password": newPassword, "confirm_password": confirmPassword, "mail_id": mail_id, "user_status": user_status, "type": "R" }, jwtToken);
      } else {
        response = await resetPasswordApi({ "user_id": user_id, "new_password": newPassword, "confirm_password": confirmPassword, "mail_id": mail_id, "type": "R" }, jwtToken);
      }
      setLoader(() => false)
      console.log('responssssse', response)
      console.log('saiii', response.data)

      //  If the password reset is successful (response.success === 200), it sets a state (isResetSuccessful) to true and triggers a success popup. If the new password is the same as the previous password (response.Message === "Cannot enter the same password to reset"), it sets an error message. If the token is expired (response.status === 403) or there's a generic error (response.status === 401), it shows an appropriate error message. If none of these conditions are met, it disables the reset password button.
      if (response.success === true) {
        setIsResetSuccessful(true);
        document.getElementById('success').click()
        // Show success popup
      } else if (response.message == "Cannot enter the same password to reset") {
        setErrorMessage("Your new password cannot be the same as your previous password. Please choose a different password.", "font-14 font-regular warning text-center mt-2");
      } else if (response.success == false) {
        openToast("Something went wrong", "images/cross - red.svg");
      } else if (response.message == 'Token Expired') {
        openToast("Token Expired", "images/cross - red.svg");
      } else if (response.message == 'User_Id mismatch between JWT token and request body') {
        openToast("Unauthorized Access", "images/cross - red.svg");
      } else if (response.message == 'Token Invalid') {
        openToast("Invalid token", "images/cross - red.svg");
      } else if (response.status == 500) {
        openToast("Internal server error", "images/cross - red.svg");
      } else {
        setResetPasswordEnabled(false);
      }
    } catch (error) {
      console.log(error)
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "ResetPassword.jsx",
        function_name: "resetPassword",
        user_id: user_id
      };
      insertErrorApi(payload);
      openToast("An error occurred during login. Please try again later.", "images/cross - red.svg");
      setLoader(false);
    }
  };

  // PS_66....Create function handleOkButtonClick(), navigate to login page if reset successful
  const handleOkButtonClick = () => {
    if (isResetSuccessful) {
      navigate('/');
    }
  };

  // The toggleNewPasswordVisibility and toggleConfirmPasswordVisibility functions are used to toggle the visibility of the new password and confirm password fields, respectively. When one field is shown, the other is hidden to prevent both from being visible simultaneously....PS_67,68
  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
    setConfirmPasswordVisible(false); // Hide confirm password if new password is shown
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
    setNewPasswordVisible(false); // Hide new password if confirm password is shown
  };


  // The openToast function is a utility function to display toast messages. It sets the message and image for the toast, then sets a timeout to hide the toast after 5 seconds. This is a nice way to provide feedback to the user for actions such as password reset success or failure...69
  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }

  // Function to show error message
  function showError(message) {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  }

  return (
    <>
      {loader ? (
        <div className="container-fluid p-0">
          <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
            {isToastOpen && (<div className="container-fluid">
              <div className="d-flex justify-content-right align-items-end flex-column">
                <div className="px-2 mx-3 my-2 toast-container p-2">
                  <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                    <span>
                      <img
                        src={toastImage}
                        alt="tick-green-icon"
                        className="me-2 toast-img cursor-pointer"
                      />
                    </span>{" "}
                    {toastMessage}
                  </p>
                </div>
              </div>
            </div>)}
            <div className="wrapper text-center">
              <div className="circle" />
              <div className="circle" />
              <div className="circle" />
              <div className="shadow" />
              <div className="shadow" />
              <div className="shadow" />
            </div>
            <p className="font-24 font-regular color-white mt-3">Loading</p>
          </div>
        </div>) : (
        <div className="container-fluid">
          <div className="row">
            {/*Left container starts here */}
            <div className="col-md-6">
              <div className="login-content-height d-flex justify-content-center align-items-center flex-column">
                <div className="text-center">
                  <img
                    src="images/logo-img.svg"
                    alt="logo-img"
                    className="mb-5 pb-4"
                  />
                </div>
                <div className="text-start w-50">
                  <div className="mb-5">
                    <h5 className="font-bold font-26 color-black">Reset Password 
                    </h5>
                      <p className="color-grey font-16 font-regular">
                        Please enter your new password here.
                      </p>
                    </div>
                      <div className="mb-4 position-relative">
                        <label
                          className="form-label font-16 font-semibold primary-color"
                          htmlFor="NewPassword"
                        >
                          New Password
                          <span class="ms-1 cursor-pointer"><img src="images/tooltip-icon.svg" alt="tooltip-icon" class="me-1" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Password must contain 8 characters.This should include a mix of uppercase and lowercase letters, numbers, and special characters." aria-label="Password must contain 8 characters"/></span>
                        </label>
                        <input
                          type={newPasswordVisible ? "text" : "password"}
                          className="form-control custom-form font-14 font-regular my-1 password-eye-hide pe-5"
                          id="NewPassword"
                          placeholder="Enter New Password"
                          onChange={handleNewPasswordChange}
                        />
                        <img
                          src={newPasswordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"}
                          alt={newPasswordVisible ? "Hide password" : "Show password"}
                          className="eye-pos cursor-pointer"
                          onClick={toggleNewPasswordVisibility}
                        />
                      </div>
                      <div className="mb-4 position-relative">
                        <label
                          className="form-label font-16 font-semibold primary-color"
                          htmlFor="ConfirmPassword"
                        >
                          Confirm Password
                        </label>
                        <input
                          type={confirmPasswordVisible ? "text" : "password"}
                          className="form-control custom-form font-14 font-regular my-1 password-eye-hide pe-5"
                          id="ConfirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleConfirmPasswordChange}
                        />
                        <img
                          src={confirmPasswordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"}
                          alt={confirmPasswordVisible ? "Hide password" : "Show password"}
                          className="eye-pos cursor-pointer"
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      </div>
                      <div className="mb-3">
                        <button
                          type={confirmPasswordVisible ? "text" : "password"}
                          className="btn px-4 submit-btn font-16 color-white font-medium w-100 mb-3"
                          onClick={resetPassword}
                          disabled={!resetPasswordEnabled}
                        >
                          Reset Password
                        </button>
                        <button
                          type="button"
                          className="btn px-4 outline-btn font-16 primary-color font-medium w-100 mb-3"
                          onClick={() => navigate('/')}
                        >
                          Back to Sign In
                        </button>
                        {errorMessage && (
                          <p className="font-14 font-regular warning">
                            {errorMessage}
                          </p>
                        )}
                      </div>
                  </div>
                </div>
              </div>
              {/*Right container starts here */}
              <div className="col-md-6 p-0">
                <div className="login-bg-height">
                  <div className="login-bg-img" />
                </div>
              </div>
              {/*Right container ends here */}
            </div>
            {/*Left container ends here */}
          </div>
      )}
          <div class="col-md-12 my-2">
            <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop10" id="success" hidden>
              Password Change
            </button>


          </div>
          <div class="modal fade" id="staticBackdrop10" data-bs-backdrop="static" data-bs-keyboard="false"
            tabIndex="-1" aria-labelledby="staticBackdropLabel10" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content border-size-5">
                <div class="modal-header border-0 justify-content-center pt-4 pb-1">
                  <img src="images/tick-green.svg" alt="error-red" />
                </div>
                <div class="modal-body pb-4">
                  <p class="text-center font-regular font-16 m-0 color-black-v2">Your password has been changed successfully.</p>
                </div>
                <div class="modal-footer justify-content-center border-0 gap-2 pb-4">
                  <button type="button" class="btn submit-btn font-13 color-white font-medium" onClick={() => handleOkButtonClick()}
                    data-bs-dismiss="modal">Ok</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      }

      export default ResetPassword;