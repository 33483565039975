import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const TimeoutModal = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const isLoginPage = location.pathname === '/';

  useEffect(() => {
    // Only set the timeout if the user is not on the login page
    if (!isLoginPage) {
      // Set a timeout to show the modal after 1 minute (60000ms)
      const timer = setTimeout(() => {
        setShowModal(true);
        // Show the modal using Bootstrap's JavaScript API
        const myModal = new window.bootstrap.Modal(document.getElementById('staticBackdrop9'));
        myModal.show();
      }, 18000000); // 60000ms = 1 minute for testing purposes 18000000

      // Cleanup the timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [isLoginPage]);

  const handleOkClick = () => {
    setShowModal(false);
    // Redirect to the login page
    window.location.href = '/';
    localStorage.clear()
  };

  return (
    <div>
      {/* Your application content */}
      <div className="modal fade" id="staticBackdrop9" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel9" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-size-5 custom-modal-width">
            <div className="modal-header border-0 justify-content-center pt-4 pb-1">
              <img src="images/error-red.svg" alt="error-red" />
            </div>
            <div className="modal-body">
              <p className="text-center font-regular font-16 m-0 color-black-v2">
                Your current session has timed out. Please login again to continue.
              </p>
            </div>
            <div className="modal-footer justify-content-center border-0 gap-2 pb-4">
              <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal" onClick={handleOkClick}>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeoutModal;
