import React from "react";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { BlobServiceClient } from '@azure/storage-blob';
import { insertErrorApi, fetchDetailsApi, postDetailsApi, writtingscoring } from '../services/api';
import moment from 'moment';
import "moment-timezone";
import Header from "./header/Header";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import Loader from './loader/Loader'
/*FROM  PS_G_32 TO PS_G_269 This binds the grid the with oppurtunities in it and providing the user to perform several functionalities on the grid it allows the user to generate RFP/RFI based on their ability*/

const Opportunities = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('locationzz', location.state)
    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
    }, [location, navigate]);
    let startIndex = 0
    let endIndex = 0
    let currentPageData = 1
    let start, end
    let user_id = location.state?.user_id
    let jwtToken = location.state?.jwtToken
    let mail_Id = location.state?.mail_Id
    let user_role = location.state?.user_role
    let rfp = location.state?.rfp_access
    let rfi = location.state?.rfi_access
    console.log(user_id, jwtToken);
    const [loader, setLoader] = useState(false)
    const [opportunities, setOpportunities] = useState([]);
    const [files, setFiles] = useState([]);
    const [website, setWebsite] = useState([]);
    const [staticOpportunities, setStaticOpportunities] = useState([])
    const [filteredOpportunities, setFilteredOpportunities] = useState([]);
    const [opportunityName, setOpportunityName] = useState('');
    const [sourceURL, setSourceURL] = useState('');
    const [metadataScoring, setMetadataScoring] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [dueTime, setDueTime] = useState('');
    const [timezone, setTimezone] = useState('');
    const [fileName, setFileName] = useState('');
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState("");
    const [sortArrow, setSortArrow] = useState('downarrow')
    const [sortorder, setSortOrder] = useState("ASC");
    const [selected, setSelected] = useState(1);
    const [sortcolumn, setSortColumn] = useState("source_date");
    const [show, setshow] = useState("empty")
    const [source, setSource] = useState([]);
    const [status, setStatus] = useState([]);
    const [metaScore, setMetaScore] = useState([]);
    const [writtingScore, setWrittingScore] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10); // assuming use `useState` if it needs to change
    const [totalRecords, setTotalRecords] = useState(0);
    const [pages, setPages] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [WriteID, setWriteID] = useState('');
    const [WritFile, setWrittingFile] = useState('');
    const [selectedFile, setSelectedFile] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [openId, setOpenId] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastImage, setToastImage] = useState("");
    const [activityChanged, setActivityChanged] = useState(true)
    const [allowDate, setallowDate] = useState(false)
    const [toggleMenu, setToggleMenu] = useState(false)
    const [PageCount, setpageCount] = useState(0)
    const [writingsFile, setWritingFile] = useState("")
    const [score, setScore] = useState(null)
    let resType
    const AZURE_STORAGE_SAS_URL = "https://cineusbadsamisgdev.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2025-06-05T22:12:28Z&st=2024-06-05T14:12:28Z&spr=https,http&sig=SrCckL%2B9YOvqqBFvwcDcqfRObC0UN7cCxdTk%2F5W1vBM%3D";

    /* PS_G_32   The use Effect is triggred */
    useEffect(() => {
        getOpportunities().then(() => {
            setTimeout(initializeTooltips, 0);
          });
    }, []);

    useEffect(() => {
        initializeTooltips();
      }, [currentPage]);
    

    function convertDate(dateTime) {


        const dateStr = dateTime.substring(dateTime.indexOf(',') + 2);
        const parts = dateStr.split(' ');
        const monthMapping = {

            "Jan": "01", "Feb": "02", "Mar": "03", "Apr": "04",

            "May": "05", "Jun": "06", "Jul": "07", "Aug": "08",

            "Sep": "09", "Oct": "10", "Nov": "11", "Dec": "12"

        };

        const month = monthMapping[parts[1]];
        const convertedDateStr = `${month}/${parts[0]}/${parts[2]} ${parts[3]} EST`;
        return convertedDateStr

    }
    const initializeTooltips = () => {
        const tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        return tooltipTriggerList.map((tooltipTriggerEl) => {
          return new (window).bootstrap.Tooltip(tooltipTriggerEl);
        });
      };
    /*At getOpportunities the  fetchDetails is called and the files,website,opportinity are recieved from the backend and corresponding 
     state variables are set  */
    const getOpportunities = async () => {
        let pageNumbers
        try {

            const payload =
            {
                "tab": "oppurtunity",
                "value": "oppurtunityGrid",
                "user_id": user_id,
                "email_id": mail_Id
            };
            setLoader(true)
            const responseData = await fetchDetailsApi(payload, jwtToken);
            console.log("response from fetch Details", responseData)
            if (responseData.success == true) {
                console.log("Entered Success", responseData.data.oppurtunities.length)
                console.log("sdfghjkljhgfghj", pageSize)
                const newOpportunities = []
                if (user_role.toLowerCase() == "admin") {
                    const oppArray = responseData.data.oppurtunities;
                    for (let j = 0; j < oppArray.length; j++) {
                        if ((oppArray[j].source_date) != null) {
                            const sourceDate = oppArray[j].source_date
                            oppArray[j].source_date = convertDate(sourceDate)
                        }
                        if ((oppArray[j].converted_due_date) != null) {
                            const dueDateTime = oppArray[j].converted_due_date;
                            oppArray[j].converted_due_date = convertDate(dueDateTime)
                        }
                        newOpportunities.push(oppArray[j]);
                    }
                    console.log(newOpportunities);
                    setOpportunities(newOpportunities);
                    setStaticOpportunities(newOpportunities)
                    setWebsite(responseData.data.website);
                    setLoader(false)
                    setFiles(responseData.data.files);
                    setTotalRecords(newOpportunities.length);
                    console.log("pages:", pageNumbers)

                    setpageCount(Math.ceil(newOpportunities.length / 10))
                    setCurrentPage(1)
                    console.log("FILES FOR ADMIN:", files)
                }
                else {
                    console.log("ENTERED USER FLOW")
                    if (Object.keys(responseData).length > 0) {
                        console.log(responseData.data.oppurtunities)
                        let len = responseData.data.oppurtunities.reduce((acc, curr) => acc + curr.length, 0);
                        const newOpportunities = [];
                        const newFiles = [];
                        const totalPages = Math.ceil(len / pageSize);
                        pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
                        console.log(pageNumbers, "numbers")
                        console.log("opp for user:", responseData.data.oppurtunities)
                        console.log("files for user:", responseData.data.files)

                        for (let i = 0; i < responseData.data.oppurtunities.length; i++) {

                            const oppArray = responseData.data.oppurtunities[i];
                            console.log(responseData.data.oppurtunities[i], "OPPORTUNITY LOOP")
                            console.log(oppArray.source_date, "Source date")
                            console.log(oppArray.converted_due_date, "Converted due date")

                            if ((oppArray.source_date) != null) {
                                const sourceDate = oppArray.source_date
                                oppArray.source_date = convertDate(sourceDate)
                            }
                            if ((oppArray.converted_due_date) != null) {
                                const dueDateTime = oppArray.converted_due_date;
                                oppArray.converted_due_date = convertDate(dueDateTime)
                            }
                            newOpportunities.push(oppArray);

                        }
                        setOpportunities(newOpportunities);
                        setWebsite(responseData.data.website);
                        setLoader(false)
                        setFiles(responseData.data.files);

                        setStaticOpportunities(newOpportunities)
                        setFilteredOpportunities(newOpportunities)
                        setCurrentPage(1)

                        setpageCount(Math.ceil(newOpportunities.length / 10))
                        setTotalRecords(newOpportunities.length);
                        console.log("pages:", pageNumbers)

                    }
                }
            } else if (responseData.message == "Mail id not found") {
                openToast("Email Not Found", "images/cross - red.svg");

            } else if (responseData.message == 'Token Expired') {
                openToast("Token Expired", "images/cross - red.svg");
            } else if (responseData.message == 'User_Id mismatch between JWT token and request body') {
                openToast("Unauthorized Access", "images/cross - red.svg");
            } else if (responseData.message == 'Token Invalid') {
                openToast("Invalid token", "images/cross - red.svg");
            } else if (responseData.status == 500) {
                openToast("Internal server error", "images/cross - red.svg");
            }

            setPages(pageNumbers);
            console.log(totalRecords - 1)
           
        }
        catch (error) {
            console.log(error, "0000000000000000000000000000000000");
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "getOppurtunity",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    console.log(website, "---------------------------------==========");
    const nullifyNewOpportunityFields = () => {
        setOpportunityName('')
        setSourceURL('')
        setMetadataScoring('')
        setDueDate('')
        setDueTime('')
        setTimezone('')
        setSelectedFile('')
        setErrors({})
        setErrorMessage("")
    }
    /*PS_G_59 to  PS_G_63*/
    const handleAccordionClick = (id) => {
        setshow(show == id ? "empty" : id);
    };
    /*On click of the check box the ID are set to SelectedIds to perform export and sustain   */
    function transformOpportunities(opportunities) {
        const selectedIdsSet = new Set(selectedIds.map(item => item.id));
        return opportunities
            .filter(opportunity => selectedIdsSet.has(opportunity.oppurtunity_id))
            .map(opportunity => ({
                "Opportunity name": opportunity.oppurtunity_name,
                "Capture Type": null,
                "Contract Type": opportunity.contract_type,
                "Source sought (RFI) Release Date": opportunity.release_date,
                "Source sought (RFI) Due Date": opportunity.original_due_date,
                "Solicitation": opportunity.release_date,
                "NAICS CODE": opportunity.NAICS_code,
                "Place of performance": opportunity.place_of_performance,
                "Opportunity Link": opportunity.oppurtunity_link,
                "Buying Organisation": opportunity.buying_organization
            }));
    }
    const exportToExcel = async () => {
        console.log("entered>>>>>>>>");
        try {

            const transformedData = transformOpportunities(opportunities);

            const worksheet = XLSX.utils.json_to_sheet(transformedData);

            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Write the workbook to an Excel file
            XLSX.writeFile(workbook, 'opportunities.xlsx');

            // Inform the user that the file has been downloaded

        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "exportToExcel",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    }


    const handleCheckboxClick = (id, search_name) => {
        try {
            setSelectedIds((prevIds) => {
                console.log(prevIds, "previous==================================");
                console.log({ id, search_name }, "The id========================");
                const existingIndex = prevIds?.findIndex(item => item.id === id);
                console.log(existingIndex, "Existing id------------------");

                if (existingIndex !== -1) {
                    // If found, remove it using the filter method
                    return prevIds.filter(item => item.id !== id);
                } else {
                    // If not found, add the new object
                    return [...prevIds, { id: id, search_name: search_name }];
                }
            });
        }
        catch (error) {
            // Handle any exceptions here
            console.error(error);

            // Define your error payload if needed before using it
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Opportunity.jsx",
                function_name: "handleCheckboxClick",
                // user_id: user_id, // Uncomment and provide the user_id if available
            };

            // Make sure the insertErrorApi function is defined and available in this scope
            insertErrorApi(payload);
        }
    };
    /* PS_G_74  tO  PS_G_90*/
    console.log('selected id', selectedIds)
    const sustained = async () => {
        setLoader(true)
        console.log("entered sustain>>>>>>");
        console.log(selectedIds?.length > 0, "length===");

        try {
            console.log("entered try");

            if (selectedIds?.length > 0) {
                console.log("entered if");
                let payload = {
                    "tab": "opportunity",
                    "type": "sustain",
                    "details": selectedIds,
                    "user_id": user_id,
                    "user_role": user_role

                }
                console.log('payload', payload, jwtToken)

                const responseData = await postDetailsApi(payload, jwtToken);
                console.log(responseData, "Sucess data ")
                setLoader(false)
                if (responseData.success) {
                    getOpportunities()

                }
                else if (responseData.message == "Mail id not found") {
                    openToast("Email Not Found", "images/cross - red.svg");
                }
                else if (responseData.message == 'Token Expired') {
                    openToast("Token Expired", "images/cross - red.svg");
                } else if (responseData.message == 'User_Id mismatch between JWT token and request body') {
                    openToast("Unauthorized Access", "images/cross - red.svg");
                } else if (responseData.message == 'Token Invalid') {
                    openToast("Invalid token", "images/cross - red.svg");
                } else if (responseData.status == 500) {
                    openToast("Internal server error", "images/cross - red.svg");
                }



            }
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "sustained",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    }
    /* On click of the button all the opportunity is selected 
   PS_G_227 to 239*/

    const handleSelectAllClick = () => {
        try {
            // Toggle the state of isAllSelected
            setIsAllSelected((prevIsAllSelected) => {
                if (prevIsAllSelected) {
                    // If currently all are selected, set to empty array to deselect all
                    setSelectedIds([]);
                } else {
                    // If not all are selected, create an array of objects with opportunity_id and search_name
                    setSelectedIds(
                        opportunities.map((opp) => ({
                            "id": opp.oppurtunity_id, // Ensure this is the correct property name
                            "search_name": opp.search_name,
                        }))
                    ); console.log(selectedIds, "set sttsiiiiiiiiiiiiiiiiiiiiii")
                }
                // Return the opposite of the previous state
                return !prevIsAllSelected;
            });
        } catch (error) {
            // Log error to the console or send to an error reporting service
            console.error(error);

            // Sending error details to an API endpoint (make sure insertErrorApi function is defined and works correctly)
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Opportunity.jsx",
                function_name: "handleSelectAllClick",
                user_id: user_id, // Make sure user_id is defined and holds the correct value
            };
            insertErrorApi(payload);
        }
    }
    /* PS_G_93  tO  PS_G_106*/
    const sortData = (column, arrow) => {
        try {
            setSortArrow(() => arrow)
            console.log(arrow, "arrow")
            const sortedData = [...opportunities].sort((a, b) => {
                const aValue = a[column];
                const bValue = b[column];

                // Check if the value is a date string and convert it to a Date object
                const isDate = (dateStr) => !isNaN(Date.parse(dateStr));

                const aDate = isDate(aValue) ? new Date(aValue) : null;
                const bDate = isDate(bValue) ? new Date(bValue) : null;

                // Compare dates if they are valid
                if (aDate && bDate) {
                    return sortorder == 'asc' ? aDate - bDate : bDate - aDate;
                }

                // Fallback to default string comparison for non-date values
                if (aValue < bValue) {
                    return sortorder == 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortorder == 'asc' ? 1 : -1;
                }
                return 0;
            });

            setOpportunities(sortedData);
            setSortColumn(column);
            setSortOrder(sortorder == 'asc' ? 'desc' : 'asc');
        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "pagination",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    //Shows the 50 character in the string
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    };

    //To update the name of the website from the given link
    const getWebsiteName = (url) => {
        try {
            if (url.includes('govtribe.com')) {
                return 'Gov Tribe.com';
            } else if (url.includes('sam.gov')) {
                return 'Sam.Gov';
            } else if (url.includes('govwin.com')) {
                return 'Govwin.com';
            } else {
                return false; // Default case, if none of the above matches
            }
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "getWebsiteName",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }
    }

    const renderScoring = (scoring) => {
        try {
            if (!scoring) {
                return 0;
            } else {
                return scoring; // Display the actual value if it's not empty
            }
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "renderScoring",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }
    };
    /*Search functionality PS_G_107  tO  PS_G_115*/
    const handleSearch = (term) => {
        try {

            if (term.length == 0) {

                setOpportunities(staticOpportunities);

            } else {
                const searchData = staticOpportunities.filter((item) =>
                    Object.values(item).some(value =>
                        String(value).toLowerCase().includes(term.toLowerCase())
                    )
                );
                setOpportunities(searchData);
            }
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "pagination",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    console.log(files, "files-----------------------------------")
    console.log(opportunities, "opportunities------------------------------------")
    console.log("pages in", pages)

    /*New Opportunity submit functionality PS_G_116  tO  PS_G_157*/
    //To submit the values 
    const handleSubmit = async (opportunityName, sourceURL, metadataScoring, dueDate, dueTime, timezone, user_id) => {



        console.log("At function ")
        let validationErrors = {}

        if (!opportunityName) validationErrors.opportunityName = 'Enter the value';
        if (!metadataScoring) validationErrors.metadataScoring = 'Enter the value';
        if (!dueDate) validationErrors.dueDate = 'Enter a valid date';
        if (!dueTime) validationErrors.dueTime = 'Enter the value';
        if (!timezone) validationErrors.timezone = 'Enter the value';
        if (!selectedFile) validationErrors.file = 'Upload a file';

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            return;
        }
        setLoader(() => true)
        console.log("Creating pay load for function call")
        const opportunityData = {
            "user_id": user_id,
            "tab": "opportunity",
            "type": "new",
            "opportunity_name": opportunityName,
            "metadata_scoring": metadataScoring,
            "source_url": sourceURL,
            "due_date": dueDate,
            "due_time": dueTime,
            "time_zone": timezone,
            "blob_url": ''
        };
        console.log("The Payload", opportunityData)

        try {

            console.log("Entering true")


            const responseData = await postDetailsApi(opportunityData, jwtToken);

            console.log("try block", responseData)
            console.log("   THE VALUES OUT ", responseData)
            if (responseData.success) {
                console.log("   THE RESPONSE ID RETURNED", responseData.id)
                console.log(responseData.id, "id from new opp")

                let is_load = true
                let score = await handleWrittingSubmit(responseData.id, is_load)
                setLoader(() => false)

                setOpportunityName('');
                setSourceURL('');
                setMetadataScoring('');
                setDueDate(''); setDueTime(''); setTimezone(''); setFileName('');

                // Navigate to the opportunities page
                setSelectedFile(null)
                getOpportunities()
                setLoader(false)
            }
            else if (responseData.message == "Mail id not found") {
                openToast("Email Not Found", "images/cross - red.svg");
            }
            else if (responseData.message == 'Token Expired') {
                openToast("Token Expired", "images/cross - red.svg");
            } else if (responseData.message == 'User_Id mismatch between JWT token and request body') {
                openToast("Unauthorized Access", "images/cross - red.svg");
            } else if (responseData.message == 'Token Invalid') {
                openToast("Invalid token", "images/cross - red.svg");
            } else if (responseData.status == 500) {
                openToast("Internal server error", "images/cross - red.svg");
            }
        } catch (error) {

            // Handle error
            document.getElementById("CancelHandleSubmit").click()
            console.log('An error occurred');
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "handleSubmit",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }
        setErrors({})
    }
    const uploadFileToBlob = async (file, opportunityId) => {
        try {

            console.log('file', file)
            console.log('ID', opportunityId)

            const blobServiceClient = new BlobServiceClient(AZURE_STORAGE_SAS_URL);
            const containerName = "badsami";
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobName = `Documents/${opportunityId}/Writtings/${file.name}`;
            console.log('blob name', blobName)
            const blobClient = containerClient.getBlockBlobClient(blobName);
            console.log('blob client', blobClient.url);
            const options = {
                blobHTTPHeaders: {
                    blobContentType: "application/pdf",
                    blobContentDisposition: "inline"
                }
            };

            const uploadBlobResponse = await blobClient.uploadData(file, options);
            console.log('uploadBlob response', uploadBlobResponse);
            const blobUrlWithoutSas = blobClient.url.split('?')[0];
            console.log('blobUrlWithoutSas got:', blobUrlWithoutSas)
            return blobUrlWithoutSas;

        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "opportunity.jsx",
                function_name: "uploadblob",
                user_id: user_id
            };

        }
    }
    const getLink = async (selectedFile, opportunityName) => {
        try {
            const submit = await handleSubmit(opportunityName, sourceURL, metadataScoring, dueDate, dueTime, timezone, user_id)
            return submit;
        } catch (error) {
            console.error('Error getting blob link', error);
            // Handle error as needed
        }

    };
    /*Upload Writting submit functionality PS_G_158  tO  PS_G_176*/
    const handleFileSelect = (event) => {
        try {
            event.target.files.length > 0 ? setSelectedFile(() => files.length) : setSelectedFile(() => null)
            const files = event.target.files;
            console.log('THE ARRAY VALUE----------2', files);
            console.log('THE ARARAY 1ST ONE--------2', files[0]);

            // Check if the input element has files
            if (files && files.length == 1) {
                const file = files[0];
                console.log('file', file);

                if (file.type == 'application/pdf') {
                    if (file.size <= 5 * 1024 * 1024) {
                        // 5MB size limit
                        setSelectedFile(file);
                        console.log("File done")
                        setWritingFile("")
                        setErrorMessage('')
                    } else {
                        setErrorMessage('File size must be less than 5MB');
                        setSelectedFile(null);
                    }
                } else {
                    setErrorMessage('Only PDF files are allowed');
                    setSelectedFile(null);
                }
            } else {
                console.error('No file selected or file input not found');
            }
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "handleFileSelect",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    /*RFP (or) RFI Navigation  PS_G_176  tO  PS_G_191 */
    const uploadCheck = (oppID, restype) => {
        return files?.map((file) => {
            console.log(resType)
            if (oppID == file.oppurtunity_id) {
                console.log(oppID, "------------", file.oppurtunity_id)
                console.log(restype, "------------", file.response_type)
                if (restype == file.response_type) {
                    console.log(restype, "------------", file.response_type)
                    console.log(file.user_id,"342342234323")
                    return file.user_id
                }
                console.log("end")
            }

            else {
                console.log("else")
                return null
            }


        })


    }

    const navigateToRFPproposal = (opportunity_id, opportunityName, responseType, userID) => {
        console.log(       
            "The values passed :", opportunity_id,
            "user_id:", user_id,
            "JWT:", jwtToken,
            "Opportunity:", opportunityName,
            "response:", responseType,
            "user_create:", userID
        );
        //To navigate through RFI or RFP button
        navigate('/UploadDocument', { state: { "opportunity_id": opportunity_id, "user_id": user_id, "responseType": responseType, "userID": userID, "jwtToken": jwtToken, "opportunity_name": opportunityName } });
    };
    //To navigate through the file name


    const navigateToUpload = (opportunity_id, opportunityName, responseType, userID) => {
        console.log(responseType, "navigated")
        responseType = responseType.includes("RFI") ? 'rfi_document' : 'rfp_document'
        console.log(userID, "navigated")
        navigate('/UploadDocument', { state: { "opportunity_id": opportunity_id, "user_id": user_id, "responseType": responseType, "userID": userID, "jwtToken": jwtToken, "opportunity_name": opportunityName } });
    };

    /*To show toast message in the  display screen */
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    const handleWrittingSubmit = async (blobID, is_load = false) => {

        if (is_load == false) { setLoader(true) }


        try {

            console.log('Entered submit--------------------------------------------')

            console.log('before blob', blobID)
            const fileUploadtoblob = await uploadFileToBlob(selectedFile, blobID);
            const blobUrl = fileUploadtoblob
            console.log('upload url', blobUrl)
            console.log('upload url', blobID)
            console.log('upload url', user_id)
            const responseData = await writtingscoring({ "opportunity_id": blobID, "url": blobUrl, "user_id": user_id }, jwtToken);


            if (responseData.status == true) {
                let score = responseData.score

                const scoreChange = [...opportunities];
                let oppIndex = scoreChange.findIndex(opp => opp.oppurtunity_id == blobID);
                scoreChange[oppIndex] = {
                    ...scoreChange[oppIndex],
                    writings_scoring: score,
                    writings_scoring_filename: selectedFile.name
                };
                setOpportunities(scoreChange);
                setLoader(() => false)
                setSelectedFile(null)
                setWritingFile("")
                return score
            } else if (responseData.message == "Mail id not found") {
                openToast("Email Not Found", "images/cross - red.svg");
            }
            else if (responseData.message == 'Token Expired') {
                openToast("Token Expired", "images/cross - red.svg");
            } else if (responseData.message == 'User_Id mismatch between JWT token and request body') {
                openToast("Unauthorized Access", "images/cross - red.svg");
            } else if (responseData.message == 'Token Invalid') {
                openToast("Invalid token", "images/cross - red.svg");
            } else if (responseData.status == 500) {
                openToast("Internal server error", "images/cross - red.svg");
            }

        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "pagination",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    }

    const ranges = [
        { id: 1, min: 0, max: 100 },
        { id: 2, min: 101, max: 300 },
        { id: 3, min: 301, max: 600 },
        { id: 4, min: 601, max: 900 },
        { id: 5, min: 901, max: 1000 },
    ];
    /*Filter  PS_G_217 to 226 */

    const filterBox = (newSource, newStatus, newMetaScoring, newWritingScoring, newDueFrom, newDueTo) => {
        newStatus = (newStatus.length == 0 ? false : newStatus)
        newMetaScoring = (newMetaScoring.length == 0 ? false : newMetaScoring)
        newWritingScoring = (newWritingScoring.length == 0 ? false : newWritingScoring)
        let filtered = staticOpportunities.filter(opportunity => {
            const matchesSource = newSource ? opportunity.oppurtunity_link.includes(newSource) : true;
            const matchesStatus = newStatus ? opportunity.generated_documents == newStatus : true;
            let min = 0
            let max = 100
            if (newMetaScoring) {
                [min, max] = newMetaScoring.split('-').map(Number);
            }
            const matchesMetaScoring = newMetaScoring ? opportunity.metadata_scoring >= min && opportunity.metadata_scoring <= max : true;
            const selectedWriteRange = ranges.find(range => range.id == newWritingScoring);
            const matchesWrittingScoring = newWritingScoring ? opportunity.writings_scoring >= selectedWriteRange?.min && opportunity.writings_scoring <= selectedWriteRange?.max : true;
            const matchesDueFrom = newDueFrom ? new Date(opportunity.original_due_date) >= new Date(newDueFrom) : true;
            const matchesDueTo = newDueTo ? new Date(opportunity.original_due_date) <= new Date(newDueTo) : true;
            return matchesSource && matchesStatus && matchesMetaScoring && matchesWrittingScoring && matchesDueFrom && matchesDueTo;
        });

        setOpportunities(filtered);
        setpageCount(Math.ceil(filtered.length / 10));
        setCurrentPage(1);
        setTotalRecords(filtered.length);
        console.log(filtered, "g")
        console.log(opportunities, "h")
    };
    const handleSourceChange = (e) => {
        console.log(e)
        const newSource = e.target.value;
        console.log("Source here")
        console.log(newSource)
        setSource(newSource);
    }
    const handleStatusChange = (e) => {
        console.log("HIIIIIIIIIIIIIIIIIIIIIIIII2", e.target.name)
        const newStatus = e.target.value;
        setStatus(newStatus);
    };
    const handleMetaScoring = (e) => {
        console.log("new Meta data")
        console.log(parseInt(e.target.value))
        const newMetaScoring = parseInt(e.target.value);
        setMetaScore(e.target.value);
    };
    const handleWritingScoring = (e) => {
        console.log("HIIIIIIIIIIIIIIIIIIIIIIIII", e.target.value)
        const newWritingScoring = parseInt(e.target.value);
        setWrittingScore(e.target.value);
    };
    const handleDueDateFrom = (e) => {
        const newDueFrom = e.target.value;
        console.log("Due data")
        console.log(newDueFrom)
        setStartDate(newDueFrom);
    };
    const handleDueDateTo = (e) => {
        setEndDate(e.target.value)
    };



    const handleInputChange = (e) => {
        const term = e.target.value;
        setSearch(term);
        handleSearch(term);
    };
    const handleToggle = (id) => {
        setOpenId(id)
    };
    const extractFileName = (url) => {
        try {
            console.log('url', url)
            const parts = url.split('/')
            console.log('parts', parts)
            return parts[parts.length - 1];
        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: " extractFileName",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }
    }

    const handleDownload = async (blobUrl) => {
        try {
            // Assuming `extractFileName` function correctly extracts the file name from the URL
            const response = await fetch(blobUrl);

            if (!response.ok) {

                throw new Error(`Failed to fetch the file: ${response.statusText}`);

            }

            const blob = await response.blob();

            const downloadUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');

            a.href = downloadUrl;

            a.download = extractFileName(blobUrl) || 'download.docx';

            document.body.appendChild(a);

            a.click();

            window.URL.revokeObjectURL(downloadUrl);

            document.body.removeChild(a);
        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Oppurtunity.jsx",
                function_name: "handleDownload",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }

    };
    const selectedIdsArray = selectedIds.map(item => item.id);
    console.log(show, "show---------------------")
    const opportunityMap = () => {
        console.log(currentPage, opportunities, staticOpportunities, PageCount);

        return opportunities?.map((item, index) => {
            if (index >= ((currentPage - 1) * 10) && index < ((currentPage) * 10)) {
                return <>
                    {
                        toastMessage && (
                            <div className="toast">
                                {toastImage && <img src={toastImage} alt="toast" />}
                                <p>{toastMessage}</p>
                            </div>
                        )
                    }
                    <tr key={item} className="accordion-middle">
                        <td className="pe-1 ps-4">
                            <input
                                type="checkbox"
                                className=" cursor-pointer form-check-input cust-form-check m-0"
                                id={item.oppurtunity_id}
                                defaultChecked=""

                                checked={selectedIdsArray.includes(item.oppurtunity_id)}
                                onChange={() => handleCheckboxClick(item.oppurtunity_id, item.search_name)}
                            />
                        </td>
                        <td className="ps-1">
                            <span >

                                <img
                                    src="images/accordion-arrow.svg"
                                    alt="table-accordion-arrow"
                                    data-bs-toggle="collapse"

                                    data-bs-target="#badsami1"
                                    className="cursor-pointer table-accordion-arrow collapsed custom-disabled"
                                    onClick={() => {
                                        handleAccordionClick(index)
                                        console.log("function callll")
                                    }}


                                />



                            </span>

                        </td>


                        <td className="text-nowrap">
                            <span data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={item.oppurtunity_name} >
                                {truncateString(item.oppurtunity_name, 50)}
                                {item.updated_date ? (<span className="cursor-pointer table-batch font-10 font-regular px-3 py-2">Updated</span>) : (<></>)}
                            </span>
                        </td>

                        <td className="text-nowrap">
                            {getWebsiteName(item.oppurtunity_link) ?
                                <a href={item.oppurtunity_link} className="cursor-pointer text-decoration-none link-color" target="_blank"
                                    rel="noopener noreferrer" >{getWebsiteName(item.oppurtunity_link)}
                                </a> : <> NA </>}
                        </td>

                        <td className="text-nowrap">{renderScoring(item.metadata_scoring)}</td>

                        {item.writings_scoring ?
                            (<td className="text-nowrap"><a className="cursor-pointer text-decoration-none link-color" data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop" onClick={() => { setWritingFile(item.writings_scoring_filename.includes('.pdf') ? item.writings_scoring_filename.substring(0, item.writings_scoring_filename.indexOf('.pdf') + 4):""); setWriteID(item.oppurtunity_id) }}>
                                {item.writings_scoring}  </a> </td>)

                            :
                            (<td className="text-nowrap"> <a className="cursor-pointer text-decoration-none link-color" data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop" onClick={() => setWriteID(item.oppurtunity_id)}>
                                Upload </a></td>)
                        }




                        <td className="text-nowrap">
                            {item.source_date}{" "}


                        </td>

                        <td className="text-nowrap">

                            <span

                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title={item.original_due_date}
                            >
                                {item.converted_due_date}{" "}
                            </span>

                        </td>
                        <td className="text-nowrap">{item.generated_documents}</td>

                        <td className="text-nowrap d-flex align-items-center justify-content-center">
                            <span className="action-drop-down position-relative">
                                <img
                                    src="images/action-icon.svg"
                                    alt="action-icon"
                                    data-bs-toggle="dropdown"
                                    className="p-2 action-icon"
                                />

                                <span className="dropdown-menu dropdown-menu-end  font-regular font-13 p-1">
                                    {rfp == true || rfp == "true" ? (<span className="d-flex gap-2 px-3 py-2 list" onClick={() => {
                                        let usercreate = uploadCheck(item.oppurtunity_id, "RFP");

                                        navigateToRFPproposal(item.oppurtunity_id, item.oppurtunity_name, 'rfp_document', usercreate[0])
                                    }}>
                                        <img src="images/ai-icon.svg" alt="ai-icon" />
                                        RFP Response
                                    </span>) : (<></>)}
                                    {rfi == true || rfi == "true" ? (<span className="d-flex gap-2 px-3 py-2 list" onClick={() => {
                                        let usercreate = uploadCheck(item.oppurtunity_id, "RFI");
                                        console.log(usercreate[0], "The data returned")
                                        navigateToRFPproposal(item.oppurtunity_id, item.oppurtunity_name, 'rfi_document', usercreate[0])
                                    }}>
                                        <img src="images/ai-icon.svg" alt="ai-icon" />
                                        RFI Response
                                    </span>) : (<></>)}

                                </span>

                            </span>
                        </td>
                    </tr>

                    {show == index ? (
                        <tr key={item.oppurtunity_id}
                            className="collapse bg-white" id="badsami10" style={{ 'display': 'contents' }}>
                            <td colspan="10" className="px-5 pt-0">
                                <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                    <table className="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-nowrap">File Name</th>
                                                <th className="text-nowrap">Type</th>
                                                <th className="text-nowrap">Generated By</th>
                                                <th>Last Generated On</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        {console.log("Done")}
                                        <tbody>

                                            {files?.map((file) => {
                                                if (item.oppurtunity_id == file.oppurtunity_id) {
                                                    return <tr className="accordion-middle">
                                                        <td>
                                                            <a href='' className="text-decoration-none link-color" onClick={() => navigateToUpload(item.oppurtunity_id, item.oppurtunity_name, file.response_type, file.user_id)} >
                                                                {file.response_name}
                                                            </a>
                                                        </td>
                                                        <td>{file.response_type}</td>
                                                        <td>{file.user_name}</td>
                                                        <td>
                                                            {file.generated_time.replace("GMT", "EST")}
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn" onClick={() => handleDownload(file.blob_link)}>
                                                                <img src="images/download-blue.svg" alt="download-icon" className="cursor-pointer" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                } else { return <></> }
                                            })}
                                        </tbody>
                                    </table>
                                </span>
                            </td>
                        </tr>) : <></>}

                </>
            } else { return <></> }
        }
        )
    }


    function toggleApplyButton() {
        setActivityChanged(false);
    }
    function resetFields() {
        setSource("");
        setStatus("");
        setMetaScore("");
        setWrittingScore("");
        setStartDate("");
        setEndDate("");
        setActivityChanged(true);
        console.log(staticOpportunities, "static")
        setOpportunities(() => staticOpportunities)
        setpageCount(Math.ceil(staticOpportunities.length / 10));
        setCurrentPage(1);
        setTotalRecords(staticOpportunities.length);
    }
    function handleApplyFilter() {
        console.log("Welcome")
        if (endDate != "" && startDate != "") {
            const startMoment = moment(startDate);
            const endMoment = moment(endDate);
            // moment's diff will be negative if the end moment is before the start moment
            const diffDays = endMoment.diff(startMoment, 'days');
            console.log("Datttttttttttttttttttttttttttttttttttttttteeeeeeeeeee")
            console.log(startDate)
            console.log(endDate)
            console.log(diffDays)
            if (diffDays > 0) {
                setallowDate(false)
                setToggleMenu(false)
                filterBox(source, status, metaScore, writtingScore, startDate, endDate)
            }
            else {
                setallowDate(true)
                return;
            }
        }
        else {
            setToggleMenu(false)
            filterBox(source, status, metaScore, writtingScore, startDate, endDate)
        }

    }
    console.log("FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF", writingsFile, selectedFile)

    console.log("end")
    return (
        <>
            <div>
                {<Header user_id={user_id} jwtToken={jwtToken} moduleName={1} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
            </div>
            {loader ? (
                <Loader />

            ) : (
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12 p-5">


                            <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false"
                                tabIndex="-1" aria-labelledby="staticBackdropLabel1" aria-hidden="true" >
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content border-size-5">
                                        <div className="modal-header border-0 px-4 pb-0">
                                            <h1 className="modal-title font-semibold color-black font-20" id="exampleModalLabel">Add New
                                                Opportunity</h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                onClick={() => nullifyNewOpportunityFields()}
                                                aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body px-4 pt-0">
                                            <div className="mt-3">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-12 mb-4">
                                                                <label className="form-label font-14 font-semibold primary-color"
                                                                    htmlFor="OpportunityName">Opportunity Name</label>

                                                                <input type="text" value={opportunityName} onChange={(e) => setOpportunityName(e.target.value)}
                                                                    className="form-control custom-form font-13 font-regular py-2"
                                                                    id="OpportunityName" placeholder="Enter Opportunity Name" />
                                                                {errors.opportunityName && <p style={{ color: 'red' }}>{errors.opportunityName}</p>}
                                                            </div>

                                                            <div className="col-md-6 mb-4">
                                                                <label className="form-label font-14 font-semibold primary-color"
                                                                    htmlFor="SourceURL">Source URL</label>
                                                                <input type="text" value={sourceURL} onChange={(e) => setSourceURL(e.target.value)}
                                                                    className="form-control custom-form font-13 font-regular py-2"
                                                                    id="SourceURL" placeholder="Enter Source URL" />
                                                            </div>

                                                            <div className="col-md-6 mb-4">
                                                                <label className="form-label font-14 font-semibold primary-color"
                                                                    htmlFor="MetadataScoring">Metadata Scoring</label>
                                                                <input type="number" value={metadataScoring} onChange={(e) => setMetadataScoring(e.target.value)}
                                                                    className="form-control custom-form font-13 font-regular py-2"
                                                                    id="MetadataScoring" placeholder="Enter Metadata Scoring" />
                                                                {errors.metadataScoring && <p style={{ color: 'red' }}>{errors.metadataScoring}</p>}

                                                            </div>

                                                            <div className="col-md-12 mb-2">
                                                                <h5 className="font-semibold font-16 primary-color">Due Date</h5>
                                                            </div>
                                                            <div className="col-md-6 mb-4">
                                                                <label className="form-label font-semibold primary-color font-14" htmlFor="Date">Date</label>
                                                                <div className="position-relative">
                                                                    <input
                                                                        type="date"
                                                                        value={dueDate}
                                                                        onChange={(e) => setDueDate(e.target.value)}
                                                                        className="form-control custom-form font-13 font-regular py-2"
                                                                        id="Date"
                                                                        min={new Date().toISOString().split('T')[0]} // Set the min attribute to today's date
                                                                    />
                                                                    <img className="position-absolute custom-input-icon" src="images/calender-icon.svg" alt="calender-icon" />
                                                                    {errors.dueDate && <p style={{ color: 'red' }}>{errors.dueDate}</p>}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 mb-4">
                                                                <label className="form-label font-semibold primary-color font-14"
                                                                    htmlFor="Time">Time</label>
                                                                <div className="position-relative">
                                                                    <input type="time" value={dueTime} onChange={(e) => setDueTime(e.target.value)}
                                                                        className="form-control custom-form font-13 font-regular py-2"
                                                                        id="Time" />
                                                                    <img className="position-absolute custom-input-icon"
                                                                        src="images/time-icon.svg" alt="time-icon" />
                                                                    {errors.dueTime && <p style={{ color: 'red' }}>{errors.dueTime}</p>}

                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 mb-4">
                                                                <label className="form-label font-semibold primary-color font-14"
                                                                    htmlFor="TimeZone">Time Zone</label>
                                                                <select className="form-select custom-select-arrow custom-form"
                                                                    aria-label="Default select example" value={timezone}
                                                                    onChange={(e) => setTimezone(e.target.value)}>
                                                                    <option value="">Select</option>
                                                                    <option value="EST" >EST</option>
                                                                    <option value="CST" >CST</option>
                                                                    <option value="MST" >MST</option>
                                                                    <option value="PST" >PST</option>
                                                                    <option value="HAST">HAST</option>
                                                                </select>
                                                                {errors.timezone && <p style={{ color: 'red' }}>{errors.timezone}</p>}

                                                            </div>

                                                            <div className="col-md-12 mb-4">
                                                                <label htmlFor="UploadWritings"
                                                                    className="pb-2 font-semibold font-14 color-black">Upload
                                                                    Writings</label>
                                                                <span className="upload-btn-wrapper d-block">
                                                                    <button type="button"
                                                                        className="upload-btn cursor-pointer py-4 font-14 border-size-5">
                                                                        <img src="images/upload.svg" alt="Upload" className="mb-2" />
                                                                        <p className="font-regular font-13 m-0">Drag and drop PDF files
                                                                            here or <a
                                                                                className="text-decoration-none">browse</a></p>
                                                                    </button>
                                                                    <input type="file" name="myfile"
                                                                        accept=".pdf"
                                                                        onChange={(e) => { handleFileSelect(e); }}
                                                                        className="cursor-pointer cust-file" id="UploadWritings"
                                                                        disabled={selectedFile ? true : false}
                                                                    />


                                                                </span>
                                                                <p className="font-14 font-regular warning">
                                                                    {errorMessage}
                                                                </p>
                                                                {
                                                                    selectedFile ? (
                                                                        <div className="uploaded-content my-3 px-2 py-1 border-size-5">
                                                                            <span className="font-medium font-14">
                                                                                {selectedFile.name}
                                                                                <img
                                                                                    src="images/close-icon.svg"
                                                                                    alt="close-icon"
                                                                                    className="cursor-pointer ms-3"
                                                                                    onClick={() => setSelectedFile(null)}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    ) : null}

                                                                {errors.file && <p style={{ color: 'red' }}>{errors.file}</p>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="modal-footer gap-2 text-end pt-3 px-0 border-0">
                                                    <button type="button"
                                                        className="btn cancel-btn font-13 primary-color font-medium"
                                                        onClick={() => nullifyNewOpportunityFields()}
                                                        data-bs-dismiss="modal"
                                                        id="CancelHandleSubmit">Cancel</button>

                                                    <button type="button"
                                                        className="btn submit-btn font-13 color-white font-medium"
                                                        data-bs-dismiss="modal"
                                                        disabled={selectedFile == null || selectedFile == "" || metadataScoring == "" || timezone == "" || dueTime == "" || dueDate == "" || opportunityName == "" ? true : false}
                                                        onClick={() => {

                                                            getLink(selectedFile, opportunityName);
                                                        }}
                                                    >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                <h5 className="font-22 font-semibold">Opportunities</h5>
                                <div className="d-flex gap-3 w-md-100 flex-wrap">
                                    <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                                        <div className="d-flex gap-3 flex-md-row w-md-100 align-items-center position-relative">
                                            <div className="input-group input-search">
                                                {/*SEARCH FUNCTIONALITY*/}
                                                <input
                                                    type="search"
                                                    className="form-control border-end-0 font-14 font-regular search-input"
                                                    placeholder="Search"
                                                    onChange={(e) => handleInputChange(e)}

                                                />
                                                <button
                                                    className="btn border-start-0 search-input"
                                                    type="button"
                                                >
                                                    <img src="images/search.svg" alt="search" className="mb-1" />
                                                </button>
                                            </div>
                                            <button
                                                className="btn filter-btn lh-0"
                                                id="dropdownMenuLink"
                                                aria-expanded="false"
                                                style={{ display: "flex" }}
                                                onClick={() => setToggleMenu(true)}
                                            >
                                                <img
                                                    src="images/filter-active.svg"
                                                    alt="filter-icon"
                                                    className="filter-icon"
                                                />
                                            </button>
                                            {/*filter*/}
                                            <div
                                                className={`dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0`} style={toggleMenu ? { position: "absolute", inset: "0px auto auto 0px", margin: "0px", top: "41px",
                                                    left: "-96px", display: "block" } : {}}
                                            >
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                    <h5 className="font-semibold font-20 mb-0 color-black">
                                                        Advanced Filter
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="btn-close"
                                                        aria-label="Close"
                                                        onClick={() => { resetFields(); setToggleMenu(false); setallowDate(false) }}
                                                    />
                                                </div> {/*FILTER FUNCTIONALITY*/}
                                                <div className="row" style={{ display: "flex" }}>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="status"
                                                        >
                                                            Source
                                                        </label>
                                                        <select
                                                            onChange={(e) => { toggleApplyButton(); handleSourceChange(e) }}
                                                            className="form-select custom-select-arrow custom-form"
                                                            aria-label="Default select example"
                                                            id="status"
                                                            name="source"
                                                            value={source}
                                                        >
                                                            <option selected="" hidden>Select</option>

                                                            {website?.map((website) => (

                                                                <option key={website.website_id} value={website.website_name}>
                                                                    {website.website_name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="current-plan"
                                                        >
                                                            Status
                                                        </label>
                                                        <select
                                                            className="form-select custom-select-arrow custom-form"
                                                            aria-label="Default select example"
                                                            id="current-plan"
                                                            value={status}
                                                            name='status'
                                                            onChange={(e) => { toggleApplyButton(); handleStatusChange(e) }}
                                                        >
                                                            <option selected="" hidden>Select</option>
                                                            <option value="RFP Generated">RFP Generated</option>
                                                            <option value="RFI Generated">RFI Generated</option>
                                                            <option value="RFP & RFI Generated">RFP and RFI Generated</option>

                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="environment"
                                                        >
                                                            Metadata Scoring
                                                        </label>
                                                        <select
                                                            className="form-select custom-select-arrow custom-form"
                                                            aria-label="Default select example"
                                                            id="environment"
                                                            value={metaScore}
                                                            name='metaScore'
                                                            onChange={(e) => { toggleApplyButton(); handleMetaScoring(e) }}
                                                        >
                                                            <option selected="" hidden>Select</option>
                                                            <option value="0-100"> 0-100</option>
                                                            <option value="100-300">100-300</option>
                                                            <option value="300-600">300-600</option>
                                                            <option value="600-900">600-900</option>
                                                            <option value="900-1000">900-1000</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="environment"
                                                        >
                                                            Writings Scoring
                                                        </label>
                                                        <select
                                                            className="form-select custom-select-arrow custom-form"
                                                            aria-label="Default select example"
                                                            id="environment"
                                                            value={writtingScore}
                                                            name='writtingScore'
                                                            onChange={(e) => { toggleApplyButton(); handleWritingScoring(e) }}
                                                        >
                                                            <option value="" hidden>Select</option>
                                                            <option value="1"> 0-100</option>
                                                            <option value="2">100-300</option>
                                                            <option value="3">300-600</option>
                                                            <option value="4">600-900</option>
                                                            <option value="5">900-1000</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-12 mb-2">
                                                        <label className="font-16 font-semibold color-black">
                                                            Due Date
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="from"
                                                        >
                                                            From
                                                        </label>
                                                        <div className="position-relative">
                                                            <input
                                                                type="date"
                                                                className="form-control custom-form font-13 font-regular"
                                                                id="Date"
                                                                name='startDate'
                                                                value={startDate}
                                                                onChange={(e) => { toggleApplyButton(); handleDueDateFrom(e) }}
                                                            />
                                                            <img
                                                                className="position-absolute custom-input-icon"
                                                                src="images/calender-icon.svg"
                                                                alt="calender-icon"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label
                                                            className="form-label font-14 font-semibold primary-color"
                                                            htmlFor="to"
                                                        >
                                                            To
                                                        </label>
                                                        <div className="position-relative">
                                                            <input
                                                                type="date"
                                                                className="form-control custom-form font-13 font-regular"
                                                                id="Date"
                                                                value={endDate}
                                                                name='endDate'
                                                                onChange={(e) => { toggleApplyButton(); handleDueDateTo(e) }}
                                                            />
                                                            <img
                                                                className="position-absolute custom-input-icon"
                                                                src="images/calender-icon.svg"
                                                                alt="calender-icon"
                                                            />
                                                            {allowDate && <span style={{ color: 'red' }}>The due date must be post the from date</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex justify-content-end align-items-center py-3">
                                                            <button className="btn cancel-btn me-3 px-4 font-medium font-13 primary-color" onClick={() => { resetFields(); setToggleMenu(false); setallowDate(false) }}>
                                                                Cancel
                                                            </button>
                                                            <button className="btn submit-btn color-white px-4 font-medium font-13" id='Apply_button' disabled={(activityChanged) ? true : false} onClick={() => { handleApplyFilter() }} >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>  {/*FILTER FUNCTIONALITY END*/}
                                            </div>
                                            {/*filter*/}
                                        </div>
                                    </div>
                                    <button className="btn submit-btn text-nowrap font-regular font-13 color-white border-size-5 d-flex gap-2 justify-content-center align-items-center"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop1"
                                    >
                                        <img src="images/add-plus-icon.svg" alt="add-plus-icon" />
                                        New Opportunity
                                    </button>
                                </div>
                                <div
                                    className="modal fade"
                                    id="staticBackdrop"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false"
                                    tabIndex={-1}
                                    aria-labelledby="staticBackdropLabel"
                                    aria-hidden="true"
                                >
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content border-size-5">
                                            <div className="modal-header border-0 px-4 pb-0">
                                                <h1
                                                    className="modal-title font-semibold color-black font-20"
                                                    id="exampleModalLabel"
                                                >
                                                    Writing Scoring
                                                </h1>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { setSelectedFile(null); setWritingFile(""); setErrorMessage("") }}
                                                />
                                            </div>
                                            <div className="modal-body px-4 pt-0">
                                                <div className="mt-3">
                                                    <label
                                                        htmlFor="Upload"
                                                        className="pb-2 font-semibold font-14 color-black"
                                                    >
                                                        Upload
                                                    </label>
                                                    <span className="upload-btn-wrapper d-block">
                                                        <button
                                                            type="button"
                                                            className="upload-btn cursor-pointer py-4 font-14 border-size-5"
                                                        >
                                                            <img
                                                                src="images/upload.svg"
                                                                alt="Upload"
                                                                className="mb-2"
                                                            />
                                                            <p className="font-regular font-13 m-0">
                                                                Drag and drop PDF files here or{" "}
                                                                <a className="text-decoration-none">
                                                                    browse
                                                                </a>
                                                            </p>
                                                        </button>
                                                        <input
                                                            type="file"
                                                            name="myfile"
                                                            className="cursor-pointer cust-file"
                                                            id="Upload"
                                                            accept=".pdf"
                                                            onChange={(e) => handleFileSelect(e)}
                                                            disabled={selectedFile || writingsFile ? true : false}

                                                        />
                                                    </span>
                                                    <p className="font-14 font-regular warning">
                                                        {errorMessage}
                                                    </p>
                                                    {
                                                        selectedFile ? (
                                                            <div className="uploaded-content my-3 px-2 py-1 border-size-5">
                                                                <span className="font-medium font-14 d-flex">
                                                                    <span className="text-break">{selectedFile.name}</span>
                                                                    <img
                                                                        src="images/close-icon.svg"
                                                                        alt="close-icon"
                                                                        className="cursor-pointer ms-3"
                                                                        onClick={() => setSelectedFile(null)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                    {
                                                        writingsFile != null && writingsFile != "" ? (
                                                            <div className="uploaded-content my-3 px-2 py-1 border-size-5">
                                                                <span className="font-medium font-14 d-flex">
                                                                    <span className="text-break">{writingsFile}</span>
                                                                    <img
                                                                        src="images/close-icon.svg"
                                                                        alt="close-icon"
                                                                        className="cursor-pointer ms-3"
                                                                        onClick={() => setWritingFile("")}
                                                                    />
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                    <div className="modal-footer gap-2 text-end pt-5 px-0 border-0">
                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn font-13 primary-color font-medium"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => { setSelectedFile(() => null); setWritingFile(""); setErrorMessage("") }}                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn submit-btn font-13 color-white font-medium"
                                                            data-bs-dismiss="modal"
                                                            disabled={selectedFile == null || selectedFile == '' ? true : false}
                                                            onClick={() => {
                                                                setWritingFile("");
                                                                handleWrittingSubmit(WriteID)

                                                            }
                                                            }
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive theme-table bg-white">
                                <table className="table table-borderless mb-0 cust-acc-table">
                                    <thead>
                                        <tr>
                                            <th className="pe-1 ps-4">
                                                <input
                                                    type="checkbox"
                                                    className=" cursor-pointer form-check-input cust-form-check m-0"
                                                    id="checkbox"
                                                    checked={isAllSelected}
                                                    onChange={handleSelectAllClick}
                                                />
                                            </th>
                                            <th className="ps-1" />
                                            <th className="text-nowrap">Opportunity Name</th>
                                            <th className="text-nowrap">Source</th>
                                            <th className="text-nowrap">
                                                Metadata Scoring
                                                <span className="ms-1 cursor-pointer">
                                                    {sortArrow == 'uparrow' && sortcolumn == 'metadata_scoring' ? <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" onClick={() => sortData('metadata_scoring', 'downarrow')} /> : <img src="images/sort-arrow-down.svg" alt="sort-arrow-down" onClick={() => sortData('metadata_scoring', 'uparrow')} />}
                                                </span>
                                            </th>
                                            <th className="text-nowrap">
                                                Writings Scoring
                                                <span className="ms-1 cursor-pointer">
                                                    {sortArrow == 'uparrow' && sortcolumn == 'writings_scoring' ? <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" onClick={() => sortData('writings_scoring', 'downarrow')} /> : <img src="images/sort-arrow-down.svg" alt="sort-arrow-down" onClick={() => sortData('writings_scoring', 'uparrow')} />}

                                                </span>
                                            </th>
                                            <th className="text-nowrap">
                                                Source Date
                                                <span className="ms-1 cursor-pointer">
                                                    {sortArrow == 'uparrow' && sortcolumn == 'source_date' ? <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" onClick={() => sortData('source_date', 'downarrow')} /> : <img src="images/sort-arrow-down.svg" alt="sort-arrow-down" onClick={() => sortData('source_date', 'uparrow')} />}

                                                </span>
                                            </th>
                                            <th className="text-nowrap">
                                                Due Date

                                                <span className="ms-1 cursor-pointer">
                                                    <img
                                                        src="images/tooltip-icon.svg"
                                                        alt="tooltip-icon"
                                                        className="me-1"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        title="Hover the time to see actual time zone"
                                                    />

                                                    {sortArrow == 'uparrow' && sortcolumn == 'converted_due_date' ? <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" onClick={() => sortData('converted_due_date', 'downarrow')} /> : <img src="images/sort-arrow-down.svg" alt="sort-arrow-down" onClick={() => sortData('converted_due_date', 'uparrow')} />}

                                                </span>
                                            </th>
                                            <th className="text-nowrap">Status</th>
                                            <th className="text-nowrap text-center pe-3">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>

                                        {opportunities.length == 0 ? (
                                            <tr>
                                                <th colSpan={8}>
                                                    <div className="text-center mb-5 table-no-record">
                                                        <img
                                                            src="images/no-record.svg"
                                                            alt="no-record-img"
                                                            className="mb-5"
                                                        />
                                                        <p className="text-center font-22 font-semibold primary-color">
                                                            No results found
                                                        </p>
                                                        <p className="text-center font-14 font-regular color-grey font-400">
                                                            No results match for the search or to show results
                                                        </p>
                                                    </div>
                                                </th>
                                            </tr>
                                        ) : (

                                            opportunityMap()
                                        )
                                        }



                                    </tbody>

                                </table>
                            </div>
                            <div className="d-flex justify-content-between mt-4">
                                <p className="font-14 font-regular color-grey-v2">
                                    # of Records :{" "}
                                    <span className="font-semibold font-16 color-black">{currentPage == PageCount ? totalRecords : currentPage * 10}</span> out of{" "}
                                    <span className="font-semibold font-16 color-black">{totalRecords}</span>
                                </p>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination gap-2">
                                        <li className="page-item">
                                            <a
                                                className="cursor-pointer page-link page-text d-flex justify-content-center"

                                                aria-label="Previous"
                                                onClick={() => setCurrentPage(1)}
                                            >
                                                <span aria-hidden="true" className="font-10 font-semibold">
                                                    <img src="images/left-arrows.svg" alt="left-arrows" />
                                                </span>
                                            </a>
                                        </li>
                                        <ResponsivePagination
                                            current={currentPage}
                                            total={PageCount}
                                            onPageChange={setCurrentPage}
                                            maxWidth={15}
                                            pageItemClassName="page-item page-text page-link"
                                            pageLinkClassName="cursor-pointer font-10 d-flex justify-content-center font-semibold"
                                            activeItemClassName="cursor-pointer page-text page-link font-10 d-flex justify-content-center font-semibold active text-decoration-none color-white "
                                            previousClassName="page-link page-text me-2"
                                            nextClassName="page-link page-text ms-2"
                                            previousLabel="<"
                                            nextLabel=">"
                                        /><li className="page-item">
                                            <a
                                                className="cursor-pointer page-link page-text d-flex justify-content-center"

                                                aria-label="Next"
                                                onClick={() => setCurrentPage(PageCount)}

                                            >
                                                <span aria-hidden="true" className="font-10 font-semibold">
                                                    <img src="images/right-arrows.svg" alt="right-arrows" />
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    {selectedIds?.length > 0 ? (
                        <div className="d-flex bottom-75">
                            <div className="custom-toast-select sticky-bottom d-flex align-items-center justify-content-between m-4">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <button type="button" className="btn p-0 me-3 lh-0">
                                        <img src="images/close-white.svg" alt="close-icon" onClick={() => { setSelectedIds([]); setIsAllSelected(false) }} />
                                    </button>
                                    <div className="border-size-5 items-selected px-4 py-2 color-white font-14 font-semibold">
                                        {selectedIds?.length}
                                    </div>
                                    <p className="font-14 font-semibold color-white m-0 ms-2">
                                        items selected
                                    </p>
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn primary-btn border-size-5 font-13 font-medium color-white me-3"
                                        onClick={() => sustained()}>
                                        <img src="images/sustain-icon.svg" alt="sustain-icon" className="me-2" />
                                        Sustain
                                    </button>
                                    <button type="button" className="btn primary-btn border-size-5 font-13 font-medium color-white me-3" onClick={() => exportToExcel()}>
                                        <img src="images/export-icon.svg" alt="export-icon" className="me-2" />
                                        Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            )}
        </>

    )

}

export default Opportunities