import { BlobServiceClient } from "@azure/storage-blob";

const AZURE_STORAGE_SAS_URL = "https://cineusbadsamisgdev.blob.core.windows.net/?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupyx&se=2025-06-05T22:12:28Z&st=2024-06-05T14:12:28Z&spr=https,http&sig=SrCckL%2B9YOvqqBFvwcDcqfRObC0UN7cCxdTk%2F5W1vBM%3D";

export async function uploadFileToBlob(file, category) {
    try {
        // Ensure the SAS URL is correctly formatted and complete
        const blobServiceClient = new BlobServiceClient(AZURE_STORAGE_SAS_URL);
        console.log(blobServiceClient,"blob");
        
        // Extract container name from SAS URL or use a hardcoded name if not included in SAS URL
        const containerName = "badsami"; // Your container name, should match the one in your SAS URL

        // Get a reference to the container client
        const containerClient = blobServiceClient.getContainerClient(containerName);
        console.log(containerClient,"blob0");

        // Construct the blob name using opportunityId and file name
        const blobName = `Writings/${category}/${file.name}`;
        console.log(blobName,"blob1");

        // Get a block blob client for the constructed blob name
        const blobClient = containerClient.getBlockBlobClient(blobName);
        console.log(blobClient,"blob2");

        // Upload the file to the blob
        const uploadBlobResponse = await blobClient.uploadBrowserData(file);
        console.log(uploadBlobResponse);
        console.log(`Upload block blob ${file.name} successfully`, uploadBlobResponse);
        console.log(blobClient.url);
        // Return an object containing the URL and the file name
        return blobClient.url
    } catch (error) {
        console.error("Error uploading file to blob storage:", error.message);
        throw new Error("File upload failed.");
    }
}