//  PS_01 to 05
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import mammoth from 'mammoth';
import Header from "./header/Header";
import { RfpResponse, RfiResponse, fetchDetailsApi, insertErrorApi, postDetailsApi } from '../services/api';
import { decode } from 'base-64';

// PS_05 to 10
const ResponsePreview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('response preview location', location)

    const getDecodedItem = (key) => {
        const encodedValue = localStorage.getItem(key);
        if (!encodedValue) return null;
        return decode(encodedValue.substring(15, encodedValue.length - 10));
    };

    const mail_Id = getDecodedItem('2tMb2dpbg23Ly')
    const user_role = getDecodedItem('5mMb2dpbg23Ly')
    const rfp = getDecodedItem('2bTs2dpbg23Ly')
    const rfi = getDecodedItem('m3Da2dpbg23Ly')

    const opportunity_id = location.state?.opportunity_id
    const user_id = location.state?.user_id
    const responseType = location.state?.responseType
    const opportunityName = location.state?.opportunityName
    const resType = location.state?.resType
    const jwtToken = location.state?.jwtToken

    console.log('opppp anme', opportunityName)
    console.log('user id', user_id, 'mail id', mail_Id, 'roleee', user_role, 'rfppp', rfp, 'rfii', rfi, 'jwttpken', jwtToken)
    console.log('user idddddddd', user_id)

    const [loading, setLoading] = useState(true);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastImage, setToastImage] = useState("");
    const [blobUrl, setBlobUrl] = useState('');
    const [content, setContent] = useState('');
    const [regenerateInput, setRegenerateInput] = useState('');
    const [percentage, setPercentage] = useState(10);
    const [userId, setUserId] = useState('')
    const intervalRef = useRef(null);
    console.log("THE VALUES GOT AT STATE RESPONSE PREVIEW", user_id, opportunity_id, opportunityName, responseType, resType, user_role, mail_Id)

    // PS_11.....check the response type is rfp_response the  set that as RFP Response same for rfi_Response
    let ResponseType;
    if (resType?.includes('rfp_document')) {
        ResponseType = 'RFP Response';
    }
    if (resType?.includes('rfi_document')) {
        ResponseType = 'RFI Response';
    }

    let response_type;
    if (resType?.includes('rfp_document')) {
        response_type = 'RFP';
    }
    if (resType?.includes('rfi_document')) {
        response_type = 'RFI';
    }
    console.log('resType', resType)
    console.log('response_type', response_type)
    // PS_08
    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
        intervalRef.current = setInterval(() => {
            setPercentage((prevPercentage) => (prevPercentage < 100 ? prevPercentage + 5 : prevPercentage));
        }, 120000); // 2 minutes in milliseconds
        getResponse(opportunity_id)
        return () => clearInterval(intervalRef.current); // Clean up the interval on component unmount
    }, []); // Run this effect only once, on component mount


    console.log('user iddddddd', userId)
    console.log('user idddddd----------', user_id)


    const getResponse = async (opportunity_id) => {
        try {
            const payload = {
                "value": "responsepage",
                "tab": "oppurtunity",
                "oppurtunity_id": opportunity_id,
                "user_id": user_id,
                "type": response_type
            };
            /*Makes the call to the aAPI with the required pay load */
            const response = await fetchDetailsApi(payload, jwtToken);
            const responseData = response.data;
            console.log(responseData.responsePage.length, "ewwwe")
            console.log(response.success, "asdai")
            if (response.success == true) {
                if (responseData.responsePage.length == 0) {
                    handleFlow()
                } else {
                    const response_res = await fetchDetailsApi({
                        "value": "responsepage",
                        "tab": "oppurtunity",
                        "oppurtunity_id": opportunity_id,
                        "user_id": user_id,
                        "type": response_type
                    }, jwtToken);
                    console.log('ress', response_res)
                    console.log('res data', response_res.data)
                    console.log('res.data0', response_res.data.responsePage[0].blob_link)
                    const responsee = response_res.data.responsePage[0].blob_link
                    const userId = response_res.data.responsePage[0].user_id
                    console.log('------user id----', userId)
                    setPercentage(100);
                    clearInterval(intervalRef.current); // Clear the interval when response is generated
                    const response = await fetch(responsee);
                    const arrayBuffer = await response.arrayBuffer();
                    console.log('array buffer', arrayBuffer)
                    const result = await mammoth.convertToHtml({ arrayBuffer });
                    console.log('ressusss', result)
                    setBlobUrl(responsee)
                    setUserId(userId)
                    setContent(result.value);
                    setLoading(false);
                }

            } else if (responseData.message == "Mail id not found") {
                openToast("Email Not Found", "images/cross - red.svg");
            } else if (response.message == 'Token Expired') {
                openToast("Token Expired", "images/cross - red.svg");
            } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                openToast("Unauthorized Access", "images/cross - red.svg");
            } else if (response.message == 'Token Invalid') {
                openToast("Invalid token", "images/cross - red.svg");
            } else if (response.status == 500) {
                openToast("Internal server error", "images/cross - red.svg");
            }

        } catch (error) {
            await insertErrorApi({
                error_message: String(error),
                module_name: '__name__',
                function: 'getCompliance()',
                service: 'Frontend',
                user_id: 'user_id',
            });
        }
    };


    const handleFlow = async () => {
        try {

            let res;
            if (resType.includes('rfp_document')) {
                res = await RfpResponse({ "opportunity_id": opportunity_id, "user_id": user_id, "type": "generate" }, jwtToken);
            } else {
                res = await RfiResponse({ "opportunity_id": opportunity_id, "user_id": user_id, "type": "generate" }, jwtToken);
            }
            console.log('------------res-----------', res.data[0].blob_link)
            let response = res.data[0].blob_link
            setPercentage(100);
            clearInterval(intervalRef.current); // Clear the interval when response is generated
            setBlobUrl(response)
            const respnse = await fetch(response)
            const arrayBuffer = await respnse.arrayBuffer();
            console.log(arrayBuffer, "array buffeeerrr");
            const result = await mammoth.convertToHtml({ arrayBuffer });
            console.log('resulktttttttttt', result)
            setContent(result.value);

        } catch (error) {
            await insertErrorApi({
                error_message: error.toString(),
                module_name: 'PreviewResponse',
                function_name: 'handleFlow',
                service: 'Backend',
                user_id: user_id
            });
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    console.log('response type', responseType);
    // The handleCancel function cancels an action, deleting the opportunity if it's a creation and navigating to the 'Opportunities' page, handling errors appropriately. The handleFinish function navigates directly to the 'Opportunities' page...PS-21 to 23
    const handleCancel = async () => {
        if (responseType === 'create') {
            console.log('came to cancel', 'responseType:',responseType, 'resType:',resType, 'response_type:', response_type);
            console.log('responseType cancel', responseType)
            let response;
            const params = { "opportunity_id": opportunity_id, "user_id": user_id, "type": 'delete', "tab": "responsePreview", "response_type": response_type };
            try {
                response = await postDetailsApi(params, jwtToken)
                console.log('response', response)
                if (response.success == true) {
                    navigate('/opportunities', { state: { "user_id": user_id, "user_role": user_role, "rfp_access": rfp, "rfi_access": rfi, "jwtToken": jwtToken, "mail_Id": mail_Id } })
                } else if (response.status == 400) {
                    openToast("something went wrong", "images/cross - red.svg");
                } else if (response.message == 'Token Expired') {
                    openToast("Token Expired", "images/cross - red.svg");
                } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                    openToast("Unauthorized Access", "images/cross - red.svg");
                } else if (response.message == 'Token Invalid') {
                    openToast("Invalid token", "images/cross - red.svg");
                } else if (response.status == 500) {
                    openToast("Internal server error", "images/cross - red.svg");
                }
            } catch (error) {
                await insertErrorApi({
                    error_message: error.toString(),
                    module_name: 'PreviewResponse',
                    function_name: 'handleCancel',
                    service: 'Backend',
                    user_id: user_id
                });
                console.error(error);
            }
        } else {
            console.log('came to else cancel', 'responseType:',responseType, 'resType:',resType, 'response_type:', response_type);
            navigate('/opportunities', { state: { "user_id": user_id, "user_role": user_role, "rfp_access": rfp, "rfi_access": rfi, "jwtToken": jwtToken, "mail_Id": mail_Id } });
        }
    };

    // handleFinish function is used to save the response and navigate to opportunity page..PS_30
    console.log('opportunity navigation', user_id, jwtToken, mail_Id)
    const handleFinish = () => {

        navigate('/opportunities', { state: { "user_id": user_id, "user_role": user_role, "rfp_access": rfp, "rfi_access": rfi, "jwtToken": jwtToken, "mail_Id": mail_Id } })
    }

    // The handleRegenerateSubmit function regenerates the opportunity data based on the given input, converts the response to HTML, sets the content, and handles errors, updating the loading state throughout the process...PS_51 to 65
    console.log('regenerate input', regenerateInput)
    console.log('contextxttt', content)
    console.log('respone type', responseType)

    const handleRegenerateSubmit = async () => {
        setLoading(true);

        const params = { "opportunity_id": opportunity_id, "user_id": user_id, "type": 'regenerate', "context": regenerateInput };
        try {
            let response;
            setLoading(true);
            setPercentage(5); // Reset the percentage
            intervalRef.current = setInterval(() => {
                setPercentage((prevPercentage) => (prevPercentage < 100 ? prevPercentage + 5 : prevPercentage));
            }, 120000); // Restart the interval

            if (resType.includes('rfp_document')) {
                response = await RfpResponse(params, jwtToken);
            } else {
                response = await RfiResponse(params, jwtToken);
            }
            console.log('--------responsiveee--------', response.data[0].blob_link)
            let responsee = response.data[0].blob_link

            setPercentage(100);
            clearInterval(intervalRef.current); // Clear the interval when response is generated
            setBlobUrl(responsee)
            const respnse = await fetch(responsee)
            const arrayBuffer = await respnse.arrayBuffer();
            const result = await mammoth.convertToHtml({ arrayBuffer });
            setContent(result.value);
            setRegenerateInput('');
            setLoading(false);

        } catch (error) {
            await insertErrorApi({
                error_message: error.toString(),
                module_name: 'PreviewResponse',
                function_name: 'handleCancel',
                service: 'Backend',
                user_id: user_id
            });
            console.error(error);
        }
    };

    console.log('response type', responseType);
    // Handles navigation to the 'Considerations' page. If responseType is 'create', it attempts to delete the current response and navigates based on the success of the deletion. Otherwise, it navigates directly to 'Considerations', passing user_id as state. Displays appropriate error messages and logs any exceptions...PS_23 to 31
    const handlePrevious = async () => {
        if (responseType == 'create') {
            console.log('came to previous');
            const params = { "opportunity_id": opportunity_id, "user_id": user_id, "tab": "responsePreview", "type": 'deleteResponse', "response_type": response_type };
            try {
                let response;
                response = await postDetailsApi(params, jwtToken)
                console.log('response', response)
                if (response.success === true) {
                    navigate('/Considerations', { state: { user_id, opportunityName, opportunity_id, jwtToken, resType, responseType } })
                } else if (response.status === 400) {
                    openToast("something went wrong", "images/cross - red.svg");
                } else if (response.message == 'Token Expired') {
                    openToast("Token Expired", "images/cross - red.svg");
                } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                    openToast("Unauthorized Access", "images/cross - red.svg");
                } else if (response.message == 'Token Invalid') {
                    openToast("Invalid token", "images/cross - red.svg");
                } else if (response.status == 500) {
                    openToast("Internal server error", "images/cross - red.svg");
                }
            } catch (error) {
                await insertErrorApi({
                    error_message: error.toString(),
                    module_name: 'PreviewResponse',
                    function_name: 'handlePrevious',
                    service: 'Backend',
                    user_id: user_id
                });
                console.error(error);
            }
        } else {
            navigate('/Considerations', { state: { user_id, opportunityName, opportunity_id, jwtToken, resType, responseType } });
        }
    };


    // Extracts the file name from a given URL by splitting the URL string at each forward slash ('/') and returning the last part...PS_43 to 47
    const extractFileName = (url) => {
        console.log('url', url)
        const parts = url.split('/')
        console.log('parts', parts)
        return parts[parts.length - 1];
    };

    // Function to handle the click event
    //   Initiates the download of a file from a given blob URL. It first extracts the file name from the URL, creates a temporary anchor element, sets its download attribute, and triggers the click event to start the download process. If an error occurs, it logs the error to the console...PS_48 to 51
    const handleDownload = async () => {
        try {
            const fileName = extractFileName(blobUrl);
            console.log('file name', fileName)
            const link = document.createElement('a');
            console.log('link', link)
            link.href = blobUrl;
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    //   he openToast function displays a toast notification with a message and an image for a specified duration. It sets the toast message and image, opens the toast, and then closes it after 5 seconds.
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    //Shows the 50 character in the string
    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + '...';
        } else {
            return str;
        }
    };

    return (
        <>
            <div>
                {<Header user_id={user_id} jwtToken={jwtToken} moduleName={1} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
            </div>
            {loading ? (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 px-5 py-4">
                            {isToastOpen && (<div className="container-fluid">
                                <div className="d-flex justify-content-right align-items-end flex-column">
                                    <div className="px-2 mx-3 my-2 toast-container p-2">
                                        <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                            <span>
                                                <img
                                                    src={toastImage}
                                                    alt="tick-green-icon"
                                                    className="me-2 toast-img cursor-pointer"
                                                />
                                            </span>{" "}
                                            {toastMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>)}
                            { /* breadcrumb starts here */}
                            <ul className="breadcrumb-item mb-4 ps-0">
                                <li><a className="pe-0 font-13 primary-color font-medium">Opportunities</a></li>
                                <li className="pe-2 font-13 font-medium">{opportunityName}</li>
                            </ul>
                            { /* breadcrumb ends here */}
                            <div className="p-4 bg-white border-size-5 container-height">
                                <div className="row justify-content-center">
                                    <div className="col-md-12">
                                        <div className="d-flex gap-2 mb-5 align-items-center">
                                            <a className="cursor-pointer lh-1 pb-1" onClick={() => handleCancel()}>
                                                <img src="images/back-icon.svg" alt="Back Icon" onClick={() => handleCancel()} />
                                            </a>
                                            <h5 className="font-semibold font-22 m-0">{ResponseType}</h5>
                                        </div>
                                    </div>

                                    { /* stepper starts here */}
                                    <div className="col-md-6 mb-5">
                                        <div className="d-flex justify-content-between align-items-center">

                                            <div className="d-flex align-items-center flex-column gap-3">
                                                <div className="step-num completed d-flex justify-content-center align-items-center">
                                                    <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                </div>
                                                <span className="font-16 font-semibold step-text active"> Step 1 </span>
                                            </div>

                                            <div className="d-flex align-items-center flex-column gap-3 position-relative">
                                                <div className="step-num completed d-flex justify-content-center align-items-center font-medium font-18 step-line">
                                                    <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                </div>
                                                <span className="font-16 font-semibold step-text"> Step 2 </span>
                                            </div>

                                            <div className="d-flex align-items-center flex-column gap-3 position-relative">
                                                <div className="step-num active d-flex justify-content-center align-items-center font-medium font-18 step-line">
                                                    <p className="font-medium font-18 m-0">3</p>
                                                    <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                </div>
                                                <span className="font-16 font-semibold step-text"> Step 3 </span>
                                            </div>

                                        </div>
                                    </div>
                                    { /* stepper ends here */}

                                    { /* loader starts here */}
                                    <div className="col-md-12">
                                        <div className="text-center my-5 mt-5">
                                            <div className="card mb-4">
                                                <div className="percent">
                                                    <svg>
                                                        <circle cx="100" cy="100" r="75" />
                                                        <circle cx="100" cy="100" r="75" className={`pg-percentage`} style={{ '--percent': `${percentage}` }} />
                                                    </svg>
                                                    <div className="number">
                                                        <img src="images/document-generate-loader.svg" alt="loading-img" />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center font-18 font-medium color-black mb-2">Generating Document </p>
                                            <p className="text-center font-16 font-regular color-grey">Please wait while we processing your inputs</p>
                                        </div>
                                    </div>
                                    { /* loader ends here */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (

                <>

                    <div className="col-md-12 my-2">

                        <div class="modal fade" id="staticBackdrop21" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabIndex="-1" aria-labelledby="staticBackdropLabel2" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content border-size-5">
                                    <div className="modal-header border-0 px-4 pb-0">
                                        <h1 className="modal-title font-semibold color-black font-20" id="exampleModalLabel">
                                            Regenerate</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close" ></button>
                                    </div>
                                    <div className="modal-body px-4 pt-0">
                                        <div className="mt-3">
                                            <label htmlFor="generatedocument" className="pb-2 font-semibold font-14 color-black">What
                                                you wish to change in the generated document?</label>
                                            <textarea className="form-control form-textarea custom-form font-13 font-regular py-2"
                                                id="generatedocument"
                                                value={regenerateInput}
                                                onChange={(e) => setRegenerateInput(e.target.value)}
                                            ></textarea>

                                            <div className="modal-footer gap-2 text-end pt-3 px-0 border-0">
                                                <button type="button"
                                                    className="btn cancel-btn font-13 primary-color font-medium"
                                                    data-bs-dismiss="modal">Cancel</button>
                                                <button type="button"
                                                    className="btn submit-btn font-13 color-white font-medium"
                                                    data-bs-dismiss="modal"
                                                    onClick={handleRegenerateSubmit}
                                                    disabled={!regenerateInput.trim()}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 p-5">
                                { /* breadcrumb starts here */}
                                <ul className="breadcrumb-item mb-4 ps-0">
                                    <li><a className="pe-0 font-13 primary-color font-medium">Opportunities</a></li>
                                    <li className="pe-2 font-13 font-medium">{opportunityName}</li>
                                </ul>
                                { /* breadcrumb ends here */}
                                <div className="p-4 bg-white border-size-5">
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">
                                            <div className="d-flex gap-2 mb-5 align-items-center">
                                                <a className="cursor-pointer lh-1 pb-1" onClick={() => handleCancel()}>
                                                    <img src="images/back-icon.svg" alt="Back Icon" onClick={() => handleCancel()} />
                                                </a>
                                                <h5 className="font-semibold font-22 m-0">{ResponseType}</h5>
                                            </div>
                                        </div>

                                        { /* stepper starts here */}
                                        <div className="col-md-6 mb-5">
                                            <div className="d-flex justify-content-between align-items-center mb-5">

                                                <div className="d-flex align-items-center flex-column gap-3">
                                                    <div className="step-num completed d-flex justify-content-center align-items-center">
                                                        <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                    </div>
                                                    <span className="font-16 font-semibold step-text active"> Step 1 </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-column gap-3 position-relative">
                                                    <div className="step-num completed  d-flex justify-content-center align-items-center font-medium font-18 step-line">
                                                        <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                    </div>
                                                    <span className="font-16 font-semibold step-text"> Step 2 </span>
                                                </div>

                                                <div className="d-flex align-items-center flex-column gap-3 position-relative">
                                                    <div className="step-num completed  d-flex justify-content-center align-items-center font-medium font-18 step-line">
                                                        <img src="images/tick-white.svg" alt="completed-icon" className="tick-img" />
                                                    </div>
                                                    <span className="font-16 font-semibold step-text"> Step 3 </span>
                                                </div>

                                            </div>
                                        </div>
                                        { /* stepper ends here */}

                                        { /* table starts here */}
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-between mb-3">
                                                <h5
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="top"
                                                    title={opportunityName}
                                                    className="font-18 font-semibold primary-color m-0">
                                                    {truncateString(opportunityName, 110)}
                                                </h5>
                                                <div className="text-end">

                                                    <button
                                                        type="button"
                                                        className="btn submit-btn border-size-5 font-13 font-medium color-white me-2"

                                                        data-bs-toggle="modal"
                                                        data-bs-target="#staticBackdrop21"
                                                        disabled={userId == '' || userId == user_id ? false : true}
                                                    >{console.log('matchcccchhhh', userId, user_id)}
                                                        <img src="images/reload-icon.svg" alt="reload-icon" className="me-2"
                                                        />Regenerate</button>

                                                    <button type="button" className="btn primary-btn border-size-5 font-13 font-medium color-white" onClick={handleDownload}><img src="images/download-white.svg" alt="download-white" className="me-2" />Download</button>
                                                </div>
                                            </div>
                                            <div className="pt-1">
                                                <div className="d-flex justify-content-center py-0" style={{ backgroundColor: '#f0f0f0', padding: '20px' }}> {/* Replace #f0f0f0 with the actual gray color value */}
                                                    <div
                                                        style={{
                                                            resize: 'none',
                                                            height: '583px',
                                                            width: '50%', // Adjust the width as needed
                                                            border: '1px solid #dcdcdc', // Replace #dcdcdc with the actual color value
                                                            boxShadow: '0px 0.5px 2px 0px #dcdcdc', // Replace #dcdcdc with the actual color value
                                                            backgroundColor: '#fff', // Replace #fff with the actual background color value for the content
                                                            padding: '20px', // Adjust padding as needed
                                                            // overflowY: 'auto',
                                                            fontSize: '14px',
                                                        }}
                                                        className='audit-popup-scroll'
                                                        dangerouslySetInnerHTML={{ __html: content }}
                                                    />
                                                </div>


                                            </div>

                                            <div className="d-flex justify-content-between mt-4">
                                                <button type="button" className="btn secondary-outline-btn font-13 font-medium" onClick={handlePrevious}>Previous</button>
                                                <div className="text-end">
                                                    <button type="button" className="btn cancel-btn font-13 font-medium me-2" onClick={handleCancel}>Cancel</button>
                                                    <button type="button" className="btn submit-btn font-13 font-medium color-white" onClick={() => handleFinish()}>Finish</button>
                                                </div>
                                            </div>
                                        </div>
                                        { /* table ends here */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </>
            )}
        </>
    );
};

export default ResponsePreview;