import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDetailsApi, insertErrorApi } from '../../services/api';
import { decode } from 'base-64';

const Header = ({ user_id, jwtToken, moduleName, mail_Id, user_role, rfp_access, rfi_access }) => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [activeTab, setActiveTab] = useState(1); // Default active tab
    const location = useLocation()

    // Fetch user details on component load
    useEffect(() => {
        if (location.state == null) {
            navigate('/');
        }
        console.log('Received user_id:', user_id);
        console.log('Received jwtToken:', jwtToken);
        fetchPwd();
    }, [user_id, jwtToken, navigate]);

    const getDecodedItem = (key) => {
        try {
            const encodedValue = localStorage.getItem(key);
            if (!encodedValue) return null;
            
            // Extract the encoded part without random strings
            const encodedPart = encodedValue.substring(15, encodedValue.length - 10);
            
            // Decode the extracted part
            const decodedValue = decode(encodedPart);
            
            console.log(`Decoded value for key '${key}':`, decodedValue);
            
            return decodedValue;
        } catch (error) {
            console.error(`Error decoding value for key '${key}':`, error);
            return null;
        }
    };

    let adminval = getDecodedItem('5mMb2dpbg23Ly')?.toLowerCase()
    console.log(adminval, "adminnnnnnStatusss");

    const fetchPwd = async () => {
        try {
            const payload = {
                "tab": "manageuser",
                "value": "infogrid",
                "user_id": user_id
            }
            const response = await fetchDetailsApi(payload, jwtToken);
            console.log(response.data.infoGrid[0].user_name);
            if (response.success === true) {
                setUserName(response.data.infoGrid[0].user_name);
                setEmailAddress(response.data.infoGrid[0].email_id);
            } else if (response.status === 403) {
                navigate('/Login');
            } else if (response.status === 401 || 500) {
                console.log("Something went wrong", "images/cross - red.svg");
            }
        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Header.jsx",
                function_name: "fetchPwd",
                user_id: user_id,
            };
            insertErrorApi(payload);
        }
    };

    // Handler for navigation with state
    const navigates = (path) => {
        // Navigate to the new path and pass user_id and jwtToken as state
        navigate(path, { state: { user_id, jwtToken, moduleName, mail_Id, user_role, rfp_access, rfi_access } });
    };

    return (
        <nav className="navbar navbar-expand-lg cus-boxshadow p-54 w-100">
            <div className="cursor-pointer container-fluid p-0">
                <img src="/images/badsami-logo.svg" alt="badsami-logo"
                    onClick={() => { navigates('/opportunities', { state: { ...location.state } }); setActiveTab(() => 1) }}
                />
                <div className="collapse navbar-collapse mt-1 ms-3" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto d-flex align-items-center">
                        <li className="nav-item header-button">
                            <a
                                className={`cursor-pointer nav-link font-14 font-regular primary-color me-4 link-text ${moduleName == 1 ? 'active' : ''}`}
                                onClick={() => { navigates('/opportunities', { state: { ...location.state } }); setActiveTab(() => 1) }}
                            >
                                Opportunities
                            </a>
                        </li>
                        {adminval === "admin" ? (
                            <>
                                <li className="nav-item header-button">
                                    <a
                                        className={`cursor-pointer nav-link font-14 font-regular primary-color me-4 link-text ${moduleName == 2 ? 'active' : ''}`}
                                        onClick={() => { navigates('/configuration', { state: location.state }); setActiveTab(() => 2) }}
                                    >
                                        Configuration
                                    </a>
                                </li>
                                <li className="nav-item header-button">
                                    <a
                                        className={`cursor-pointer nav-link font-14 font-regular primary-color me-4 link-text ${moduleName == 3 ? 'active' : ''}`}
                                        onClick={() => { navigates('/manageUsers', { state: location.state }); setActiveTab(() => 3) }}
                                    >
                                        Manage Users
                                    </a>
                                </li>
                            </>
                        ) : null}
                    </ul>
                    <div className="d-flex flex-row align-items-center">
                        <div className="dropdown me-4">
                            <button className="cursor-pointer font-medium font-14 primary-color border-0 bg-transparent d-flex align-items-center px-0" data-bs-toggle="dropdown" aria-expanded="false" type="button">
                                {userName} <img src="/images/dropdown-arrow-dark.svg" alt="dropdown-arrow" className="ms-2 dropdown-arrow" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-end shadow-sm border-0 pb-0">
                                <div className="px-1 mb-5">
                                    <img src="/images/profile-cover-img.svg" alt="cover-img" />
                                </div>
                                <div className="position-relative">
                                    <img src="/images/profile-img.svg" alt="profile-img" className="user-profile" />
                                </div>
                                <p className="text-center m-0 font-14 font-medium color-black">{userName}</p>
                                <p className="text-center m-0 font-12 font-regular color-grey">{emailAddress}</p>
                                <p className="text-center">
                                    <a href="" className="font-12 font-medium link-color text-decoration-none" onClick={() => { navigates('/profile', { state: location.state }); setActiveTab(4) }}
                                    >
                                        Profile
                                    </a>
                                </p>
                                <div className="text-center profile-logout mx-1 mb-1 pt-1">
                                    <button className="font-regular font-13 border-0 logout-btn w-100 px-5 py-2" onClick={() =>{ 
                                    localStorage.clear()
                                    navigates('/')}}
                                    >
                                        <img src="/images/logout-icon.svg" alt="logout-icon" className="me-2" />Logout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Header;