import axios from 'axios';

export const client = async (urlendpoint, body, method,jwt='') => {
  try{
        // Set the headers object
    const headers = {
      // "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json" 
    };

    // Add Authorization header if jwtToken is present in the body
    if (jwt) {
      headers["Authorization"] = `Bearer ${jwt}`;
    }
    console.log(body,"client")
    // Make the request with axios
    const response = await axios({
      url: urlendpoint,
      method: method,
      data: body,
      headers: headers
    });

    return response.data;
  }
  catch (error) {
    console.log("error in client");

  }
}
