// PS_01,02
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { insertErrorApi, checkUserApi } from '../services/api'; 
import { encode, decode } from 'base-64';

// PS_03 to PS_6..import all the state variables 
const LogIN = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastImage, setToastImage] = useState("");
    const [jwtToken, setJwtToken] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [userId, setUserId] = useState('');
    const [userDetails, setUserDetails] = useState([]);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false)
    const [signinEnabled, setSigninEnabled]=useState(false)

    // `useEffect()` is triggered, calling `handleUser()` with empty dependencies...PS_07
    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
        handleUser();
    }, []);

    // PS_19,20
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/; // Adjust the regex as needed

    // PS_08
    // create a random function for random number generation while encodeing. And crerate setENcoding and setDecoding function for securing the jwtToken, mail, role, rfp, rfi and user_id. When using need to decode and need to use
    const random=(length)=>{
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          result += characters.charAt(randomIndex);
        }
        return result;
      }
    
      const setEncodedItem = (key, value) => {
        const encodedValue = random(15) + encode(value) + random(10);
        localStorage.setItem(key, encodedValue);
      };

      const getDecodedItem = (key) => {
        const encodedValue = localStorage.getItem(key);
        if (!encodedValue) return null;
        return decode(encodedValue.substring(15, encodedValue.length - 10));
      };

    // PC_23,24..When the user enters email id, handleEmailChange() arrow function is triggered and the email state is updated with the new value.Try to trim the email input and assign to newEmail, then setEmail to newEmail value.
    const handleEmailChange = (e) => {
        try {
          const email = e.target.value.trim();
          if (email !== "" && password!=="" ) {
            setSigninEnabled(true); // Enable login if both new password is not empty 
        } else {
            setSigninEnabled(false);
        }
            setEmail(email);
            setErrorMessage(''); // Clear error message when user starts typing
        } catch (error) {
          const payload = {
            service: "frontend",
            error_message: error.toString(),
            module_name: "LogIN.jsx",
            function_name: "handleEmailChange"
          };
          insertErrorApi(payload);
        }
    };
    
    // When the user enters a password, handlePasswordChange() arrow function is triggered and the password state is updated with the new value.Try to trim the password input and assign to newPassword, then setPassword to newPassword value..PS_26 to 28
    const handlePasswordChange = (e) => {
        try {
          const password = e.target.value.trim();
          if (email !== "" && password!=="" ) {
            setSigninEnabled(true); // Enable login if both new password is not empty 
        } else {
            setSigninEnabled(false);
        }
            setPassword(password);
            setErrorMessage(''); // Clear error message when user starts typing
        } catch (error) {
          const payload = {
            service: "frontend",
            error_message: error.toString(),
            module_name: "LogIN.jsx",
            function_name: "handlePasswordChange"
          };
          insertErrorApi(payload);
        }
    };

    // PS_26 to 28
    // handleUser() is used to trigger backend API to fetch the mail id along with the user id
    const handleUser=async()=>{
        try{
            setLoading(true)
            const result=await checkUserApi({"value":"User"})
            setLoading(false)
            console.log('result',result)

            const res=result.data
            console.log('resssssss',res)
            setUserDetails(res);
        }
        catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "LogIN.jsx",
                function_name: "handleUser"
            };
            insertErrorApi(payload);
            openToast("An error occurred during login. Please try again later.", "images/cross - red.svg");
        }
    }

    // PS_29 to 36
    // handle login() is used to 
    const handleLogin = async () => {
        try {

            // Returns errorr if email and password is not enetered
            if (!email.trim()) {
                setErrorMessage("Invalid email address or password. Please check your credentials and try again.","font-14 font-regular warning text-center mt-2")
                return;
            }
    
            if (!password.trim()) {
                setErrorMessage("Invalid email address or password. Please check your credentials and try again.","font-14 font-regular warning text-center mt-2")
                return;
            }

            // handling for invalid email, password, and API responses seems appropriate. You display error messages to the user when necessary.
            if (!emailRegex.test(email)) {
                setErrorMessage("Please enter a valid email address in the format user@example.com.","font-14 font-regular warning text-center mt-2")
                return;
            }
    
            if (!passwordRegex.test(password)) {
                setErrorMessage("Your password must contain a mix of uppercase and lowercase letters, numbers, and special characters. Please try again with a stronger password.","font-14 font-regular warning text-center mt-2")
                return;
            }

            // find the user ID based on the email entered. However, if the user is not found (userID === null), you display an error message but do not prevent the API call. Consider adding a return statement after setting the error message to prevent further execution.
            const user = userDetails.find(user => user.email_id.toLocaleLowerCase() == email.toLocaleLowerCase());
            const userID=user ? user.user_id : null
            const userRole = user ? user.user_role : null;
            const rfp_access=user?user.rfp_access:null;
            const rfi_access=user?user.rfi_access:null;

            setUserId(userID)
            console.log('user is',userID)
            
            if(userID===null){
                setErrorMessage("The email address or password you entered is incorrect. Please check your credentials and try again.","font-14 font-regular warning text-center mt-2")
                return
            }

            console.log('user_id', user ? user.user_id : null);
           
            const response = await checkUserApi({"email":email, "password":password, "user_id":userID, "value":"Login", "type":"createjwt"}, jwtToken);
            console.log('response',response)
            if (response.success === true) {
                const user_status = response.data.user_status;
                const jwtToken=response.jwtToken
                const user_id=response.data.user_id
             
                setJwtToken(response.jwtToken);
                
                setEncodedItem('Y2hlY2tMb2dpb', jwtToken);
                setEncodedItem('lY2tMb2dpbg23', user_id);
                setEncodedItem('2tMb2dpbg23Ly', email);
                setEncodedItem('5mMb2dpbg23Ly', userRole);
                setEncodedItem('2bTs2dpbg23Ly', rfp_access);
                setEncodedItem('m3Da2dpbg23Ly', rfi_access);
                

                let jwt_token=getDecodedItem('Y2hlY2tMb2dpb')
                let userID=getDecodedItem('lY2tMb2dpbg23')
                let mail_Id=getDecodedItem('2tMb2dpbg23Ly')
                let userROle=getDecodedItem('5mMb2dpbg23Ly')
                let rfpAccess=getDecodedItem('2bTs2dpbg23Ly')
                let rfiAccess=getDecodedItem('m3Da2dpbg23Ly')

                console.log('user isddddd',userID)
                console.log('jwt_token',jwt_token)

                // Clear email and password fields
                setEmail('');
                setPassword('');
                // After a successful login, you navigate the user to different routes based on their status. This logic seems fine, but make sure that the routes (/opportunities and /resetPassword) exist in your application.
                if (user_status.toLowerCase() == 'accepted') {
                    navigate('/opportunities', { state: { "jwtToken":jwt_token, "user_id":userID, "mail_Id":mail_Id, "user_role":userROle, "rfp_access":rfpAccess, "rfi_access":rfiAccess } });
                } 
                else if (user_status.toLowerCase() == 'invited') {
                    navigate('/resetPassword?status='+encode(user_status));
                }
            } else if (response.Message === "Password is incorrect") {
                openToast("The email address or password you entered is incorrect. Please check your credentials and try again.", "images/cross - red.svg");
            } else if (response.Status == 400) {
                openToast("Something went wrong", "images/cross - red.svg");
            } else if (response.message == 'Token Expired') {
                openToast("Token Expired", "images/cross - red.svg");
            } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                openToast("Unauthorized Access", "images/cross - red.svg");
            } else if (response.message == 'Token Invalid') {
                openToast("Invalid token", "images/cross - red.svg");
            } else if (response.status == 500) {
                openToast("Internal server error", "images/cross - red.svg");
            }
        } catch (error) {
            const payload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "LogIN.jsx",
                function_name: "handleLogin"
            };
            insertErrorApi(payload);
            openToast("An error occurred during login. Please try again later.", "images/cross - red.svg");
        }
    };

    // This function sets the toast message and image, opens the toast, and then closes it after 5000 milliseconds (5 seconds). It utilizes the setTimeout function to achieve the delay in closing the toast...PS_54
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);
    
        setTimeout(() => {
          setIsToastOpen(false);
          setToastImage("");
          setToastMessage("");
        }, 5000);
    }

    // This function toggles the visibility of the password field between visible and hidden. It flips the passwordVisible state using setPasswordVisible(!passwordVisible)...PS_56
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    console.log('tokennnnn',jwtToken)
    console.log('user isssssssdddd',userId)
    // This function navigates to the "forgot password" page using React Router's navigate function. It passes the jwtToken and userId as part of the route state...PS_57
    const navigateToForget = () => {
        localStorage.clear()
        navigate('/forgotpassword');
    };

    return (
        <>
            {loading ? (
                <div className="container-fluid p-0">
                    <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
                        <div className="wrapper text-center">
                            <div className="circle" />
                            <div className="circle" />
                            <div className="circle" />
                            <div className="shadow" />
                            <div className="shadow" />
                            <div className="shadow" />
                        </div>
                        <p className="font-24 font-regular color-white mt-3">Loading</p>
                    </div>
                </div>
            ) : (

                <>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <div className="login-content-height d-flex justify-content-center align-items-center flex-column">
                        {isToastOpen && (
                            <div className="container-fluid">
                                <div className="d-flex justify-content-right align-items-end flex-column">
                                    <div className="px-2 mx-3 my-2 toast-container p-2">
                                        <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                            <span>
                                                <img
                                                    src={toastImage}
                                                    alt="toast-icon"
                                                    className="me-2 toast-img cursor-pointer"
                                                />
                                            </span>{" "}
                                            {toastMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="text-center">
                            <img src="images/logo-img.svg" alt="logo" className="mb-5 pb-4" />
                        </div>
                        <div className="text-start w-50">
                            <div className="mb-5">
                                <h5 className="font-bold font-26 color-black" >Sign In</h5>
                                <p className="color-grey font-16 font-regular">Please enter your details to sign in.</p>
                            </div>
                            <div className="mb-4">
                                <label className="form-label font-16 font-semibold primary-color" htmlFor="EmailAddress">Email Address</label>
                                <input type="text" className="form-control custom-form font-14 font-regular" id="EmailAddress" placeholder="Enter Email Address" value={email} onChange={handleEmailChange}/>
                            </div>
                            <div className="mb-4 position-relative">
                                <label className="form-label font-16 font-semibold primary-color" htmlFor="Password">Password</label>
                                <input type={passwordVisible ? "text" : "password"}
                                className="form-control custom-form font-14 font-regular my-1 password-eye-hide pe-5" id="Password" 
                                placeholder="Enter Password" 
                                value={password} 
                                onChange={handlePasswordChange}/>
                                <div className="text-end">
                                    <a href="" className="text-decoration-none font-regular font-14 link-color" onClick={navigateToForget}>Forgot Password</a>
                                </div>
                                <img 
                                src={passwordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"} 
                                alt={passwordVisible ? "Hide password" : "Show password"} 
                                className="eye-pos cursor-pointer" 
                                onClick={togglePasswordVisibility} 
                            />
                            </div>
                            <div className="mb-3">
                                <button 
                                type="button" 
                                className="btn px-4 submit-btn font-16 color-white font-medium w-100" 
                                onClick={handleLogin}
                                disabled={!signinEnabled}
                                >Sign In</button>
                                <p className="font-14 font-regular warning text-center mt-2">
                                    {/* *Please provide valid credentials */}
                                    {errorMessage}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 p-0">
                    <div className="login-bg-height">
                        <div className="login-bg-img"></div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )}
    </>
    )
};

export default LogIN;