import React, { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postConfig, insertErrorApi } from '../../services/api';
import { uploadFileToBlob } from '../../azureBlobService';
import Loader from '../loader/Loader';

//PS_CP_60: Set up state variables `files`, `category`, and `uploads` with initial empty arrays or values when the Admin starts the data upload in RFP and RFI writings. Define functions `handleFileChange` to assign the selected files to state, and `handleCategoryChange` to set the selected category. The upload progress and document names are visualized for the user.
const Writings = () => {
    const [files, setFiles] = useState([]);
    const [category, setCategory] = useState('');
    const [searchBubbles, setSearchBubbles] = useState([]);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [loader, setLoader] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastImage, setToastImage] = useState('');
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location', location)
    const user_id = location.state.user_id
    const jwtToken = location.state.jwtToken

    //PS_CP_60: When the Admin begins the upload process for RFP and RFI writings, initialize state variables for files, category, and uploads to track the uploaded files, their respective category, and the overall uploads progress. Also define `handleFileChange` and `handleCategoryChange` functions to handle file and category changes respectively. The names of uploaded documents are then displayed to the user.
    const handleFileChange = (event) => {
        console.log("hellloooooo",event.target.files)
        const selectedFiles = Array.from(event.target.files);
        const newFiles = [...files];
        const newFileNames = [];

        selectedFiles.forEach((file) => {
            if (file.size / 1024 / 1024 <= 5) {
                console.log("coreeeeeeeeeeeeeeeeeeeeeeeeeeeeeeect",file)
                newFiles.push(file);
                console.log("888888888888888",newFiles)
                newFileNames.push(file.name);
            } else {
                console.log("not correeeeeeeeeeeeeect",file)
                openToast(`File "${file.name}" exceeds the 5 MB limit`, "images/cross - red.svg");
            }
        });
        event.target.value=''
        setFiles(newFiles);
        console.log(files, "fileeeeee")
        handleAddFileBubble(newFileNames);

        if (category && newFiles.length > 0) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };
    const handleCategoryChange = (event) => {
        const selectedCategory = event.target.value;
        setCategory(selectedCategory);

        if (selectedCategory && files.length > 0) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    //PS_CP_61: Define `handleAddfileBubble` function which handles the event when a file is selected for upload by the Admin. The function updates the `searchBubbles` state to include the name of the uploaded file and reset the file input for future uploads. Display the file name as bubble text to the user. If an error occurs, display an error message.
    const handleAddFileBubble = (newFileNames) => {
        setSearchBubbles((prevBubbles) => [...prevBubbles, ...newFileNames]);

        if (category && newFileNames.length > 0) {
            setIsSaveDisabled(false);
        } else {
            setIsSaveDisabled(true);
        }
    };

    //PS_CP_62: This function handles the deletion of a specific file bubble from the file upload form. It updates the state to remove the selected file and its associated bubble. If all files are removed, it disables the save button.
    const handleDeleteFileBubble = (index) => {
        const updatedBubbles = [...searchBubbles];
        updatedBubbles.splice(index, 1);
        setSearchBubbles(updatedBubbles);

        const updatedFiles = files.filter((file, i) => i !== index);
        setFiles(updatedFiles);

        if (updatedFiles.length === 0) {
            setIsSaveDisabled(true);
        }
    };

    //PS_CP_63: This function handles the submission of the file upload form. It uploads each file to a cloud storage (or a similar service), collects the uploaded file links, and sends them to a server as part of a payload. It manages loader visibility, error handling, and provides user feedback via toast notifications.
    const handleSubmit = async () => {
        try {
            console.log(files, "klkll")
            setLoader(() => true)
            const fileUploads = files.map((file) => uploadFileToBlob(file, category));
            const uploadsData = await Promise.all(fileUploads);

            const payload = {
                value: "writtings",
                category: category,
                blobLink: uploadsData,
                user_id: user_id,
            };

            const response = await postConfig(payload, jwtToken);
            setLoader(() => false)
            console.log("loader:", loader);
            console.log("responseeeeeeeeeeeeeeeeee", response.message);
            if (response.message == "Documents successfully inserted") {
                console.log("In Success Upload file");
                openToast("File Uploaded Successfully", "images/tick - green.svg");
                handleCancelUpload();
            } else if (response.status === 403) {
                openToast("Token Expired", "images/cross-red.svg");
                // navigate('/Login');
            } else if (response.status === 401 || 500) {
                openToast("Something went wrong", "images/cross - red.svg");
            }
            handleCancelUpload();
        } catch (error) {
            setLoader(() => false)
            const errorPayload = {
                service: "frontend",
                error_message: error.toString(),
                module_name: "Writtings.jsx",
                function_name: "handleSubmit",
                user_id: user_id,
            };
            insertErrorApi(errorPayload);
            handleCancelUpload();
        }
    };

    //PS_CP_64: This function cancels the file upload process by clearing all the related states, including the list of files to be uploaded, the selected category, and the search bubbles. It also disables the save button.
    const handleCancelUpload = () => {
        setFiles([]);
        setCategory('');
        setSearchBubbles([]);
        setIsSaveDisabled(true);
    };

    // Handle "browse" click anchor tag
    const handleBrowseClick = (event) => {
        fileInputRef.current.click();
    };

    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <div className="tab-pane fade-show active" id="RFI-RFP-writings" role="tabpanel">
                    <h2 className="font-semibold font-20">Category</h2>

                    {isToastOpen && (
                        <div className="container-fluid">
                            <div className="d-flex justify-content-right align-items-end flex-column">
                                <div className="px-2 mx-3 my-2 toast-container p-2">
                                    <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                        <span>
                                            <img
                                                src={toastImage}
                                                alt="toast-icon"
                                                className="me-2 toast-img cursor-pointer"
                                            />
                                        </span>
                                        {toastMessage}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="d-flex gap-4 mt-3">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input custom-radio-input"
                                type="radio"
                                name="category"
                                id="wonWritings"
                                value="Won_Writtings"
                                checked={category === 'Won_Writtings'}
                                onChange={handleCategoryChange}
                            />
                            <label className="form-check-label font-16 font-medium" htmlFor="wonWritings">
                                Won Writings
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input custom-radio-input"
                                type="radio"
                                name="category"
                                id="otherWritings"
                                value="Other_Writtings"
                                checked={category === 'Other_Writtings'}
                                onChange={handleCategoryChange}
                            />
                            <label className="form-check-label font-16 font-medium" htmlFor="otherWritings">
                                Other Writings
                            </label>
                        </div>
                    </div>

                    <h4 className="font-20 font-semibold mt-4">Upload</h4>

                    <div className="mt-3">
                        <span className="upload-btn-wrapper d-block">
                            <button
                                type="button"
                                className="upload-btn cursor-pointer py-5 font-14 border-size-5"
                            >
                                <img src="images/upload-large.svg" alt="Upload" className="mb-2" />
                                <p className="font-regular font-14 m-0">
                                    Drag and drop PDF files here or{" "}
                                    <a  className="text-decoration-none link-color" onClick={handleBrowseClick}>
                                        browse
                                    </a>
                                </p>
                            </button>
                            <input
                                type="file"
                                name="myfile"
                                className="cursor-pointer cust-file"
                                id="Upload"
                                multiple
                                accept=".pdf"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </span>

                        <div>
                            {searchBubbles.map((bubble, index) => (
                                <div key={index} className="d-flex align-items-center gap-3 mt-3">
                                    <span className="d-inline-block align-items-center green-bg gap-3 p-2 px-3 rounded-3">
                                        <span className="me-4 font-14 font-semibold">{bubble}</span>
                                        <img
                                            className="cursor-pointer"
                                            src="./images/cross-icon.svg"
                                            onClick={() => handleDeleteFileBubble(index)}
                                            alt="Delete"
                                        />
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="col-md-12 mt-5">
                            <div className="d-flex justify-content-end align-items-center py-3">
                                <button
                                    className="btn cancel-btn me-3 px-4 font-regular primary-color"
                                    onClick={handleCancelUpload}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn submit-btn color-white px-4 font-regular"
                                    onClick={handleSubmit}
                                    disabled={isSaveDisabled}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

};

export default Writings;