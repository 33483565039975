import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "../header/Header";
import BasicSearch from './BasicSearch';
import Writings from './Writings';
import Scoring from './Scoring';

const Configuration = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location', location.state)

    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
    }, [location, navigate]);

    const user_id = location.state?.user_id
    const jwtToken = location.state?.jwtToken
    const mail_Id = location.state?.mail_Id
    const user_role = location.state?.user_role
    const rfp = location.state?.rfp_access
    const rfi = location.state?.rfi_access
    const [tab, setTab] = useState(1)



    return (
        <>
            <div>
            {<Header user_id={user_id} jwtToken={jwtToken} moduleName={2} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi}/>}
            </div>

            <div className="p-5 body-bg">
                <div className="bg-white rounded-3 p-4">

                    <h2 className="font-bold font-22">Configuration</h2>
                    <div className="tab-scroll d-flex mt-5 mb-3">
                        <ul
                            className="nav nav-pills mb-4 d-flex gap-3"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li
                                className="nav-item custom-bg rounded-start position-relative"
                                role="presentation"
                            >
                                <button
                                    className="nav-link theme-tab active cus-tab-border bg-white font-16 color-grey  p-0 pe-2 font-medium"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Basic-Search"
                                    type="button"
                                    role="tab"
                                    aria-selected="true" onClick={() => setTab(() => 1)}
                                >
                                    Basic Search
                                </button>
                            </li>
                            <li
                                className="nav-item custom-bg position-relative"
                                role="presentation"
                            >
                                <button
                                    className="nav-link theme-tab cus-tab-border bg-white font-16 color-grey  p-0 px-2 font-medium"
                                    data-bs-toggle="pill"
                                    data-bs-target="#RFI-RFP-writings"
                                    type="button"
                                    role="tab"
                                    aria-selected="false" onClick={() => setTab(() => 2)}
                                >
                                    RFI & RFP Writings
                                </button>
                            </li>
                            <li
                                className="nav-item custom-bg position-relative"
                                role="presentation"
                            >
                                <button
                                    className="nav-link theme-tab cus-tab-border bg-white font-16 color-grey p-0 px-2 font-medium"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Scoring"
                                    type="button"
                                    role="tab"
                                    aria-selected="false" onClick={() => setTab(() => 3)}
                                >
                                    Scoring
                                </button>
                            </li>
                        </ul>
                    </div>

                    {/*Tab Inner starts here*/}
                    <div className="tab-content" id="pills-tabContent">
                        {tab == 1 ? < BasicSearch user_id={user_id} jwtToken={jwtToken} /> :
                            tab == 2 ? <Writings user_id={user_id} jwtToken={jwtToken} /> :
                                tab == 3 ? <Scoring user_id={user_id} jwtToken={jwtToken} /> : <></>}
                    </div>
                    {/*Tab Inner ends here*/}
                </div>
            </div>
        </>
    )
}
export default Configuration;