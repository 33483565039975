//PS_PF_05
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from "./header/Header";
import { fetchDetailsApi, resetPasswordApi, insertErrorApi } from '../services/api';
import Loader from './loader/Loader';

//PS_PF_11: The Profile component contains various functions designed to handle user interactions related to profile and password management. Below are the functional descriptions for each function in the component.
const Profile = () => {
    //PS_PF_13: Create a state variable 
    const [userName, setUserName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [role, setRole] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loader, setLoader] = useState(false);
    const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false)
    const [newPasswordVisible, setNewPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const [toastImage, setToastImage] = useState("");

    const location = useLocation();
    console.log('location', location)
    const user_id = location.state?.user_id
    const jwtToken = location.state?.jwtToken
    const mail_Id = location.state?.mail_Id
    const user_role = location.state?.user_role
    const rfp = location.state?.rfp_access
    const rfi = location.state?.rfi_access

    const navigate = useNavigate();

    //PS_PF_20
    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
        //PS_PF_21: The fetchPwd function is responsible for fetching user details from an API, handling various response scenarios, and updating the application state accordingly. It also manages error reporting in case of failures.
        const fetchPwd = async () => {
            //PS_PF_24
            try {
                const payload = {
                    "tab": "manageuser",
                    "value": "infogrid",
                    "user_id": user_id
                }
                setLoader(true)
                const response = await fetchDetailsApi(payload, jwtToken);
                setLoader(false)
                console.log(response.data.infoGrid[0].user_name);
                if (response.success === true) {
                    console.log(response);
                    setUserName(response.data.infoGrid[0].user_name);
                    setEmailAddress(response.data.infoGrid[0].email_id);
                    setRole(response.data.infoGrid[0].user_role);
                } else if (response.message == 'Token Expired') {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                    openToast("Unauthorized Access", "images/cross - red.svg");
                } else if (response.message == 'Token Invalid') {
                    openToast("Invalid token", "images/cross - red.svg");
                } else if (response.status == 500) {
                    openToast("Internal server error", "images/cross - red.svg");
                }
            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "ForgotPassword.jsx",
                    function_name: "handleSendEmail",
                    user_id: user_id,
                };
                insertErrorApi(payload);
            }
        };
        console.log(currentPassword, "wes");
        fetchPwd();
    }, []);

    //PS_FP_38: The openToast function is designed to display a toast notification with a custom message and image. It sets the toast to be visible for a brief duration before automatically hiding it.
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    //PS_PF_46: The handleSubmitClick function is responsible for handling the submission of a password reset request. It validates the new password against the current password and makes an API call to reset the password. The function provides feedback to the user through toast notifications based on the outcome of the password reset process.
    const handleSubmitClick = async () => {
        console.log(currentPassword, "ppppp");

        if (currentPassword === newPassword) {
            console.log('Passwords do not match!');
            openToast("Previous Password Used", "images/cross - red.svg");
            return;
        }
        else {
            try {
                const payload = {
                    "user_id": user_id,
                    "type": "R",
                    "mail_id": emailAddress,
                    "curnt_password": currentPassword,
                    "new_password": newPassword,
                    "confirm_password": confirmNewPassword
                };
                const response = await resetPasswordApi(payload, jwtToken);
                console.log(response);
                if (response.success === true) {
                    openToast("Password Reset Succesfully", "images/tick - green.svg");
                    setNewPassword('')
                    setCurrentPassword('')
                    setConfirmNewPassword('')
                } else if (response.message === "current password mismatch"){
                    openToast("Enter Valid Current Password", "images/cross - red.svg");
                } else if (response.message === "Cannot enter the same password to reset") {
                    openToast("Previous Password Used", "images/cross - red.svg");
                } else if (response.message === "Password mismatch") {
                    openToast("Passwords Do Not Match", "images/cross - red.svg");
                } else if (response.message === "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.") {
                    openToast("Password Criteria Not Met", "images/cross - red.svg");
                } else if (response.message == 'Token Expired') {
                    openToast("Token Expired", "images/cross - red.svg");
                } else if (response.message == 'User_Id mismatch between JWT token and request body') {
                    openToast("Unauthorized Access", "images/cross - red.svg");
                } else if (response.message == 'Token Invalid') {
                    openToast("Invalid token", "images/cross - red.svg");
                } else if (response.status == 500) {
                    openToast("Internal server error", "images/cross - red.svg");
                }

            }
            catch (error) {
                console.log("error");
            }
        }
    };

    //PS_PF_64: Hide confirm password if new password is shown and Hide confirm password if new password is shown and  // Hide new password if confirm password is shown
    const toogleCurrentPasswordVisiblity = () => {
        setCurrentPasswordVisible(!currentPasswordVisible);
        setConfirmPasswordVisible(false);
    };
    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
        setConfirmPasswordVisible(false);
    };
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
        setNewPasswordVisible(false);
    };

    //PS_PF_67: The handleCancelClick function is designed to clear the password input fields in a password reset form. When invoked, it resets the newPassword, currentPassword, and confirmNewPassword states to empty strings, effectively clearing any input that the user has entered.
    const handleCancelClick = () => {
        setNewPassword('');
        setCurrentPassword('')
        setConfirmNewPassword('');
    };
    console.log(currentPassword, "wes");

    const areAllFieldsFilled = () => {
        return currentPassword && newPassword && confirmNewPassword;
    };

    return (
        <>
            <div>
                {<Header user_id={user_id} jwtToken={jwtToken} moduleName={4} mail_Id={mail_Id} user_role={user_role} rfp_access={rfp} rfi_access={rfi} />}
            </div>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="container-fluid p-5">
                        {isToastOpen && (<div className="container-fluid">
                            <div className="d-flex justify-content-right align-items-end flex-column">
                                <div className="px-2 mx-3 my-2 toast-container p-2">
                                    <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                        <span>
                                            <img
                                                src={toastImage}
                                                alt="tick-green-icon"
                                                className="me-2 toast-img cursor-pointer"
                                            />
                                        </span>{" "}
                                        {toastMessage}
                                    </p>
                                </div>
                            </div>
                        </div>)}
                        <div className="row bg-white container-height border-size-5">
                            <div className="col-md-12 p-4">
                                <h5 className="font-22 font-semibold primary-color mt-3">Profile</h5>
                                <div className="tab-scroll mt-4 mb-3">
                                    <ul className="nav nav-pills mb-4 d-flex gap-3" id="pills-tab" role="tablist">
                                        <li className="nav-item custom-bg rounded-start" role="presentation">
                                            <button
                                                className="nav-link theme-tab active position-relative cus-tab-border bg-white font-16 color-grey font-regular"
                                                data-bs-toggle="pill"
                                                data-bs-target="#Account-Information"
                                                type="button"
                                                role="tab"
                                                aria-selected="true"
                                            >
                                                Account Information
                                            </button>
                                        </li>
                                        <li className="nav-item custom-bg" role="presentation">
                                            <button
                                                className="nav-link theme-tab position-relative cus-tab-border bg-white font-16 color-grey font-regular"
                                                data-bs-toggle="pill"
                                                data-bs-target="#Password-Reset"
                                                type="button"
                                                role="tab"
                                                aria-selected="false"
                                            >
                                                Password Reset
                                            </button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active pt-4"
                                            id="Account-Information"
                                            role="tabpanel"
                                            aria-labelledby="Account-Information"
                                        >
                                            <div className="row">
                                                <div className="col-md-4 px-3 mb-4">
                                                    <label htmlFor="username" className="form-label font-14 font-semibold primary-color">
                                                        Username
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control cust-input-read custom-form color-black border-0"
                                                        id="username"
                                                        value={userName}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-4 px-3 mb-4">
                                                    <label htmlFor="email" className="form-label font-14 font-semibold primary-color">
                                                        Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control cust-input-read custom-form font-13 font-regular color-black border-0"
                                                        id="email"
                                                        value={emailAddress}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-4 px-3 mb-4">
                                                    <label htmlFor="role" className="form-label font-14 font-semibold primary-color">
                                                        Role
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control cust-input-read custom-form color-black border-0"
                                                        id="role"
                                                        value={role}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade pt-4"
                                            id="Password-Reset"
                                            role="tabpanel"
                                            aria-labelledby="Password-Reset"
                                        >
                                            <div className="row">
                                                <div className="col-md-4 px-3 mb-4">
                                                    <label htmlFor="email" className="form-label font-14 font-semibold primary-color">
                                                        Email Address
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control cust-input-read custom-form color-black border-0"
                                                        id="email"
                                                        value={emailAddress}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="col-md-4 px-3 mb-4 position-relative">
                                                    <label htmlFor="currentPassword" className="form-label font-14 font-semibold primary-color">
                                                        Current Password
                                                    </label>
                                                    <input
                                                        type={currentPasswordVisible ? "text" : "password"}
                                                        className="form-control cust-input-read custom-form color-black border-0 pe-5 password-eye-hide"
                                                        id="currentPassword"
                                                        value={currentPassword}
                                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                                        placeholder="Enter Current Password"
                                                    />
                                                    <img
                                                        src={currentPasswordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"}
                                                        alt={currentPasswordVisible ? "Hide password" : "Show password"}
                                                        className="eye-position cursor-pointer"
                                                        onClick={toogleCurrentPasswordVisiblity}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-3 mb-4 position-relative">
                                                    <label htmlFor="newPassword" className="form-label font-14 font-semibold primary-color">
                                                        New Password
                                                    </label>
                                                    <input
                                                        type={newPasswordVisible ? "text" : "password"}
                                                        className="form-control cust-input custom-form color-black password-eye-hide pe-5"
                                                        id="newPassword"
                                                        value={newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                        placeholder="Enter New Password"
                                                    />
                                                    <img
                                                        src={newPasswordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"}
                                                        alt={newPasswordVisible ? "Hide password" : "Show password"}
                                                        className="eye-position cursor-pointer"
                                                        onClick={toggleNewPasswordVisibility}
                                                    />
                                                </div>
                                                <div className="col-md-4 px-3 mb-4 position-relative">
                                                    <label htmlFor="confirmNewPassword" className="form-label font-14 font-semibold primary-color">
                                                        Confirm Password
                                                    </label>
                                                    <input
                                                        type={confirmPasswordVisible ? "text" : "password"}
                                                        className="form-control cust-input custom-form color-black password-eye-hide pe-5"
                                                        id="confirmNewPassword"
                                                        value={confirmNewPassword}
                                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                        placeholder="Confirm Password"
                                                    />
                                                    <img
                                                        src={confirmPasswordVisible ? "images/eye-open-icon.svg" : "images/password-eye-close.svg"}
                                                        alt={confirmPasswordVisible ? "Hide password" : "Show password"}
                                                        className="eye-position cursor-pointer"
                                                        onClick={toggleConfirmPasswordVisibility}
                                                    />
                                                </div>
                                                <div className="col-md-12 mt-5">
                                                    <div className="d-flex justify-content-end align-items-center py-3">

                                                        <button
                                                            type="button"
                                                            className="btn cancel-btn me-3 px-4 font-regular primary-color"
                                                            onClick={handleCancelClick}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn submit-btn color-white px-4 font-regular"
                                                            onClick={handleSubmitClick}
                                                            disabled={!areAllFieldsFilled()}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Profile;