import React from 'react';
import { useNavigate } from "react-router-dom";



function NotFoundPage() {
    const navigate = useNavigate();

    const navigateToHomepage = () => {
        navigate('/');
    };

    return (
        <div className="container-fluid bg-white vh-100">
            <div className="row">
                <div className="col-md-12 flex-column d-flex align-items-center justify-content-center vh-100">
                    <img src="images/page-not-found.svg" alt="page-not-found-img" className="error-img" />
                    <p className="font-48 font-semibold primary-color mt-4">Oops! Page not found</p>
                    <p className="font-24 font-regular color-grey mb-4">Sorry, but the page you were looking for could not be found.</p>
                    <button type="button" className="btn submit-btn font-20 color-white font-medium" onClick={navigateToHomepage}> Back to Homepage</button>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;