import React from 'react';

const Loader = () => {
    return (
        <div className="container-fluid p-0">
            <div className="container-backdrop d-flex align-items-center justify-content-center flex-column w-100">
                <LoaderWrapper />
                <LoadingText />
            </div>
        </div>
    );
}

const LoaderWrapper = () => {
    return (
        <div className="wrapper text-center">
            <LoaderCircle />
            <LoaderCircle />
            <LoaderCircle />
            <LoaderShadow />
            <LoaderShadow />
            <LoaderShadow />
        </div>
    );
};

const LoaderCircle = () => {
    return <div className="circle"></div>;
}

const LoaderShadow = () => {
    return <div className="shadow"></div>;
}

const LoadingText = () => {
    return <p className="font-24 font-regular color-white mt-3">Loading</p>;
}

export default Loader;