import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ForgotPassword from '../components/ForgotPassword.jsx';
import NotFoundPage from '../components/notFound/NotFoundPage.jsx';
import Login from '../components/LogIN.jsx';
import Profile from '../components/Profile.jsx';
import ManageUser from '../components/ManageUser.jsx';
import Configuration from '../components/Configuration/Configuration.jsx';
import Opportunities from '../components/Opportunities.jsx';
import ResetPassword from '../components/ResetPassword.jsx'
import UploadDocument from '../components/UploadDocument.jsx'
import Consideration from '../components/Consideration.jsx'
import ResponsePreview from '../components/ResponsePreview.jsx';


const RoutesComponent = () => {
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname === '/') {
      localStorage.clear();
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/configuration" element={<Configuration />} />
        <Route path="/manageUsers" element={<ManageUser />} />
        <Route path="/Considerations" element={<Consideration />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/ResponsePreview" element={<ResponsePreview />} />
        <Route path="/UploadDocument" element={<UploadDocument />} />
        <Route path="/" element={<Login />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default RoutesComponent;