//PS_FP_03: Import useState from react                                                       
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { encode, decode } from 'base-64';
//PS_FP_04: Import resetPasswordApi, insertErrorApi from api.js file                                                
import { insertErrorApi, resetPasswordApi } from '../services/api';


//PS_FP_06: Create state variable email with set state function              
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [PasswordEnabled, setPasswordEnabled] = useState(false);
  const [clicks, setClicks] = useState([]);
  const maxClicks = 5; // Maximum allowed clicks within the timeframe
  const timeframe = 60 * 1000; // 1 minute timeframe
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastImage, setToastImage] = useState("");
  const [message, setMessage] = useState('');

  const random=(length)=>{
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  const setEncodedItem = (key, value) => {
    const encodedValue = random(15) + encode(value) + random(10);
    localStorage.setItem(key, encodedValue);
  };

  const getDecodedItem = (key) => {
    const encodedValue = localStorage.getItem(key);
    if (!encodedValue) return null;
    return decode(encodedValue.substring(15, encodedValue.length - 10));
  };
  
  let userId=getDecodedItem('lY2tMb2dpbg23')
  const handleEmailChange = (e) => {
    try {
      console.log(toastImage);
      console.log(e.target.value, "entered email address");
      const newEmail = e.target.value.trim()
      const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
      if (newEmail !== null && newEmail !== "" && emailRegex.test(newEmail)) {
        setPasswordEnabled(true);
      }
      else {
        setPasswordEnabled(false);
      }
      setEmail(newEmail);
    }
    catch (error) {
      const payload = {
        service: "frontend",
        error_message: error.toString(),
        module_name: "ForgotPassword.jsx",
        function_name: "handleEmailChange",
        user_id: userId,
      };
      insertErrorApi(payload);
    }
  };

  
  
  // PS_FP_20: User clicks Send Email button after entering email                                                       
  const handleSendEmail = async () => {
    //PS_FP_21: Create currentTime variable, value as new Date().getTime()                                               
    const currentTime = new Date().getTime();
    const filteredClicks = clicks.filter(click => currentTime - click < timeframe);
    console.log(filteredClicks);

    if (filteredClicks.length < maxClicks) {
      setClicks([...filteredClicks, currentTime]);
      // PS_FP_24
      let userID;
      try {
        //PS_FP_26
        if (PasswordEnabled) {
          setPasswordEnabled(true)
          const response = await resetPasswordApi({ "mail_id": email, type: 'F'});
          if (response.success === true) {
            console.log("Email Sent Successfully", response.success === true)
            const jwtToken=response.data.jwtToken
            const user_id=response.data.user_id
            setEncodedItem('Y2hlY2tMb2dpb', jwtToken);
            setEncodedItem('lY2tMb2dpbg23', user_id);
            setEncodedItem('2tMb2dpbg23Ly', email);
            let jwt_token=getDecodedItem('Y2hlY2tMb2dpb')
            userID=getDecodedItem('lY2tMb2dpbg23')
            openToast("Email Sent Succesfully", "images/tick - green.svg");
          } else if (response.message === "Mail id not found") {
            openToast("Email Not Found", "images/cross - red.svg");
          } else if (response.message == 'Token Expired') {
            openToast("Token Expired", "images/cross - red.svg");
          } else if (response.message == 'User_Id mismatch between JWT token and request body') {
              openToast("Unauthorized Access", "images/cross - red.svg");
          } else if (response.message == 'Token Invalid') {
              openToast("Invalid token", "images/cross - red.svg");
          } else if (response.status == 500) {
              openToast("Internal server error", "images/cross - red.svg");
          }
          // PS_FP_27
        } else {
          setPasswordEnabled(false)
        }
        // PS_FP_29
      } catch (error) {
        const payload = {
          service: "frontend",
          error_message: error.toString(),
          module_name: "ForgotPassword.jsx",
          function_name: "handleSendEmail",
          user_id: userID,
        };
        insertErrorApi(payload);
      } finally {
        setEmail(''); // Clear the email field
        setPasswordEnabled(false); // Reset the password enabled state
      }
    } else {
      setMessage('Max clickable reached');
      setTimeout(() => {
        setMessage('');
        setEmail('');
      }, 60 * 1000);
    }
  };

  function openToast(message, image) {
    setIsToastOpen(true);
    setToastImage(image);
    setToastMessage(message);

    setTimeout(() => {
      setIsToastOpen(false);
      setToastImage("");
      setToastMessage("");
    }, 5000);
  }
  // PS_FP_57
  const navigateToLogin = () => {
    navigate('/');
  };


  return (
    <div className="container-fluid">
      <div className="row">
        {/* Left container */}
        <div className="col-md-6">
          <div className="login-content-height d-flex justify-content-center align-items-center flex-column">

            {isToastOpen && (<div className="container-fluid">
              <div className="d-flex justify-content-right align-items-end flex-column">
                <div className="px-2 mx-3 my-2 toast-container p-2">
                  <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                    <span>
                      <img
                        src={toastImage}
                        alt="tick-green-icon"
                        className="me-2 toast-img cursor-pointer"
                      />
                    </span>{" "}
                    {toastMessage}
                  </p>
                </div>
              </div>
            </div>)}

            <div className="text-center">
              {/* Add your logo image here */}
              <img src="images/logo-img.svg" alt="logo-img" className="mb-5 pb-4" />
            </div>

            <div className="text-start w-50">
              <div className="mb-5">
                <h5 className="font-bold font-26 color-black">Forgot Password</h5>
                <p className="color-grey font-16 font-regular">
                  Please enter your registered email address to reset password.
                </p>
              </div>

              <div className="mb-5">
                <label className="form-label font-16 font-semibold primary-color" htmlFor="EmailAddress">
                  Email Address
                </label>

                <input
                  type="text"
                  className="form-control custom-form font-14 font-regular py-2"
                  id="EmailAddress"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              {message && (
                <span
                  style={{
                    color: 'red',
                    textAlign: 'center',
                    display: 'block',
                    margin: '1rem 0',
                  }}
                >
                  {message}
                </span>
              )}
              <div className="mb-3">
                <button
                  type="button"
                  onClick={handleSendEmail}
                  className="btn px-4 submit-btn font-16 color-white font-medium w-100 mb-3"
                  disabled={!PasswordEnabled || isToastOpen || message}
                >
                  Send Email
                </button>
                <button type="button" onClick={navigateToLogin} className="btn px-4 outline-btn font-16 primary-color font-medium w-100 mb-3">
                  Back to Sign In
                </button>
              </div>

            </div>



          </div>
        </div>

        {/* Right container */}
        <div className="col-md-6 p-0">
          {/* Add your background image or styling here */}
          <div className="login-bg-height">
            <div className="login-bg-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;