import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './routes/RouteComponent';
import TimeoutModal from './components/timoutmodal/TimeoutModal'

function App() {
  console.log = () => {};
  console.error = () => {};
  return (
    <Router>
      <div className="App">
        <RoutesComponent /> 
        <TimeoutModal />
      </div>
    </Router>
  );
}
export default App;