//PS_CP_02
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchConfigApi, postConfig, insertErrorApi } from '../../services/api';
import Loader from '../loader/Loader';

//PS_CP_06
//PS_CP_07
//PS_CP_08
const BasicSearch = () => {
    const [fetchedData, setFetchedData] = useState({
        businessUnits: [],
        sites: [],
        bubbles: [],
    });

    const [workingData, setWorkingData] = useState({
        businessUnits: [],
        sites: [],
        bubbles: [],
        bubble: [],
        site: ''
    });

    const [backupData, setBackupData] = useState({
        businessUnits: [],
        sites: [],
        bubbles: [],
        site: ''
    });
    console.log("heyy")
    const navigate = useNavigate();

    const [selectedSite, setSelectedSite] = useState('');
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState('');
    const [selectedBubble, setSelectedBubble] = useState('');

    const [modalType, setModalType] = useState('');
    const [currentItem, setCurrentItem] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [newBubble, setNewBubble] = useState('');
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastImage, setToastImage] = useState('');
    const [itemToDelete, setItemToDelete] = useState(null);
    const [update, setUpdate] = useState(null)
    const [action, setAction] = useState(null)
    const [loader, setLoader] = useState(false);
    const inputRef = useRef(null); // Define inputRef using useRef

    const location = useLocation();
    console.log('location', location)

    useEffect(() => {
        if (location.state == null) {
            navigate('/', { state: null });
        }
    }, [location, navigate]);

    const user_id = location.state?.user_id
    const jwtToken = location.state?.jwtToken

    let allowedSites = {
        'sam.gov': '391F0D6C-2F19-4BD1-AB2D-0E8B3D64A1CF',
        'govtribe': 'A1374E18-C657-4DEA-909B-2E88D3465A9C',
        'govwin': '5213E526-5A49-45CF-840E-23A28FEC7285'
    };

    //PS_CP_11
    const fetchConfigData = async () => {
        try {
            const payload = {
                user_id: user_id,
                click_type: 'basicsearch',
            };
            setLoader(true);
            // 
            //PS_CP_12
            const responseData = await fetchConfigApi(payload, jwtToken);
            setLoader(false);
            if (responseData.success === true) {
                const mappedData = responseData.data.data;

                // Extract unique sites
                const uniqueSitesMap = new Map();
                mappedData.forEach(item => {
                    if (!uniqueSitesMap.has(item.website_id)) {
                        uniqueSitesMap.set(item.website_id, {
                            id: item.website_id,
                            name: item.website_name,
                        });
                    }
                });
                const fetchedSites = Array.from(uniqueSitesMap.values());

                // Extract business units
                const fetchedBusinessUnits = mappedData.map(item => ({
                    id: item.configuration_id,
                    name: item.business_unit,
                    website_id: item.website_id,
                }));

                // Extract bubbles
                const fetchedBubbles = mappedData.map(item => ({
                    id: item.configuration_id,
                    name: JSON.parse(item.search_name),
                    website_id: item.website_id,
                    business_name: item.business_unit,
                }));

                // Set initial selected site and business unit
                setSelectedSite(fetchedSites.length > 0 ? fetchedSites[0].name : '');
                setSelectedBusinessUnit(fetchedBusinessUnits.length > 0 ? fetchedBusinessUnits[0].name : '');
                setSelectedBubble(fetchedBubbles.length > 0 ? fetchedBubbles[0].name : '');

                // Filter business units and bubbles based on the initial selected site
                const filteredBusinessUnits = fetchedBusinessUnits.filter(bu => bu.website_id === selectedSite);
                const filteredBubbles = fetchedBubbles.filter(bubble => bubble.website_id === selectedSite);

                // Set initial working data
                setWorkingData({
                    businessUnits: filteredBusinessUnits,
                    sites: [fetchedSites[0]], // Only the initially selected site
                    bubbles: filteredBubbles,
                    site: [fetchedSites[0].name]
                });

                setBackupData({
                    businessUnits: filteredBusinessUnits,
                    sites: fetchedSites,
                    bubbles: filteredBubbles,
                    site: [fetchedSites[0].name]
                });

                // Set fetched data
                setFetchedData({
                    businessUnits: fetchedBusinessUnits,
                    sites: fetchedSites,
                    bubbles: fetchedBubbles,
                });
            } else if (responseData.status === 403) {
                openToast('Token Expired', 'images/cross - red.svg');
            } else {
                openToast('Something went wrong', 'images/cross - red.svg');
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleSiteSelection = (site) => {
        setSelectedSite(site.name);

        // Filter business units based on selected site
        const filteredBusinessUnits = fetchedData.businessUnits.filter(bu => bu.website_id === site.id);

        // Filter bubbles for the selected business unit and site
        const filteredBubbles = fetchedData.bubbles.filter(bubble =>
            bubble.website_id === site.id && bubble.business_name === selectedBusinessUnit
        );

        setWorkingData({
            sites: fetchedData.sites, // Maintain all sites in workingData
            businessUnits: filteredBusinessUnits,
            bubbles: filteredBubbles,
            bubble: fetchedData.bubbles,
            site: site.name,
        });

        setBackupData({
            sites: fetchedData.sites, // Maintain all sites in workingData
            businessUnits: filteredBusinessUnits,
            bubbles: filteredBubbles,
            site: site.name,
        });
    };


    useEffect(() => {
        fetchConfigData();
    }, []);

    //PS_CP_17 useEffect triger based on the Site Changes When the Admin select the site name, setSiteModalOpen is true, create a variable called selectedValue and assign the selected value using the e.target.value, and then setNewSite as selectedValue.
    useEffect(() => {
        if (fetchedData.sites.length > 0) {
            handleSiteSelection(fetchedData.sites[0]);
        }
    }, [fetchedData.sites]);

    console.log("Updated workingData:", workingData);
    console.log("Updated BackupData:", backupData);
    console.log("Updated fetchedData:", fetchedData);


    //This Function is used to Show the Error messsage in the Toast Popup
    function openToast(message, image) {
        setIsToastOpen(true);
        setToastImage(image);
        setToastMessage(message);

        setTimeout(() => {
            setIsToastOpen(false);
            setToastImage("");
            setToastMessage("");
        }, 5000);
    }

    //PS_CP_19: When the Admin click the add/edit in the site popup, create a function called **handleAddSite** with the async function and with the parameter of user as arrow function.
    const handleAddSite = async (siteName) => {
        if (workingData.sites.some(site => site.name === siteName.toLowerCase())) {
            openToast('Site already exists.', 'images/cross - red.svg');
            return;
        }
        console.log(allowedSites[siteName], "ds")
        console.log(siteName, "df")

        if (allowedSites[siteName.toLowerCase()]) {
            const newSite = {
                id: allowedSites[siteName.toLowerCase()],
                name: siteName.toLowerCase(),
            };
            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": allowedSites[siteName.toLowerCase()],
                        "OtherAction": "null",
                        "action": "insert"
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }
            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
            // Append the new site to the existing list of sites
            const updatedSites = [...workingData.sites, newSite];

            setWorkingData(prev => ({
                ...prev,
                sites: updatedSites
            }));

            // Automatically select the newly added site
            setSelectedSite(siteName);
            setAction('insert')

            // Clear business units and bubbles since the new site has no associated units yet
            setWorkingData(prev => ({
                ...prev,
                businessUnits: [],
                bubbles: []
            }));

            setSelectedBusinessUnit('');
            setSelectedBubble('');

            setWorkingData(prev => ({
                ...prev,
                site: siteName
            }));
        } else {
            openToast('Invalid site. Only sam.gov, govtribe, and govwin are allowed.', 'images/cross - red.svg');
        }
    };

    //PS_CP_19: When the Admin click the add/edit in the business unit popup, create a function called **handleAddItem** with the async function and with the parameter of user as arrow function. Check the Item type is site or business unit
    const handleAddItem = async (type, item) => {
        if (type === 'sites') {
            handleAddSite(item.name);

        } else {
            if (type === 'businessUnits') {
                const exists = workingData.businessUnits.some(businessUnit => 
                    businessUnit.name.toLowerCase() === item.name.toLowerCase()
                );
                console.log(exists, "existsssss");
                if (exists == true) {
                    openToast(`Business unit with name ${item.name} already exists`, 'images/cross - red.svg');
                    return; // Exit the function if the business unit already exists
                }
                else {
                    console.log("came to else");
                    setWorkingData(prev => ({
                        ...prev,
                        [type]: [...prev[type], item],
                    }));
                    setAction('insert businessUnits')
                    setSelectedBusinessUnit(item.name);
                    setWorkingData(prev => ({
                        ...prev,
                        bubbles: []
                    }));
                }
            }
        }
    };

    const checkBusinessUnitIdFormat = (businessUnit) => {
        // Example check: assuming datetime format means a UUID-like structure
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        return uuidRegex.test(businessUnit.id);
    };


    //PS_CP_41: When the Admin edits a business unit, populate the business unit modal with the current value and set up the editing state. When the Admin edits a site, populate the site modal with the current value and set up the editing state.

    const handleEditItem = (type, item) => {
        setUpdate(item)
        console.log(fetchedData, 'ffff')
        console.log(type, "jhf");
        console.log(item, "ko");

        setWorkingData(prev => ({
            ...prev,
            [type]: prev[type].map(dataItem => dataItem.id === item.id ? item : dataItem),
            bubbles: prev.bubbles.map(bubble =>
                bubble.website_id === item.website_id
                    ? { ...bubble, business_name: item.name }
                    : bubble
            ),
        }));

        setAction('Edit businessUnits')
        setSelectedBusinessUnit(item.name)



        if (type === 'businessUnits') {
            const hasRequiredSites = checkBusinessUnitIdFormat(item);
            console.log(hasRequiredSites, "opppp");
            if (!hasRequiredSites) {
                setAction('insert businessUnits');

            } else {
                setAction('Edit businessUnits'); // or any other appropriate action
            }
        }
    };
    console.log(action, "action");


    // Function to check if all arrays in workingData are empty
    const areAllArraysEmpty = () => {
        return (
            workingData.bubbles.length === 0 &&
            workingData.businessUnits.length === 0 &&
            workingData.sites.length === 0
        );
    };
    // Function to check if plus icon should be disabled
    const shouldDisablePlusIcon = () => {
        return workingData.sites.length >= 3;
    };

    const disabled = shouldDisablePlusIcon()
    console.log(disabled, "oooooooooossssssssss");

    //PS_CP_40: When the Admin deletes a site name, update the sites state to exclude the deleted site and reflect changes in UI.
    const handleDeleteItem = (type, name) => {
        if (type === 'businessUnits') {
            const updatedBusinessUnits = workingData.businessUnits.filter(bu => bu.name !== name);
            const newSelectedBusinessUnit = updatedBusinessUnits.length > 0 ? updatedBusinessUnits[0].name : null;

            setSelectedBusinessUnit(newSelectedBusinessUnit);

            const updatedBubbles = workingData.bubbles.filter(bubble => bubble.business_name !== name);

            if (newSelectedBusinessUnit) {
                const filteredBubbles = fetchedData.bubbles.filter(bubble => bubble.business_name === newSelectedBusinessUnit);
                setWorkingData(prev => ({
                    ...prev,
                    businessUnits: updatedBusinessUnits,
                    bubbles: filteredBubbles,
                }));
            } else {
                setWorkingData(prev => ({
                    ...prev,
                    businessUnits: updatedBusinessUnits,
                    bubbles: [],
                }));
            }

        } else if (type === 'sites') {
            const updatedSites = workingData.sites.filter(site => site.name !== name);
            const newSelectedSite = updatedSites.length > 0 ? updatedSites[0].name : null;
            setSelectedSite(newSelectedSite);

            const updatedBubbles = workingData.bubbles.filter(bubble => bubble.website_id !== name);
            const updatedBusiness = workingData.businessUnits.filter(business => business.website_id !== name);

            setSelectedBusinessUnit('');

            const filteredBusinessUnits = fetchedData.businessUnits.filter(bu => bu.website_id === updatedSites[0]?.id);
            setSelectedBusinessUnit(filteredBusinessUnits[0]?.name || '');
            const filteredBubbles = fetchedData.bubbles.filter(bubble => bubble.website_id === updatedSites[0]?.id);

            setWorkingData({
                businessUnits: filteredBusinessUnits,
                sites: updatedSites,
                bubbles: filteredBubbles,
                site: name,
            });
        }
    };

    const handleOpenDeleteModal = (buId, type) => {
        setUpdate(buId, "buid")
        setItemToDelete(buId.name);
        console.log(buId, "deletera")
        if (type == "BusinessUnit") {
            setSelectedBusinessUnit(buId.name)
            const matchingBubbles = fetchedData.bubbles.filter(bubble =>
                bubble.business_name === buId.name
            );
            console.log(matchingBubbles, "matchingbbb")

            setWorkingData(prev => ({
                ...prev,
                bubbles: matchingBubbles
            }));
        }
    };

    //PS_CP_50: Upon Admin confirmation (clicking 'yes' in the deletePopup), filter businessUnits to remove the selected unit and update state with the new array, excluding the deleted unit.
    const handleConfirmDelete = async (type) => {
        console.log(action, "dellll");
        let siteId = false;
        for (let i = 0; i < workingData.sites.length; i++) {
            if (workingData.sites[i].name === itemToDelete) {
                siteId = workingData.sites[i].id;;
                break; // Exit the loop early since we found the site
            }
        }
        console.log(siteId,"siteeeee");
        if (itemToDelete) {
            if (type === 'businessUnits') {
                handleDeleteItem(type, itemToDelete);

                try {
                    const payload = {
                        "value": "basicSearch",
                        "user_id": user_id,
                        "detail": [{
                            "WebsiteId": siteId,
                            "OtherAction": "yes",
                            "action": "delete",
                            "BuisnessUnit": itemToDelete,
                            "SearchName": "null"
                        }]
                    }
                    setLoader(true)
                    const response = await postConfig(payload, jwtToken);
                    setLoader(false)
                    console.log(response);
                    if (response.success === true) {
                        console.log(response);
                        fetchConfigData()
                    } else if (response.status === 403) {
                        openToast("Token Expired", "images/cross - red.svg");
                        navigate('/Login');
                    } else if (response.status === 401) {
                        openToast("Something went wrong", "images/cross - red.svg");
                    }

                } catch (error) {
                    const payload = {
                        service: "frontend",
                        error_message: error.toString(),
                        module_name: "BasicSearch.jsx",
                        function_name: "handleSubmit",
                        user_id: user_id,
                    };
                    insertErrorApi(payload, jwtToken);
                }
            } 
            else{
                console.log("delete the state");
            }
            if (type === 'sites') {
                handleDeleteItem(type, selectedSite);
                try {
                    const payload = {
                        "value": "basicSearch",
                        "user_id": user_id,
                        "detail": [{
                            "WebsiteId": siteId,
                            "OtherAction": "null",
                            "action": "delete"
                        }]
                    }
                    setLoader(true)
                    const response = await postConfig(payload, jwtToken);
                    setLoader(false)
                    console.log(response);
                    if (response.success === true) {
                        console.log(response);
                        fetchConfigData()
                    } else if (response.status === 403) {
                        openToast("Token Expired", "images/cross - red.svg");
                        navigate('/Login');
                    } else if (response.status === 401 || 500) {
                        openToast("Something went wrong", "images/cross - red.svg");
                    }
                } catch (error) {
                    const payload = {
                        service: "frontend",
                        error_message: error.toString(),
                        module_name: "BasicSearch.jsx",
                        function_name: "handleSubmit",
                        user_id: user_id,
                    };
                    insertErrorApi(payload, jwtToken);
                }
            }
            setAction('delete' + type)

        }
    };
    // This function will be Used to select the Business unit and Site
    const handleSelectItem = (type, item) => {
        if (type === 'BusinessUnit') {
            setSelectedBusinessUnit(item.name);
            // First search in fetchedData
            let filteredBubbles = fetchedData.bubbles.filter(bubble =>
                bubble.business_name === item.name && bubble.website_id === item.website_id
            );
            console.log(filteredBubbles, "bububububu");

            // If no matching bubbles found in fetchedData, search in workingData
            if (filteredBubbles.length === 0) {
                filteredBubbles = workingData.bubbles.filter(bubble =>
                    bubble.business_name === item.name && bubble.website_id === item.website_id
                );
                console.log(filteredBubbles, "wsazs");
            }
            setWorkingData(prev => ({
                ...prev,
                bubbles: filteredBubbles,
            }));
            setBackupData(prev => ({
                ...prev,
                bubbles: filteredBubbles,
            }));


        } else if (type === 'Site') {
            setSelectedSite(item.name);

            const filteredBusinessUnits = fetchedData.businessUnits.filter(bu => bu.website_id === item.id);
            const newSelectedBusinessUnit = filteredBusinessUnits[0]?.name || '';
            setSelectedBusinessUnit(newSelectedBusinessUnit);

            const filteredBubbles = fetchedData.bubbles.filter(bubble =>
                bubble.website_id === item.id && bubble.business_name === newSelectedBusinessUnit
            );

            setWorkingData({
                sites: fetchedData.sites, // Only the selected site in workingData
                site: [item.name],
                businessUnits: filteredBusinessUnits,
                bubbles: filteredBubbles,
            });

            setBackupData({
                sites: fetchedData.sites,
                businessUnits: filteredBusinessUnits,
                bubbles: filteredBubbles,
                site: [item.name]
            });
        }
    };

    //This Function will reset the function when user click the cancel button
    const handleReset = () => {
        if(workingData.bubbles.length == []){
            setSelectedBusinessUnit(workingData.businessUnits[0].name)
        }
        else{
            setSelectedBusinessUnit(workingData.bubbles[0].business_name)
        }
        setWorkingData(backupData);
        setNewBubble('');
        setSearchText('');
    };

    const openModalForAdd = (type) => {
        if (type == "BusinessUnit") {
            setAction('insert')
            setModalType(`Add${type}`);
            setCurrentItem('');
        }
        else {
            setModalType(`Add${type}`);
            setCurrentItem('');

        }
    };

    const openModalForEdit = (type, item) => {
        console.log(item, "openModalForEdit");
        setModalType(`Edit${type}`);
        setCurrentItem(item);
        if (type == "BusinessUnit") {
            setSelectedBusinessUnit(item.name)
            const matchingBubbles = workingData.bubbles.filter(bubble =>
                bubble.business_name === item.name
            );
            console.log(matchingBubbles, "matchingbbb")

            setWorkingData(prev => ({
                ...prev,
                bubbles: matchingBubbles
            }));
        }
    };

    console.log(currentItem, "poooooooooooo");
    const handleBubbleAdd = () => {
        if (newBubble.trim()) {
            setWorkingData(prev => ({
                ...prev,
                bubbles: [...prev.bubbles, newBubble.trim()],
            }));
            setNewBubble('');
        }
    };

    //This function will get the matching bubble names based on the Edited business unit
    const getMatchingBubbleNames = (update, workingData) => {
        console.log(update, workingData, "ollfe");

        // Initialize an array to collect all names
        let matchingNames = [];

        // Iterate through matching bubbles
        workingData.bubbles.forEach(bubble => {
            // Concatenate names arrays to matchingNames
            matchingNames = matchingNames.concat(bubble.name);
            console.log(matchingNames, "ollfes");
        });
        console.log(...new Set(matchingNames), "ollf");

        // Return unique names using Set (if needed)
        return [...new Set(matchingNames)];
    };

    const isTimestamp = (id) => !isNaN(id) && !isNaN(parseFloat(id)) && id.toString().length >= 10;

    // Function to determine action based on the last business unit
    const determineAction = (businessUnits) => {
        if (businessUnits.length > 0) {
            const lastElement = businessUnits[businessUnits.length - 1];
            return isTimestamp(lastElement.id) ? 'True' : 'False';
        }
        return 'True'; // Default action if no business units exist
    };

    //PS_CP_56: When Admin deletes a search bubble, update the searchBubbles state to exclude the specific bubble, reflecting the change in the user interface.
    const handleBubbleRemove = (bubbleNameToRemove) => {
        const user = determineAction(workingData.businessUnits);
        if (user === 'True' && selectedBusinessUnit === workingData.businessUnits[workingData.businessUnits.length - 1]?.name) {
            setAction('insert businessUnits')
            // Handle logic for inserting business units
            console.log('Inserting business unit logic here');
        } else {
            setAction('bubble')
        }
        console.log(workingData.businessUnits[workingData.businessUnits.length - 1]?.name, "unitttttttttttttttttt");
        const updatedBubbles = workingData.bubbles.map(bubble => {
            return {
                ...bubble,
                name: bubble.name.filter(name => name !== bubbleNameToRemove)
            };
        });

        setWorkingData(prev => ({
            ...prev,
            bubbles: updatedBubbles.filter(bubble => bubble.name.length > 0)
        }));
    };

    //PS_CP_55 When Admin adds a search bubble (on 'Enter' key press), update searchBubbles state to include a new bubble, and clear the input field.
    const handleKeyDown = (event) => {
        console.log(update, currentItem, "update and current");
        console.log(selectedBusinessUnit, "selected business (variable selected business)");
        console.log(itemToDelete, "selected business (variable itemtodelete)");


        if (event.key === 'Enter') {
            if (action === 'insert businessUnits') {
                setAction('insert businessUnits')
            }
            else {
                setAction('bubble')
            }
            const newBubbleName = event.target.value.trim();

            if (newBubbleName) {
                const existingBubbleIndex = workingData.bubbles.findIndex(
                    bubble => bubble.website_id === selectedSite && bubble.business_name === selectedBusinessUnit
                );

                if (existingBubbleIndex !== -1) {
                    const updatedBubbles = [...workingData.bubbles];
                    updatedBubbles[existingBubbleIndex] = {
                        ...updatedBubbles[existingBubbleIndex],
                        name: [...updatedBubbles[existingBubbleIndex].name, newBubbleName]
                    };

                    setWorkingData(prev => ({
                        ...prev,
                        bubbles: updatedBubbles
                    }));

                } else {
                    const newBubble = {
                        id: new Date().getTime(),
                        name: [newBubbleName],
                        website_id: selectedSite,
                        business_name: selectedBusinessUnit,
                    };

                    setWorkingData(prev => ({
                        ...prev,
                        bubbles: [...prev.bubbles, newBubble]
                    }));
                }

                setNewBubble('');
            }
        }
    };

    //PS_CP_59: When the Admin searches for search names in the basic search tab and triggers the `handleSearch()` function, it creates a function that takes `e.target.value` as a parameter and filters the `searchName` state array for matching terms (case-insensitive). It updates the site state with the filtered data, and the Admin can see the searched names listed as bubble text. 
    const filteredData = workingData.bubbles.filter(item => Array.isArray(item.name) && item.name.length > 0).map(item => item.name);

    const allNames = filteredData.flatMap(item => item);

    const filteredNames = allNames.filter(name =>
        name.toLowerCase().includes(searchText.toLowerCase())
    );


    //PS_CP_57: When the Admin clicks the save button in businessUnit, a function called handleSubmit` is created to handle the save operation. It constructs different payloads depending on the type of edit (update, delete, insert) and makes a call to the `postConfig_detail` API endpoint. The response is then handled appropriately, possibly showing modification popups or error messages, and the form is re-binded with updated data.
    const handleSubmit = async () => {
        // setFetchedData(workingData);
        console.log('Submitting data', workingData);
        let siteExists = false;
        for (let i = 0; i < workingData.sites.length; i++) {
            if (workingData.sites[i].name === workingData.site[0]) {
                siteExists = true;
                break; // Exit the loop early since we found the site
            }
        }

        let siteId = false;
        for (let i = 0; i < workingData.sites.length; i++) {
            if (workingData.sites[i].name === selectedSite) {
                siteId = workingData.sites[i].id;;
                break; // Exit the loop early since we found the site
            }
        }

        console.log(selectedSite)
        console.log("site exist Id:", siteId);
        console.log("site exist:", siteExists);
        // Use the siteExists variable in your if condition
        if (siteExists && workingData.bubbles.length == 0 && workingData.businessUnits.length == 0 && action != 'delete') {
            console.log("Site exists in the sites array. and site will update");
            const matchedSite = workingData.sites.find(site => site.name === workingData.site)
            console.log(matchedSite.id, "oooooooooooo");
            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": matchedSite.id,
                        "OtherAction": "null",
                        "action": "insert"
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }
            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
        }

        else if (action == 'deletesites') {
            console.log("Site exists in the sites array. and site will update");
            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": siteId,
                        "OtherAction": "null",
                        "action": "delete"
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }
            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
        }

        if (selectedSite && action == 'insert businessUnits') {
            const workingSiteData = workingData.sites.find(site => site.name === selectedSite);
            const fetchedSiteData = fetchedData.sites.find(site => site.name === selectedSite);

            // Identify extra business units and bubbles
            const addedBusinessUnits = workingData.businessUnits.filter(wbu =>
                !fetchedData.businessUnits.some(fbu => fbu.name === wbu.name && fbu.website_id === fetchedSiteData.id)
            );

            const addedBubbles = workingData.bubbles.filter(wb =>
                !fetchedData.bubbles.some(fb => fb.name.includes(wb.name) && fb.website_id === fetchedSiteData.id && fb.business_name === selectedBusinessUnit)
            );

            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": siteId,
                        "OtherAction": "yes",
                        "action": "insert",
                        "BuisnessUnit": addedBusinessUnits.map(bu => bu.name).join(','),
                        "SearchName": addedBubbles.map(bubble => bubble.name).flat()
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }
            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }

            console.log(addedBusinessUnits);
            console.log(addedBubbles);
            console.log(addedBusinessUnits.map(bu => bu.name));
            console.log(addedBubbles.map(bubble => bubble.name).flat());
        }

        if (selectedSite && action == 'Edit businessUnits') {
            let matchingNames = getMatchingBubbleNames(update, workingData);

            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": siteId,
                        "OtherAction": "yes",
                        "action": "update",
                        "BuisnessUnit": update.name,
                        "SearchName": matchingNames,
                        "ConfigurationId": update.id
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }

            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
        }
        if (action == 'deletebusinessUnits') {
            console.log('ok');
            console.log(itemToDelete);
            // Find the unit with the matching name
            const matchingUnit = fetchedData.bubbles.find(unit => unit.business_name === itemToDelete);
            console.log(matchingUnit);

            // Extract the names as an array
            const matchingUnitNames = matchingUnit ? JSON.stringify(matchingUnit.name) : '[]';

            // Log the result
            console.log(`Names of the matching unit:`, matchingUnitNames);


            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": siteId,
                        "OtherAction": "yes",
                        "action": "delete",
                        "BuisnessUnit": itemToDelete,
                        "SearchName": "null"
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }

            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
        }


        // Bassed on the Bubble action Buubble will be updated by passing the payload
        if (selectedSite && action == 'bubble') {
 
            // Extract and concatenate all name arrays
            let selectedBubble = [];
            workingData.bubbles.forEach(bubble => {
                selectedBubble = selectedBubble.concat(bubble.name);
            });
            console.log(selectedBubble);

            // Find id from businessUnits where name matches selectedBusinessUnit
            const selectedConfigId = workingData.businessUnits.find(bu => bu.name === selectedBusinessUnit)?.id;
            console.log(selectedConfigId);

            try {
                const payload = {
                    "value": "basicSearch",
                    "user_id": user_id,
                    "detail": [{
                        "WebsiteId": siteId,
                        "OtherAction": "yes",
                        "action": "update",
                        "BuisnessUnit": selectedBusinessUnit,
                        "SearchName": selectedBubble,
                        "ConfigurationId": selectedConfigId
                    }]
                }
                setLoader(true)
                const response = await postConfig(payload, jwtToken);
                setLoader(false)
                console.log(response);
                if (response.success === true) {
                    console.log(response);
                    fetchConfigData()
                } else if (response.status === 403) {
                    openToast("Token Expired", "images/cross - red.svg");
                    navigate('/Login');
                } else if (response.status === 401 || 500) {
                    openToast("Something went wrong", "images/cross - red.svg");
                }

            } catch (error) {
                const payload = {
                    service: "frontend",
                    error_message: error.toString(),
                    module_name: "BasicSearch.jsx",
                    function_name: "handleSubmit",
                    user_id: user_id,
                };
                insertErrorApi(payload, jwtToken);
            }
        }
    };


    console.log("Working data:", workingData)
    console.log("backup data:", backupData)
    console.log("fetched data:", fetchedData)

    console.log(action, "dellll");

    console.log(selectedBusinessUnit);
    console.log(newBubble);

    return (
        <>
            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="tab-pane fade show active" id="Basic-Search" role="tabpanel">
                        <div className="row">
                            {isToastOpen && (<div className="container-fluid">
                                <div className="d-flex justify-content-right align-items-end flex-column">
                                    <div className="px-2 mx-3 my-2 toast-container p-2">
                                        <p className="font-14 color-white font-regular m-0 d-flex align-items-center">
                                            <span>
                                                <img
                                                    src={toastImage}
                                                    alt="tick-green-icon"
                                                    className="me-2 toast-img cursor-pointer"
                                                />
                                            </span>{" "}
                                            {toastMessage}
                                        </p>
                                    </div>
                                </div>
                            </div>)}
                            <div className="col-md-3">
                                <label className="font-20 font-semibold mb-2">Business Unit</label>
                                <div className="input-group">
                                    <div className="dropdown w-75">
                                        <button className="btn input-btn bg-white color-black font-14 w-100 text-start gap-3 custom-border d-flex justify-content-between align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="mb-1">{selectedBusinessUnit || 'Select Business Unit'}</span>
                                            <img src="./images/dropdown-arrow.svg" alt="dropdown" />
                                        </button>
                                        <ul className="dropdown-menu custom-dropdown-width py-1 rounded-3 dropdown-box-shadow border-0">
                                            {workingData.businessUnits.map(bu => (
                                                <li key={bu.id} className="dropdown-item bg-white cursor-pointer px-0" onClick={() => handleSelectItem('BusinessUnit', bu)}>
                                                    <span className="d-flex justify-content-between align-items-center mx-2 rounded-2 dropdown-list">
                                                        <span className="ms-2 font-13 mb-1">
                                                            {bu.name}
                                                        </span>
                                                        <span className="me-2 mb-1 edit-delete">
                                                            <span className="cursor-pointer" onClick={() => openModalForEdit('BusinessUnit', bu)}>
                                                                <img
                                                                    className="custom-right-border pe-1"
                                                                    src="./images/edit-icon.svg"
                                                                    alt="edit"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modal"
                                                                />
                                                            </span>
                                                            {/* <span className="cursor-pointer" onClick={() => handleDeleteItem('businessUnits', bu.id)}>
                                                        <img src="./images/delete-icon.svg" alt="delete"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#staticBackdrop6" />
                                                    </span> */}
                                                            <span className="cursor-pointer" onClick={() => handleOpenDeleteModal(bu, "BusinessUnit")} id="delete">
                                                                <img src="./images/delete-icon.svg" alt="delete" data-bs-toggle="modal" data-bs-target="#staticBackdrop16" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>


                                    </div>
                                    <button type="button" className="input-group-text bg-white cursor-pointer custom-border border-start-0 add-button" data-bs-toggle="modal" data-bs-target="#modal" onClick={() => openModalForAdd('BusinessUnit')}>
                                        <img src="./images/green-plus.svg" alt="add" width={10} />
                                    </button>
                                </div>
                            </div>

                            <>
                                {/* Modal */}
                                <div className="modal fade" id="staticBackdrop16" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel6" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content border-size-5">
                                            <div className="modal-header border-0 pt-4 pb-1">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="modal-body px-4">
                                                <div className="d-flex justify-content-center mb-4">
                                                    <img src="images/error-red.svg" alt="error-red" />
                                                </div>
                                                <p className="text-center font-regular font-13 m-0 color-black">
                                                    {itemToDelete && `If you are going to delete ${itemToDelete} business the saved information against it will be deleted.`}
                                                </p>
                                            </div>
                                            <div className="modal-footer justify-content-center border-0 gap-2 pb-4">
                                                <button className="btn cancel-btn font-13 primary-color font-medium" data-bs-dismiss="modal">
                                                    No
                                                </button>
                                                <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal" onClick={() => handleConfirmDelete('businessUnits')}>
                                                    Yes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                            <div className="col-md-3">
                                <label className="font-20 font-semibold mb-2">Site</label>
                                <div className="input-group">
                                    <div className="dropdown w-75">
                                        <button className="btn input-btn bg-white color-black font-14 w-100 text-start gap-3 custom-border d-flex justify-content-between align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span className="mb-1">{selectedSite || 'Select Site'}</span>
                                            <img src="./images/dropdown-arrow.svg" alt="dropdown" />
                                        </button>
                                        <ul className="dropdown-menu custom-dropdown-width py-1 rounded-3 dropdown-box-shadow border-0">
                                            {workingData.sites.map(site => (
                                                <li key={site.id} className="dropdown-item bg-white cursor-pointer px-0" onClick={() => handleSelectItem('Site', site)}>
                                                    <span className="d-flex justify-content-between align-items-center mx-2 rounded-2 dropdown-list">
                                                        <span className="ms-2 font-13 mb-1">
                                                            {site.name}
                                                        </span>
                                                        <span className="me-2 mb-1 edit-delete">
                                                            <span className="cursor-pointer" onClick={() => openModalForEdit('site', site)}>
                                                                <img
                                                                    className="custom-right-border pe-1"
                                                                    src="./images/edit-icon.svg"
                                                                    alt="edit"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modal"
                                                                />
                                                            </span>

                                                            <span className="cursor-pointer" onClick={() => handleOpenDeleteModal(site, "site")} id="delete">
                                                                <img
                                                                    className="custom-right-border pe-1"
                                                                    src="./images/delete-icon.svg"
                                                                    alt="delete"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#staticBackdrop6" />
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <button type="button" className="input-group-text bg-white cursor-pointer custom-border border-start-0 add-button" data-bs-toggle="modal" data-bs-target="#modal" onClick={() => openModalForAdd('Site')} disabled={shouldDisablePlusIcon()}>
                                        <img src="./images/green-plus.svg" alt="add" width={10} />
                                    </button>
                                </div>
                            </div>

                            <>
                                {/* Modal */}
                                <div className="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel6" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">

                                        <div className="modal-content border-size-5">
                                            <div className="modal-header border-0 pt-4 pb-1">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                            </div>
                                            <div className="modal-body px-4">
                                                <div className="d-flex justify-content-center mb-4">
                                                    <img src="images/error-red.svg" alt="error-red" />
                                                </div>
                                                <p className="text-center font-regular font-13 m-0 color-black">
                                                    {itemToDelete && `If you are going to delete ${itemToDelete} site the saved information against it will be deleted.`}
                                                </p>
                                            </div>
                                            <div className="modal-footer justify-content-center border-0 gap-2 pb-4">
                                                <button className="btn cancel-btn font-13 primary-color font-medium" data-bs-dismiss="modal">
                                                    No
                                                </button>
                                                <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal" onClick={() => handleConfirmDelete('sites')}>
                                                    Yes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>

                            <div className="col-md-12 mt-5">
                                <div className="d-flex justify-content-end">
                                    <div className="custom-border d-flex align-items-center rounded-3">
                                        <input
                                            type="text"
                                            className="border-0 input-btn ms-3 btn-common"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={(e) => setSearchText(e.target.value)}
                                        />
                                        <button className="input-group-text bg-white cursor-pointer border-0">
                                            <img src="./images/search.svg" alt="search" />
                                        </button>
                                    </div>
                                </div>

                                <div className="col-md-12 mt-5">
                                    <div
                                        className="custom-border rounded-2 textarea-container p-3 mt-3"
                                        onClick={() => inputRef.current.focus()} // Focus input when div is clicked
                                    >
                                        {filteredNames.map((bubble, index) => (
                                            <span
                                                key={index}
                                                className="d-inline-block align-items-center green-bg gap-3 p-2 px-3 rounded-3 me-2 mb-2"
                                            >
                                                <span className="me-4 font-14 font-semibold">
                                                    {bubble}
                                                </span>
                                                <img
                                                    className="cursor-pointer"
                                                    src="./images/cross-icon.svg"
                                                    alt="cross"
                                                    onClick={() => handleBubbleRemove(bubble)}
                                                />
                                            </span>
                                        ))}
                                        <input
                                            ref={inputRef} // Ref to focus input programmatically
                                            type="text"
                                            style={{ border: "none", backgroundColor: "transparent", outline: "none" }}
                                            placeholder=""
                                            value={newBubble}
                                            onChange={(e) => setNewBubble(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="col-md-12 mt-5">
                                <div className="d-flex justify-content-end align-items-center py-3">
                                    <button className="btn cancel-btn me-3 px-4 font-regular primary-color" onClick={handleReset}>
                                        Cancel
                                    </button>
                                    <button className="btn submit-btn color-white px-4 font-regular" onClick={handleSubmit} data-bs-toggle="modal" data-bs-target="#staticBackdrop8" disabled={areAllArraysEmpty() || workingData.bubbles.length === 0}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Modal
                        type={modalType}
                        item={currentItem}
                        onSave={modalType.includes('Add') ? handleAddItem : handleEditItem}
                    />

                    <div className="modal fade" id="staticBackdrop8" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-centered">
                            <div className="modal-content border-size-5">
                                <div className="modal-body text-center">
                                    <div className="icon-container">
                                        <img className="tick-mark" src="./images/tick-green.svg" alt="tick-green" />
                                    </div>
                                    <div className="modal-body">
                                        <p className="text-center font-regular font-16 m-0 color-black-v2">
                                            Your modifications have been successfully applied. The newly added configuration will be effective for the next data scraping process.
                                        </p>
                                    </div>
                                    <div className="modal-footer justify-content-center border-0 gap-2 pb-3">
                                        <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal">
                                            Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    );
};




const Modal = ({ type, item, onSave }) => {
    const [inputValue, setInputValue] = useState('');
    console.log(inputValue, "daszx");
    useEffect(() => {
        if (item) {
            console.log(item.name, "rdfs");
            console.log(item, "fsdf");
            setInputValue(item.name);
        } else {
            setInputValue('');
        }
    }, [item, type]);

    const handleSave = () => {
        if (inputValue.trim()) {
            const newItem = {
                id: item ? item.id : Date.now(),
                name: inputValue.trim(),
                website_id: item.website_id
            };
            onSave(type.includes('BusinessUnit') ? 'businessUnits' : 'sites', newItem);
            setInputValue('')
        }
    };

    return (
        <div className="modal fade" id="modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content border-size-5">
                    <div className="modal-header border-0 px-4 pb-0">
                        <h1 className="modal-title font-semibold color-black font-20" id="modalLabel">
                            {type.includes('Add') ? 'Add' : 'Edit'} {type.includes('BusinessUnit') ? 'Business Unit' : 'Site'}
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-4">
                        <div className="mt-3">
                            <label className="form-label font-14 font-semibold primary-color" htmlFor="modalInput">
                                {type.includes('BusinessUnit') ? 'Business Unit' : 'Site'} Name
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form font-13 font-regular py-2"
                                id="modalInput"
                                placeholder={`Enter ${type.includes('BusinessUnit') ? 'Business Unit' : 'Site'} Name`}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <div className="modal-footer gap-2 text-end pt-3 px-0 border-0 pt-5 pb-0">
                                <button type="button" className="btn cancel-btn font-13 primary-color font-medium" data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="button" className="btn submit-btn font-13 color-white font-medium" data-bs-dismiss="modal" onClick={handleSave}>
                                    {type.includes('Add') ? 'Add' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicSearch;